import {
    INVITE_NEW_USER, VALIDATE_INVITATION,
    LOAD_ADMINS, UPDATE_IS_LOADING_ADMINS,
    LOAD_INVITATIONS, UPDATE_IS_LOADING_INVITATIONS
} from "../../sagas/user/types";
import IUser from "../../dtos/IUser";


export interface IInviteNewUserAction {
    readonly type: typeof INVITE_NEW_USER;
    payload: {
        invitationSent: boolean
    }
}
export const InviteNewUserProps = (payload: IInviteNewUserAction['payload']): IInviteNewUserAction => {
    return {
        type: INVITE_NEW_USER,
        payload: payload
    }
};
export interface IValitateInvitationAction {
    readonly type: typeof VALIDATE_INVITATION;
    payload: {
        valid: boolean
    }
}
export const validateInvitationProps = (payload: IValitateInvitationAction['payload']): IValitateInvitationAction => {
    return {
        type: VALIDATE_INVITATION,
        payload: payload
    }
};

export interface ILoadInvitationsAction {
    readonly type: typeof LOAD_INVITATIONS;
    payload: {
        invitations: any[],
        totalInvitationsCount: number
    }
}
export const loadInvitationsProps = (payload: ILoadInvitationsAction['payload']): ILoadInvitationsAction => {
    return {
        type: LOAD_INVITATIONS,
        payload: payload
    }
};

export interface IUpdateIsLoadingInvitationsAction {
    readonly type: typeof UPDATE_IS_LOADING_INVITATIONS;
    payload: {
        isLoadingInvitations: boolean
        errorMessage: string | undefined
    }
}
export const updateIsLoadingInvitationsProps = (payload: IUpdateIsLoadingInvitationsAction['payload']): IUpdateIsLoadingInvitationsAction => {
    return {
        type: UPDATE_IS_LOADING_INVITATIONS,
        payload: payload
    }
};

//
export interface ILoadAdminsAction {
    readonly type: typeof LOAD_ADMINS;
    payload: {
        admins: IUser[],
        totalAdminsCount: number
    }
}
export const loadAdminsProps = (payload: ILoadAdminsAction['payload']): ILoadAdminsAction => {
    return {
        type: LOAD_ADMINS,
        payload: payload
    }
};

export interface IUpdateIsLoadingAdminsAction {
    readonly type: typeof UPDATE_IS_LOADING_ADMINS;
    payload: {
        isLoadingAdmins: boolean
        errorMessage: string | undefined
    }
}
export const updateIsLoadingAdminsProps = (payload: IUpdateIsLoadingAdminsAction['payload']): IUpdateIsLoadingAdminsAction => {
    return {
        type: UPDATE_IS_LOADING_ADMINS,
        payload: payload
    }
};


export type UserActions = IInviteNewUserAction | IValitateInvitationAction |
    ILoadAdminsAction | IUpdateIsLoadingAdminsAction |
    ILoadInvitationsAction | IUpdateIsLoadingInvitationsAction;