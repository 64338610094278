import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history'
import { useClearCache } from "react-clear-cache";
import i18n from './i18n';
import { useDispatch } from 'react-redux';
//bootstrap styles
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import './assets/scss/mdb-free.scss';
//custom styles
import './assets/styles/app.scss'
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DashboardPage from "./pages/dashboard";
import UsersPage from "./pages/users"
import StoragesPage from "./pages/storages";
import POsPage from "./pages/pos"
import FulfillmentCenterPage from "./pages/fulfillment_center"
import Header from "./containers/layout/header";
import AuthRoute from './containers/authRoute'
import SideBar from './containers/layout/sidebar';
import { useSelector } from 'react-redux';
import { AppState } from './store';
import { toggleSidebarPropsSaga } from './sagas/sidebar/types';
import Modal from './components/modal';
import Can from './containers/can';
import constanst from "./constants";
import ServiceAccountPage from './pages/service_account'
import CardRequestsPage from './pages/card_requests'
import FulfillmentRequestsPage from './pages/fulfillment_requests'

//websocket
import { stompClient, connect, disconnect } from './websocket';
import { updateWebsocketClientPropsSaga } from './sagas/global/types';
import UserGroupPage from './pages/user-group';

// interface IAppProps {
//     history: History;
// }

const App = (props) => {
    const dispatch = useDispatch();
    const { isLatestVersion, emptyCacheStorage } = useClearCache();
    if (!isLatestVersion)
        emptyCacheStorage();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    changeLanguage('en');

    // connect to websocket
    // useEffect(() => {
    //     connect((client) => {
    //         dispatch(updateWebsocketClientPropsSaga({ websocketClient: client }));
    //     });
    // }, []);

    // toggle sidebar
    const sidebarReducer = useSelector((state) => state.sidebarReducer);
    const toggleSideBar = () => {
        if (sidebarReducer.open && window.innerWidth <= 767) // only when window width sm or smaller to close menu when click out
            dispatch(toggleSidebarPropsSaga())
    }
    // global reducer access
    const globalReducer = useSelector((state) => state.globalReducer);
    // listen to notifications
    useEffect(() => {
        if (globalReducer.websocketClient !== undefined) {
            globalReducer.websocketClient.unsubscribe('notification-1');
            globalReducer.websocketClient.subscribe('/topic/notifications', function (data) {
                let notification = JSON.parse(data.body);
                if (globalReducer.authUser.user_id.toString() === notification.userId.toString()) {
                    toast.success(notification.notification.short_description);
                }
            }, { id: 'notification-1' });
        }
    }, [globalReducer.websocketClient, globalReducer.authUser]);
    return (
        <ConnectedRouter history={props.history}>
            <AuthRoute>
                <MDBContainer className="p-4 h-100" fluid>
                    <div className="d-flex flex-column h-100">
                        <MDBRow className="m-0 flex-grow-1">
                            <MDBCol md={sidebarReducer.open ? '2' : '1'}
                                className={`p-0 d-flex flex-column
                             sidebar ${sidebarReducer.open ? 'pr-13-rem' : 'pr-8-rem sidebar-closed'}`}>
                                <SideBar />
                            </MDBCol>
                            <MDBCol
                                className={`p-0`} onClick={() => toggleSideBar()}>
                                <Header />
                                <Switch>
                                    <Route path="/service-account">
                                        <ServiceAccountPage />
                                    </Route>
                                    <Route path="/cards">
                                        <CardRequestsPage />
                                    </Route>
                                    <Route path="/storages">
                                        <StoragesPage />
                                    </Route>
                                    <Route path="/users">
                                        <UsersPage />
                                    </Route>
                                    <Route path="/PO">
                                        <POsPage />
                                    </Route>
                                    <Route path="/fulfillments-center">
                                        <FulfillmentCenterPage />
                                    </Route>
                                    <Route path="/requests">
                                        <FulfillmentRequestsPage />
                                    </Route>
                                    <Route exact path="/">
                                        <DashboardPage />
                                    </Route>
                                    <Route path="/user-group">
                                        <Can check="ADMIN_VIEW_SERVICE_ACCOUNT_USER_GROUP">
                                            <UserGroupPage />
                                        </Can>
                                    </Route>
                                </Switch>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="m-0">
                            <MDBCol md={sidebarReducer.open ? '2' : '1'} className={`p-0 d-flex flex-column
                                    ${sidebarReducer.open ? 'pr-13-rem' : 'pr-8-rem'}`}></MDBCol>
                            <MDBCol className={`text-center p-5`}>
                                <p className={`smaller-text lightGrey-text`}>All Rights Reserved for Dentacarts plus @{new Date().getFullYear()}</p>
                            </MDBCol>
                        </MDBRow>
                    </div>
                    <Modal isOpen={globalReducer.modal.isOpen}
                        close={globalReducer.modal.close}
                        confirm={globalReducer.modal.confirm}
                        title={globalReducer.modal.title}
                        body={globalReducer.modal.body}
                        savebtnText={globalReducer.modal.savebtnText}
                        closebtnText={globalReducer.modal.closebtnText}
                        isInfo={globalReducer.modal.isInfo}
                    />
                    <ToastContainer
                        position="top-center"
                        autoClose={constanst.toastTimeOut}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                </MDBContainer>
            </AuthRoute>
        </ConnectedRouter >
    )
}

export default App;