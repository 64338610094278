import { axiosInstance } from "../store";
import IUser from "../dtos/IUser";
import IBranch from "../dtos/IBranch";
import { handlStatusCodeMessage } from "../helpers";
import { toast } from "react-toastify";

export function fetchAdmins(
    page = 0,
    size = 10,
    sort = "",
    name = ""
): Promise<{ admins?: IUser[]; totalAdminsCount?: number }> {
    return axiosInstance
        .get(
            `${process.env.REACT_APP_API_URL}/account/user/get-user?first_name&last_name&component_id=2&page=${page}&size=${size}&sort=${sort}${name ? `&name=${name}` : ""}`
        )
        .then((res) =>
            res.status == 200
                ? {
                    admins: res.data,
                    totalAdminsCount: res.headers["x-total-count"],
                }
                : {
                    admins: [],
                    totalAdminsCount: 0,
                }
        )
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return {
                admins: [],
                totalAdminsCount: 0,
            };
        });
}

export function validateUser(
    user: any
): Promise<{
    userValidated: boolean;
    service_account_id: number | null;
    my_branches: IBranch[] | null;
    user_id: number | null;
}> {
    return axiosInstance
        .get(
            `${process.env.REACT_APP_API_URL}/account/user/sign-in?email=${user.idTokenParsed.email}&subject_id=${user.subject}&component_id=2`
        )
        .then((res) => {
            if (res.status == 200) {
                return {
                    userValidated: true,
                    service_account_id: res.data.user_info.service_account_id,
                    my_branches: res.data.user_info.branches,
                    user_permissions: res.data.user_permissions,
                    user_id: res.data.user_info.user_id,
                };
            } else {

                return {
                    userValidated: false,
                    service_account_id: null,
                    my_branches: [],
                    user_permissions: [],
                    user_id: null,
                };
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user', text: err.response.data.message });
            return {
                userValidated: false,
                service_account_id: null,
                my_branches: [],
                user_permissions: [],
                user_id: null,
            };
        });
}

export function sendInvitation(invitation: any): Promise<boolean> {
    return axiosInstance
        .post(
            `${process.env.REACT_APP_API_URL}/account/invitation/send?component_id=2`,
            invitation
        )
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: 'user' });
                return true
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'user' });
                return false
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function validateInvitation(token: string): Promise<boolean> {
    return axiosInstance
        .get(`${process.env.REACT_APP_API_URL}/account/invitation/validate/${token}?component_id=2`)
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function fetchUser(id: IUser["user_id"]): Promise<any> {
    return axiosInstance
        .get(`${process.env.REACT_APP_API_URL}/account/user/${id}?component_id=2`)
        .then((res) => (res.status == 200 ? res.data : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function deleteUser(id: IUser["user_id"]): Promise<any> {
    return axiosInstance
        .delete(`${process.env.REACT_APP_API_URL}/account/user/${id}?component_id=2`)
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function deactivateUserCard(
    id: IUser["user_id"],
    cardId: number
): Promise<any> {
    return axiosInstance
        .post(
            `${process.env.REACT_APP_API_URL}/smart-storage/access_card/deactivate_card?user_id=${id}&access_card_id=${cardId}`
        )
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function deactivateAuthUserCard(
    id: IUser["user_id"],
    cardId: number
): Promise<any> {
    return axiosInstance
        .post(
            `${process.env.REACT_APP_API_URL}/smart-storage/access_card/deactivate_my_card/?user_id=${id}&access_card_id=${cardId}`
        )
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function requestUserCard(id: IUser["user_id"]): Promise<any> {
    return axiosInstance
        .post(
            `${process.env.REACT_APP_API_URL}/smart-storage/access_card/user?user_id=${id}`
        )
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function fetchInvitations(
    page = 0,
    size = 10,
    sort = ""
): Promise<{ invitations?: any[]; totalInvitationsCount?: number }> {
    return axiosInstance
        .get(
            `${process.env.REACT_APP_API_URL}/account/user?component_id=2&is_invited=true&profile_status=Pending&page=${page}&size=${size}&sort=${sort}`
        )
        .then((res) =>
            res.status == 200
                ? {
                    invitations: res.data,
                    totalInvitationsCount: res.headers["x-total-count"],
                }
                : {
                    invitations: [],
                    totalInvitationsCount: 0,
                }
        )
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return {
                invitations: [],
                totalInvitationsCount: 0,
            };
        });
}

export function resendInvitation(id: IUser["user_id"]): Promise<any> {
    return axiosInstance
        .post(
            `${process.env.REACT_APP_API_URL}/account/invitation/resend?component_id=2&user_id=${id}`
        )
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function updateUser(
    id: IUser["user_id"],
    userGroupIds: number[],
): Promise<any> {
    return axiosInstance
        .put(`${process.env.REACT_APP_API_URL}/account/user/${id}?component_id=2`, {
            user_group_ids_list: userGroupIds,
        })
        .then((res) => (res.status == 200 ? toast.success('changes saved successfully.') : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}
