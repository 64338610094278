import { axiosInstance } from "../store";
import Constants from '../constants';
import IUserGroup from "../dtos/IUserGroup";
import { handlStatusCodeMessage } from "../helpers";
import { component } from "../containers/user-group/namings";

export function fetchUserGroups(page = 0, size = 10): Promise<{ userGroups: IUserGroup[], totalCountPages: number }> {
    const SERVICE_ACC_ID = localStorage.getItem("service_account_id");
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/account/user-group?component_id=2&target_component_id=2&size=${size}&page=${page}`)
        .then((res) => {
            return {
                userGroups: res.status === 200 ? res.data.map((b: any) => { return { ...b, selected: false } }) : []
                , totalCountPages: res.headers['x-total-count']
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user group' });
            return {
                userGroups: [],
                totalCountPages: 0
            }
        })
}


export function addUserGroups(userGroupData: IUserGroup): Promise<IUserGroup> {
    const SERVICE_ACC_ID = localStorage.getItem("service_account_id");
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}/account/user-group?component_id=2`, {
        name: userGroupData.name,
        description: userGroupData.description,
        permissions: userGroupData.permissions,
        tanent_id: SERVICE_ACC_ID,
        component_id: 2
    })
        .then((res) => res.data)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user group' });
            return {}
        })
}


export function putUserGroups(id: string, userGroupData: IUserGroup): Promise<IUserGroup> {
    const SERVICE_ACC_ID = localStorage.getItem("service_account_id");
    return axiosInstance.put(`${process.env.REACT_APP_API_URL}/account/user-group/${id}?component_id=2`, {
        id: id,
        name: userGroupData.name,
        description: userGroupData.description,
        permissions: userGroupData.permissions,
        tanent_id: SERVICE_ACC_ID,
        component_id: 2
    })
        .then((res) => res.data)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user group' });
            return {}
        })
}

export function removeUserGroups(id: string): Promise<boolean> {
    const SERVICE_ACC_ID = localStorage.getItem("service_account_id");
    return axiosInstance.delete(`${process.env.REACT_APP_API_URL}/account/user-group/${id}?component_id=2`)
        .then((res) => res.status === 200)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user group' });
            return false
        })
}

export function fetchUserGroup(id: string): Promise<IUserGroup> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/account/user-group/${id}?component_id=2`)
        .then((res) => res.data)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user group' });
            return {}
        })
}

export function fetchUserGroupsPermissions(componentName: component): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/account/permission?component=${componentName}`)
        .then((res) => res.status === 200 ? res.data : [])
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user group' });
            return []
        })
}