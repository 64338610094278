import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { MDBRow, MDBAlert, MDBContainer, MDBCardText } from 'mdbreact';
import InvitationsList from "../../containers/usersList/invitationsList";

interface IInvitationsListPageProps {
}

export default function InvitationsListPage(props: IInvitationsListPageProps) {
    const userReducer = useSelector((state: AppState) => state.userReducer)
    return (
        <>
            <MDBRow className="m-0">
                <InvitationsList />
            </MDBRow>
        </>
    )
}