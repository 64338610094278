import {
    LOAD_CARD_REQUESTS,
    UPDATE_IS_LOADING_CARD_REQUESTS, DELETE_CARD_REQUEST,
    UPDATE_CARD_REQUEST
} from '../../sagas/card_requests/types';

export interface ILoadCardRequestsAction {
    readonly type: typeof LOAD_CARD_REQUESTS;
    payload: {
        cardRequests: any[],
        totalCountPages: number
    }
}

export const loadCardRequestsProps = (payload: ILoadCardRequestsAction['payload']): ILoadCardRequestsAction => {
    return {
        type: LOAD_CARD_REQUESTS,
        payload: payload
    }
};

export interface IUpdateIsLoadingCardRequestsAction {
    readonly type: typeof UPDATE_IS_LOADING_CARD_REQUESTS;
    payload: {
        isLoadingCardRequests: boolean
        errorMessage: string | undefined
    }
}

export const updateIsLoadingCardRequestsProps = (payload: IUpdateIsLoadingCardRequestsAction['payload']): IUpdateIsLoadingCardRequestsAction => {
    return {
        type: UPDATE_IS_LOADING_CARD_REQUESTS,
        payload: payload
    }
};

export interface IDeleteCardRequestAction {
    readonly type: typeof DELETE_CARD_REQUEST;
    payload: {
        cardRequestId: string,
    }
}

export const deleteCardRequestProps = (payload: IDeleteCardRequestAction['payload']): IDeleteCardRequestAction => {
    return {
        type: DELETE_CARD_REQUEST,
        payload: payload
    }
};

export interface IUpdateCardRequestAction {
    readonly type: typeof UPDATE_CARD_REQUEST;
    payload: {
        cardRequestId: string,
        data: {
            tag_number: string,
            status: {
                id: string,
                name: string
            }
        }
    }
}

export const updateCardRequestProps = (payload: IUpdateCardRequestAction['payload']): IUpdateCardRequestAction => {
    return {
        type: UPDATE_CARD_REQUEST,
        payload: payload
    }
};

export type CardRequestsActions = ILoadCardRequestsAction | IUpdateIsLoadingCardRequestsAction |
    IDeleteCardRequestAction | IUpdateCardRequestAction;