import { axiosInstance } from "../store";
import Constants from '../constants';
import { handlStatusCodeMessage } from "../helpers";

export function fetchDataTableFulfillmentRequests(page = 0, size = 10, sort = '', name = ''): Promise<{ fulfillmentRequests: any[], totalCountPages: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/order?status=Placed&is_vendor=true&page=${page}&size=${size}&sort=${sort}&name=${name}`)
        .then((res) => {
            return { fulfillmentRequests: res.status === 200 ? res.data : [], totalCountPages: res.headers['x-total-count'] }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Fulfillment request' });
            return {
                fulfillmentRequests: [],
                totalCountPages: 0
            }
        })
}

export function fetchFulfillmentRequest(id: string | number): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/order/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Fulfillment request' });
            return {}
        })
}

export function addFulfillmentRequest(fulfillmentRequestData: any): Promise<any> {
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}/smart-storage/order`, {
        ...fulfillmentRequestData,
    }).then((res) => res.data)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Fulfillment request' });
            return false
        });
}

export function putFulfillmentRequest(id: string, fulfillmentRequestData: any): Promise<any> {
    return axiosInstance.put(`${process.env.REACT_APP_API_URL}/smart-storage/order/${id}`, {
        id: id,
        ...fulfillmentRequestData,
    }).then((res) => res.data)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Fulfillment request', text: err.response.data.message });
            return false
        });
}

export function removeFulfillmentRequest(id: string): Promise<boolean> {
    return axiosInstance.delete(`${process.env.REACT_APP_API_URL}/smart-storage/order/${id}`)
        .then((res) => res.status === 200)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Fulfillment request' });
            return false
        });
}

export function fetchVendor(id: string | number): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/vendor/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Fulfillment request' });
            return {}
        })
}