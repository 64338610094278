import React from "react";
import StoragesListPage from './storagesListPage';
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { MDBRow, MDBCardText } from 'mdbreact';
import vendorsIcon from "../../assets/img/pages-icons/vendor-icon.png";

interface IStoragesPageProps {
}

export default function StoragesPage(props: IStoragesPageProps) {
    let { path } = useRouteMatch();
    return (
        <>
            <Switch>
                <Route exact path={path} component={StoragesListPage} />
            </Switch>
        </>
    )
}