import React, { useState } from 'react';
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';

interface IEditableTextProps {
    type?: "text"|"number",
    name: string,
    key:any,
    defaultValue: any,
    onChange: any,
    onSave: any
}

function EditableText(props: IEditableTextProps) {
    return (
        <EditText
            type={props.type? props.type : "text"}
            name={props.name}
            key={props.key}
            defaultValue={props.defaultValue}
            onChange={props.onChange}
            onSave={props.onSave}
        />

    );
}

export default EditableText;
