import { put, takeLatest, call } from 'redux-saga/effects'
import {
    LOAD_FULFILLMENTS_CENTER_SAGA, ILoadFulfillmentsCenterActionSaga,
    LOAD_READY_ORDERS_SAGA, ILoadReadyOrdersActionSaga,
    //in progress
    LOAD_IN_PROGRESS_ORDERS_COUNT_SAGA, ILoadInProgressOrdersCountActionSaga,

    LOAD_IN_PROGRESS_ORDERS_SAGA, ILoadInProgressOrdersActionSaga,
    LOAD_IN_PROGRESS_ITEMS_SAGA, ILoadInProgressItemsActionSaga,
    //packed orders
    LOAD_PACKED_ORDERS_SAGA, ILoadPackedOrdersActionSaga,
    //update
    UPDATE_ORDER_SAGA, IUpdateOrderActionSaga,
    //finish 
    FINISH_ORDERS_SAGA, IFinishOrdersActionSaga
} from "./types";
import {
    loadFulfillmentsCenterProps, updateIsLoadingFulfillmentsCenterProps,
    loadReadyOrdersProps, updateIsLoadingReadyOrdersProps,
    //in progress
    loadInProgressOrdersCountProps,
    loadInProgressOrdersProps, loadInProgressItemsProps,
    updateIsLoadingInProgressOrdersProps,
    //packed
    loadPackedOrdersProps, updateIsLoadingPackedOrdersProps,
    //update
    updateOrderProps,
    //finsih
    finishOrdersProps,
} from "../../reducers/fulfillments_center/types";
import {
    fetchTotalOrdersCount, fetchTotalItemsCount,
    fetchReadyOrdersCount, fetchPendingOrdersCount,
    fetchReadyItemsCount, fetchPendingItemsCount,
    fetchReadyOrders,
    //in progress
    fetchInProgressOrdersCount,
    fetchInProgressOrders, fetchInProgressItems,
    //packed
    fetchPackedOrders,
    //update
    putOrder,
    //finish
    sendOrders
} from '../../api/fulfillmentsCenter'
import { displayErrorMessageProps } from "../../reducers/global/types";
import { IOrder } from '../../dtos/IOrder';
import { IFProduct } from '../../dtos/IFProduct';

function* loadFulfillmentsCenterData(action: ILoadFulfillmentsCenterActionSaga) {
    try {
        yield put(updateIsLoadingFulfillmentsCenterProps({ isLoadingFulfillmentsCenter: true, errorMessage: undefined }));
        const totalOrdersCount: number = yield call(fetchTotalOrdersCount);
        const totalItemsCount: number = yield call(fetchTotalItemsCount);
        const readyOrdersCount: number = yield call(fetchReadyOrdersCount);
        const pendingOrdersCount: number = yield call(fetchPendingOrdersCount);
        const readyItemsCount: number = yield call(fetchReadyItemsCount);
        const pendingItemsCount: number = yield call(fetchPendingItemsCount);
        yield put(loadFulfillmentsCenterProps({ totalOrdersCount, totalItemsCount, readyOrdersCount, pendingOrdersCount, readyItemsCount, pendingItemsCount }))
        yield put(updateIsLoadingFulfillmentsCenterProps({ isLoadingFulfillmentsCenter: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingFulfillmentsCenterProps({ isLoadingFulfillmentsCenter: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}

export function* watchLoadFulfillmentsCenter() {
    yield takeLatest(LOAD_FULFILLMENTS_CENTER_SAGA, loadFulfillmentsCenterData)
}
//ready orders
function* loadReadyOrdersData(action: ILoadReadyOrdersActionSaga) {
    try {
        yield put(updateIsLoadingReadyOrdersProps({ isLoadingReadyOrders: true, errorMessage: undefined }));
        const readyOrdersData: {
            readyOrders: IOrder[];
            readyOrdersCount: number;
        } = yield call(fetchReadyOrders, action.page, action.size, action.sort, action.status);
        yield put(loadReadyOrdersProps({ readyOrders: readyOrdersData.readyOrders, readyOrdersCount: readyOrdersData.readyOrdersCount }));
        yield put(updateIsLoadingReadyOrdersProps({ isLoadingReadyOrders: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingReadyOrdersProps({ isLoadingReadyOrders: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}

export function* watchLoadReadyOrdersData() {
    yield takeLatest(LOAD_READY_ORDERS_SAGA, loadReadyOrdersData)
}
//in progress
function* loadInProgressOrdersCount() {
    const inProgressOrdersCount: number = yield call(fetchInProgressOrdersCount);
    yield put(loadInProgressOrdersCountProps({ inProgressOrdersCount }))
}

export function* watchLoadInProgressOrdersCount() {
    yield takeLatest(LOAD_IN_PROGRESS_ORDERS_COUNT_SAGA, loadInProgressOrdersCount)
}

function* loadInProgressOrdersData(action: ILoadInProgressOrdersActionSaga) {
    try {
        yield put(updateIsLoadingInProgressOrdersProps({ isLoadingInProgressOrders: true, errorMessage: undefined }));
        const inProgressOrders: IOrder[] = yield call(fetchInProgressOrders, action.size);
        yield put(loadInProgressOrdersProps({ inProgressOrders }));
        yield put(updateIsLoadingInProgressOrdersProps({ isLoadingInProgressOrders: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingInProgressOrdersProps({ isLoadingInProgressOrders: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}

export function* watchLoadInProgressOrdersData() {
    yield takeLatest(LOAD_IN_PROGRESS_ORDERS_SAGA, loadInProgressOrdersData)
}


function* loadInProgressItemsData(action: ILoadInProgressItemsActionSaga) {
    try {
        yield put(updateIsLoadingInProgressOrdersProps({ isLoadingInProgressOrders: true, errorMessage: undefined }));
        const inProgressItemsData: {
            inProgressItems: IFProduct[];
            inProgressItemsCount: number;
        } = yield call(fetchInProgressItems, action.page, action.size, action.orderIds);
        yield put(loadInProgressItemsProps({ inProgressItems: inProgressItemsData.inProgressItems, inProgressItemsCount: inProgressItemsData.inProgressItemsCount }));
        yield put(updateIsLoadingInProgressOrdersProps({ isLoadingInProgressOrders: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingInProgressOrdersProps({ isLoadingInProgressOrders: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}

export function* watchLoadInProgressItemsData() {
    yield takeLatest(LOAD_IN_PROGRESS_ITEMS_SAGA, loadInProgressItemsData)
}
//packed orders
function* loadPackedOrdersData(action: ILoadPackedOrdersActionSaga) {
    try {
        yield put(updateIsLoadingPackedOrdersProps({ isLoadingPackedOrders: true, errorMessage: undefined }));
        const packedOrdersData: {
            packedOrders: IOrder[];
            packedOrdersCount: number;
        } = yield call(fetchPackedOrders, action.page, action.size);
        yield put(loadPackedOrdersProps({ packedOrders: packedOrdersData.packedOrders, packedOrdersCount: packedOrdersData.packedOrdersCount }));
        yield put(updateIsLoadingPackedOrdersProps({ isLoadingPackedOrders: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingPackedOrdersProps({ isLoadingPackedOrders: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}

export function* watchLoadPackedOrdersData() {
    yield takeLatest(LOAD_PACKED_ORDERS_SAGA, loadPackedOrdersData)
}
//update order
function* updateOrder(action: IUpdateOrderActionSaga) {
    const orderUpdated: boolean = yield call(putOrder,action.order);
    yield put(updateOrderProps({ orderUpdated }))
    yield loadInProgressOrdersCount()
}
export function* watchUpdateOrder() {
    yield takeLatest(UPDATE_ORDER_SAGA, updateOrder)
}
//finish orders
function* finishOrders(action: IFinishOrdersActionSaga) {
    const ordersFinished: boolean = yield call(sendOrders);
    yield put(finishOrdersProps({ ordersFinished }))
}
export function* watchFinishOrders() {
    yield takeLatest(FINISH_ORDERS_SAGA, finishOrders)
}
export default [
    watchLoadFulfillmentsCenter(),
    watchLoadReadyOrdersData(),
    watchLoadInProgressOrdersCount(),
    watchLoadInProgressOrdersData(),
    watchLoadInProgressItemsData(),
    watchLoadPackedOrdersData(),
    watchUpdateOrder(),
    watchFinishOrders(),
]