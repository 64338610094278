import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import { MDBRow, MDBCol } from 'mdbreact';
import { AppState } from "../../store";
import { loadModelsPropsSaga, addNewStoragePropsSaga } from "../../sagas/storage/types";
import Input from '../../components/input'
import Select from "../../components/select"

import * as Yup from "yup";
import { useFormik } from "formik";
import IStorage from '../../dtos/IStorage';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

interface IStorageFormProps {
    toggleAddStorageModal: any
}
const storage: IStorage = {
    storage_id: '',
    name: '',
    type: "automatic",
    manufacture_date: moment(new Date()).format("yyyy-MM-DD") + "T00:00:00",
    note: '',
    model: {
        model_id: 0,
        name: '',
        size: 0
    },
}
export let storageFormik: any;

function StorageForm(props: IStorageFormProps) {
    const dispatch = useDispatch();
    const storageReducer = useSelector((state: AppState) => state.storageReducer)
    useEffect(() => {
        dispatch(loadModelsPropsSaga());
    }, [])
    useEffect(() => {
        if (storageReducer.storageAdded) {
            props.toggleAddStorageModal(false);
            storageReducer.storageAdded = false;
            // dispatch(push({ pathname: '/users' }))
            // window.location.reload();
        }
    }, [storageReducer.storageAdded])
    const storageValidationSchema: object = Yup.object({
        name: Yup.string().required("Required!"),
        storage_id: Yup.string().required("Required!"),
        manufacture_date: Yup.date().required("Required!"),
        model: Yup.object({
            model_id: Yup.number().min(1, "Required!").required("Required!")
        })
    })

    storageFormik = useFormik({
        initialValues: storage,
        validationSchema: storageValidationSchema,
        onSubmit: async (values) => {
            dispatch(addNewStoragePropsSaga(values))
        },
    });

    return (
        <MDBRow className="m-0">
            <MDBRow className="w-100">
                <MDBCol className="w-50 mr-2 text-left">
                    <Input
                        label="Name"
                        required={true}
                        maxlength={50}
                        placeholder="Storage Name"
                        defaultValue={storageFormik.values.name}
                        onChange={(e: any) => storageFormik.setFieldValue('name', e.target.value)}
                    />
                    {storageFormik.errors.name && storageFormik.touched.name &&
                        <p className='red-text'>{storageFormik.errors.name}</p>}
                </MDBCol>
                <MDBCol className="w-50 text-left">
                    <Input
                        label="Storage Code"
                        required={true}
                        
                        placeholder="Storage Code"
                        defaultValue={storageFormik.values.storage_id}
                        onChange={(e: any) => storageFormik.setFieldValue('storage_id', e.target.value)}
                    />
                    {storageFormik.errors.storage_id && storageFormik.touched.storage_id &&
                        <p className='red-text'>{storageFormik.errors.storage_id}</p>
                    }
                </MDBCol>
            </MDBRow>

            <MDBRow className="w-100">
                <MDBCol className="w-50 mr-2 text-left">
                    <label className="font-weight-bold pl-3 pt-3">Model
                        <span className='red-text'>{' *'}</span>
                    </label>
                    <Select name="model_id"
                        label="Select Model"
                        className="select-div"
                        options={storageReducer.models.map((option, i) => {
                            return { text: option.name, value: option.model_id }
                        })}
                        selectedValue={storageFormik.values.model?.model_id}
                        onChange={(e: any) => storageFormik.setFieldValue('model.model_id', e.target.value)}
                    />

                    {storageFormik.errors.model?.model_id && storageFormik.touched.model?.model_id &&
                        <p className='red-text'>{storageFormik.errors.model?.model_id}</p>
                    }
                </MDBCol>
                <MDBCol className="w-50 text-left">
                    <div className="picker-container">
                        <label className="font-weight-bold pt-3 pl-3">Manufacture Date
                            <span className='red-text'>{' *'}</span>
                        </label>
                        <DatePicker wrapperClassName="calendar-input"
                            dateFormat="yyyy-MM-dd"
                            maxDate={new Date()}
                            selected={storageFormik.values.manufacture_date ? new Date(storageFormik.values.manufacture_date) : undefined}
                            onChange={(date: any) => {
                                storageFormik.setFieldValue('manufacture_date', moment(date).format("yyyy-MM-DD") + "T00:00:00")
                            }} />
                        {storageFormik.errors.manufacture_date && storageFormik.touched.manufacture_date &&
                            <p className='red-text'>{storageFormik.errors.manufacture_date}</p>
                        }
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow className="w-100">
                <MDBCol className="w-50 mr-2">
                    <Input
                        label="Note"
                        required={false}
                        placeholder="Note"
                        defaultValue={storageFormik.values.note}
                        onChange={(e: any) => storageFormik.setFieldValue('note', e.target.value)}
                    />
                </MDBCol>
                <MDBCol className="w-50">

                </MDBCol>
            </MDBRow>
        </MDBRow>

    )
}

export default StorageForm;