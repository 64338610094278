import React, { useEffect, useState, useRef, } from 'react';
import './styles/index.scss'
import { loadFulfillmentsCenterPropsSaga } from "../../sagas/fulfillments_center/types";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import Loader from "../../components/loader";
import {
    MDBCol, MDBRow, MDBIcon, MDBContainer, MDBModal,
    MDBModalBody, MDBModalHeader, MDBTabPane, MDBTabContent,
    MDBNav, MDBNavItem, MDBNavLink, MDBCard, MDBCardBody, MDBProgress
} from 'mdbreact';
import { NavLink } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import MainBlueButton from "../../components/blueButton";

export default function FulfillmentsCenter() {
    const dispatch = useDispatch();
    const fulfillmentsCenterReducer = useSelector((state: AppState) => state.fulfillmentsCenterReducer)
    const [time, setTime] = useState('');
    const [progress, setProgress] = useState(0)
    useEffect(() => {
        handleLoad();
    }, [])
    const handleLoad = () => {
        dispatch(loadFulfillmentsCenterPropsSaga());
        const time = new Date();
        const nowTime = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        setTime(nowTime);
        setTimeout(() => {
            setProgress(50)
        }, 800)
        setTimeout(() => {
            setProgress(70)
        }, 5000)
    }
    const renderModal = () => {
        return <MDBContainer className="progress-modal">
            <MDBModal
                isOpen={fulfillmentsCenterReducer.loadingFulfillmentsCenter.isLoadingFulfillmentsCenter}
                inline={false} noClickableBodyWithoutBackdrop={false} overflowScroll={false}>
                <MDBModalBody className="p-5" >
                    <MDBProgress
                        barClassName="filled-bar"
                        className="unfilled-bar"
                        value={progress} />
                    <div className="pt-3 text-center" >
                        <p className={`grey-text`}>Update in progress</p>
                        <p className={`grey-text`}>This may take a little while.. during this period you may not be able to browse the dashboard.</p>
                    </div>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    }
    console.log("fulfillmentsCenterReducer.loadingFulfillmentsCenter.isLoadingFulfillmentsCenter", fulfillmentsCenterReducer.loadingFulfillmentsCenter.isLoadingFulfillmentsCenter)
    return (
        <MDBRow className="m-0 w-100">
            {renderModal()}
            <MDBCol className="text-center">
                <MDBRow start className="mt-4">
                    <MDBCol md={`4`} sm={`7`} className="text-center mb-3">
                        <MDBCard className='card-shadow'>
                            <MDBCardBody className='p-3'>
                                <MDBRow>
                                    <MDBCol className='text-left'>
                                        <h6 className={`grey-text`}>Total Orders</h6>
                                        <h4 className={`text-font-black m-0 fa-1_5x text-center`}>{fulfillmentsCenterReducer.totalOrdersCount}</h4>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md={`4`} sm={`6`} className="text-center mb-3">
                        <MDBCard className='card-shadow'>
                            <MDBCardBody className='p-3'>
                                <MDBRow>
                                    <MDBCol className='text-left'>
                                        <h6 className={`grey-text`}>Total Items</h6>
                                        <h4 className={`text-font-black m-0 fa-1_5x text-center`}>{fulfillmentsCenterReducer.totalItemsCount}</h4>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md={`4`} sm={`6`} className="text-center mb-3">
                        <MDBCard className='card-shadow'>
                            <MDBCardBody className='p-3'>
                                <MDBRow>
                                    <MDBCol className='text-left'>
                                        <p className={`text-center text-font-black`}>{`Your data has been updated since ${time}`}</p>
                                        <MDBCol md="12" size="12" className="p-0 d-flex justify-content-md-start justify-content-center">
                                            <MainBlueButton
                                                title="Refresh"
                                                className="d-contents"
                                                icon="redo"
                                                btnClassName="w-100"
                                                onClick={() => window.location.reload()}
                                            />
                                        </MDBCol>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mt-4 ml-0 w-100">
                    <MDBCol className="text-center mb-3">
                        <MDBCard className='big-card-shadow p-4'>
                            <MDBCardBody className='p-3'>
                                <MDBRow>
                                    <MDBCol>
                                        <MDBRow className='d-flex justify-content-between m-2'>
                                            <h6 className={`text-font-black`}>Ready for packing</h6>
                                            <h4 className={`text-font-black m-0`}>{fulfillmentsCenterReducer.readyOrdersCount}</h4>
                                        </MDBRow>
                                    </MDBCol>
                                    <MDBCol>
                                        <MDBRow className='d-flex justify-content-between m-2'>
                                            <h6 className={`text-font-black`}>Number of Items</h6>
                                            <h4 className={`text-font-black m-0`}>{fulfillmentsCenterReducer.readyItemsCount}</h4>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <MDBRow className='d-flex justify-content-between m-2'>
                                            <h6 className={`text-font-black`}>Pending orders</h6>
                                            <h4 className={`text-font-black m-0`}>{fulfillmentsCenterReducer.pendingOrdersCount}</h4>
                                        </MDBRow>
                                    </MDBCol>
                                    <MDBCol>
                                        <MDBRow className='d-flex justify-content-between m-2'>
                                            <h6 className={`text-font-black`}>Number of Items</h6>
                                            <h4 className={`text-font-black m-0`}>{fulfillmentsCenterReducer.pendingItemsCount}</h4>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBCol className="p-4 d-flex justify-content-center w-100">
                    <NavLink to={fulfillmentsCenterReducer.readyOrdersCount > 0 ? "/fulfillments-center/orders" : "/fulfillments-center"}>
                        <MainBlueButton
                            title="Select your orders"
                            className="h1 d-contents"
                            icon="fire-alt"
                            btnClassName="pt-4 pb-4 pl-5 pr-5"
                            largeButton={true}
                            onClick={() => {}}
                        />
                    </NavLink>
                </MDBCol>

            </MDBCol>
        </MDBRow >
    )
}
