export const LOAD_CARD_REQUESTS_SAGA = "LOAD_CARD_REQUESTS_SAGA";
export const LOAD_CARD_REQUESTS = "LOAD_CARD_REQUESTS";
export const UPDATE_IS_LOADING_CARD_REQUESTS = "UPDATE_IS_LOADING_CARD_REQUESTS";
export interface ILoadCardRequestsActionSaga {
  readonly type: typeof LOAD_CARD_REQUESTS_SAGA;
  filters: {
    page: number;
    size: number;
    sort: string;
  };
}

export const loadCardRequestsPropsSaga = (filters: ILoadCardRequestsActionSaga['filters']): ILoadCardRequestsActionSaga => {
  return {
    type: LOAD_CARD_REQUESTS_SAGA,
    filters: {
      page: filters.page,
      size: filters.size,
      sort: filters.sort,
    },
  };
};

export const DELETE_CARD_REQUEST_SAGA = "DELETE_CARD_REQUEST_SAGA";
export const DELETE_CARD_REQUEST = "DELETE_CARD_REQUEST";
export interface IDeleteCardRequestActionSaga {
  readonly type: typeof DELETE_CARD_REQUEST_SAGA;
  cardRequestId: string
}

export const deleteCardRequestPropsSaga = (cardRequestId: IDeleteCardRequestActionSaga['cardRequestId']): IDeleteCardRequestActionSaga => {
  return {
    type: DELETE_CARD_REQUEST_SAGA,
    cardRequestId: cardRequestId
  };
};

export const UPDATE_CARD_REQUEST_SAGA = "UPDATE_CARD_REQUEST_SAGA";
export const UPDATE_CARD_REQUEST = "UPDATE_CARD_REQUEST";
export interface IUpdateCardRequestActionSaga {
  readonly type: typeof UPDATE_CARD_REQUEST_SAGA;
  cardRequestId: string;
  data: {
    tag_number: string,
    status: {
      id: string,
      name: string
    }
  }
}

export const updateCardRequestPropsSaga = (cardRequestId: string, data: IUpdateCardRequestActionSaga['data']): IUpdateCardRequestActionSaga => {
  return {
    type: UPDATE_CARD_REQUEST_SAGA,
    cardRequestId: cardRequestId,
    data: data
  };
};