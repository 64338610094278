import { put, takeLatest, call } from 'redux-saga/effects'
import {
    LOAD_FULFILLMENT_REQUESTS_SAGA, ILoadFulfillmentRequestsActionSaga,
    DELETE_FULFILLMENT_REQUEST_SAGA, IDeleteFulfillmentRequestActionSaga,
    UPDATE_FULFILLMENT_REQUEST_SAGA, IUpdateFulfillmentRequestActionSaga
} from "./types";
import {
    loadFulfillmentRequestsProps, updateIsLoadingFulfillmentRequestsProps,
    deleteFulfillmentRequestProps, updateFulfillmentRequestProps,
} from "../../reducers/fulfillment_requests/types";
import { fetchDataTableFulfillmentRequests, removeFulfillmentRequest, putFulfillmentRequest } from '../../api/fulfillmentRequests'
import { displayErrorMessageProps } from "../../reducers/global/types";

function* loadFulfillmentRequests(action: ILoadFulfillmentRequestsActionSaga) {
    try {
        yield put(updateIsLoadingFulfillmentRequestsProps({ isLoadingFulfillmentRequests: true, errorMessage: undefined }));
        const fulfillmentRequestsReasults: { fulfillmentRequests: any[], totalCountPages: number } = yield call(fetchDataTableFulfillmentRequests);
        yield put(loadFulfillmentRequestsProps({
            fulfillmentRequests: fulfillmentRequestsReasults.fulfillmentRequests,
            totalCountPages: fulfillmentRequestsReasults.totalCountPages,
        }));
        yield put(updateIsLoadingFulfillmentRequestsProps({ isLoadingFulfillmentRequests: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingFulfillmentRequestsProps({ isLoadingFulfillmentRequests: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}

export function* watchLoadFulfillmentRequests() {
    yield takeLatest(LOAD_FULFILLMENT_REQUESTS_SAGA, loadFulfillmentRequests)
}

function* deleteFulfillmentRequest(action: IDeleteFulfillmentRequestActionSaga) {
    const deletedFulfillmentRequestResults: boolean = yield call(removeFulfillmentRequest, action.fulfillmentRequestId);
    yield put(deleteFulfillmentRequestProps({ fulfillmentRequestId: action.fulfillmentRequestId }));
}

export function* watchDeleteFulfillmentRequest() {
    yield takeLatest(DELETE_FULFILLMENT_REQUEST_SAGA, deleteFulfillmentRequest);
}

function* updateFulfillmentRequest(action: IUpdateFulfillmentRequestActionSaga) {
    const updatedFulfillmentRequestResults: any = yield call(putFulfillmentRequest, action.fulfillmentRequestId, action.data);
    if (updatedFulfillmentRequestResults)
        yield put(updateFulfillmentRequestProps({ fulfillmentRequestId: action.fulfillmentRequestId, data: updatedFulfillmentRequestResults }))
}

export function* watchUpdateFulfillmentRequest() {
    yield takeLatest(UPDATE_FULFILLMENT_REQUEST_SAGA, updateFulfillmentRequest);
}

export default [
    watchLoadFulfillmentRequests(),
    watchDeleteFulfillmentRequest(),
    watchUpdateFulfillmentRequest(),
]