import { axiosInstance } from "../store";
import { handlStatusCodeMessage } from "../helpers";

export function postPO(startDate: any, endDate: any, orders: string | null): Promise<boolean> {
    return axiosInstance.post(
        `${process.env.REACT_APP_PO_URI}/sap-client/generate?start-date=${startDate}&end-date=${endDate}&orders=${orders}`, undefined)
        .then((res) => {
            if (res.status == 202) {
                handlStatusCodeMessage({ code: res.status, entity: 'po' });
                return true
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'po' });
                return false
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'po' });
            return false;
        });
}