import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import Loader from "../../components/loader";
import { MDBCol, MDBRow, MDBIcon, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import "../../assets/styles/app.scss"
import "./styles/styles.scss"
import {
    loadServiceAccountsPropsSaga, loadAccountBranchesPropsSaga,
    updateStoragePropsSaga, loadStoragePropsSaga
} from "../../sagas/storage/types";
import IStorageTransaction from "../../dtos/IStorageTransaction"
import MainBlueButton from '../../components/blueButton';
import Select from "../../components/select"

interface IStorageDetailsProps {
    storageId: string
    toggleStorageModal: any
}
export default function StorageDetails(props: IStorageDetailsProps) {
    const dispatch = useDispatch();
    const storageReducer = useSelector((state: AppState) => state.storageReducer);
    const [serviceAccount, chooseServiceAccount] = useState('')
    const [accountBranch, chooseAccountBranch] = useState('')
    const [dropdownsEnabled, showDropdowns] = useState(false)

    useEffect(() => {
        dispatch(loadServiceAccountsPropsSaga());
    }, [])
    useEffect(() => {
        if (storageReducer.storageUpdated) {
            props.toggleStorageModal(false)
            storageReducer.storageUpdated = false;
        }
    }, [storageReducer.storageUpdated])
    const handleChangeAccount = (value: any) => {
        chooseServiceAccount(value)
        chooseAccountBranch('')
        dispatch(loadAccountBranchesPropsSaga(value))
    }
    const assignStorage = () => {
        storageReducer.storage.branch = {
            id: parseInt(accountBranch)
        }
        dispatch(updateStoragePropsSaga(storageReducer.storage));
    }
    const unassignAccount = () => {
        storageReducer.storage.branch = null;
        dispatch(updateStoragePropsSaga(storageReducer.storage));
    }
    const headers: any = [
        { name: "User", width: 3 },
        { name: "Date", width: 4 },
    ].map((header) => {
        return { ...header };
    });
    const [headerColumns] = useState<IHeaderColumn[]>(headers);
    const buildRows = (storageTransactions: IStorageTransaction[]): IRow[] => {
        return storageTransactions.map((st: IStorageTransaction, i) => {
            return {
                value: st,
                props: [{
                    edit: (rowValue: IStorageTransaction) => {
                        return (<div>{rowValue.user ? `${rowValue.user.first_name} ${rowValue.user.last_name}` : ''}</div>
                        );
                    }
                }, {
                    edit: (rowValue: IStorageTransaction) => {
                        return (<div>{rowValue.timestamp.replace("T", " ").split(".")[0]}</div>
                        );
                    }
                }
                ]
            }
        });
    };
    const rows = useMemo(() => buildRows(storageReducer.storageTransactions), [storageReducer.storageTransactions]);

    return (
        <MDBRow className="m-0 bg-white text-left">
            <Loader isLoading={storageReducer.loadingStorages.isLoadingStorages} errorMessage={storageReducer.loadingStorages.errorMessage}>
                <MDBRow className="w-100">
                    <MDBCol className="w-50 mr-2">
                        <label className="font-weight-bold pl-2 pt-3">Name</label>
                        <input
                            className="form-control input-rounded pt-4 pb-4 pl-3"
                            defaultValue={storageReducer.storage.name}
                            placeholder="Storage Name"
                            disabled
                        />
                    </MDBCol>
                    <MDBCol className="w-50">
                        <label className="font-weight-bold pl-2 pt-3">Type</label>
                        <input
                            className="form-control input-rounded pt-4 pb-4 pl-3"
                            defaultValue={storageReducer.storage.type}
                            placeholder="Storage Type"
                            disabled

                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="w-100">
                    <MDBCol className="w-50 mr-2">
                        <label className="font-weight-bold pl-2 pt-3">Model Name</label>
                        <input
                            className="form-control input-rounded pt-4 pb-4 pl-3"
                            defaultValue={storageReducer.storage.model?.name}
                            placeholder="Model Name"
                            disabled
                        />
                    </MDBCol>
                    <MDBCol className="w-50">
                        <label className="font-weight-bold pl-2 pt-3">Model Size</label>
                        <input
                            className="form-control input-rounded pt-4 pb-4 pl-3"
                            defaultValue={storageReducer.storage.model?.size}
                            placeholder="Model Size"
                            disabled

                        />
                    </MDBCol>
                </MDBRow>
                {storageReducer.storage.branch != null && <MDBRow className="w-100">
                    <MDBCol className="w-50 mr-2">
                        <label className="font-weight-bold pl-2 pt-3">Branch</label>
                        <input
                            className="form-control input-rounded pt-4 pb-4 pl-3"
                            defaultValue={storageReducer.storage.branch?.name}
                            placeholder="Branch Name"
                            disabled
                        />
                    </MDBCol>
                    <MDBCol className="w-50">
                        <label className="font-weight-bold pl-2 pt-3">Service Account</label>
                        <input
                            className="form-control input-rounded pt-4 pb-4 pl-3"
                            defaultValue={storageReducer.storage.service_account?.account_name}
                            placeholder="Branch Name"
                            disabled
                        />
                    </MDBCol>
                </MDBRow>}

                <MDBCol className="p-4 d-flex justify-content-end w-100">
                    {
                        !dropdownsEnabled ?
                            storageReducer.storage.branch == null ?
                                <MainBlueButton
                                    title="Assign"
                                    btnClassName="pl-4 pr-4"
                                    onClick={() => showDropdowns(true)}
                                /> :
                                <MainBlueButton
                                    title="Unassign"
                                    btnClassName="pl-4 pr-4"
                                    onClick={() => unassignAccount()}
                                />

                            : undefined}
                </MDBCol>



                {dropdownsEnabled && <>
                    <MDBRow className="w-100">
                        <MDBCol className="w-50 mr-2">
                            <label className="font-weight-bold pl-3 pt-3">Service Account
                                <span className='red-text'>{' *'}</span>
                            </label>
                            <Select name="serviceAccount"
                                label="Select Service Account"
                                className="select-div"
                                options={storageReducer.serviceAccounts.map((option, i) => {
                                    return { text: option.account_name, value: option.id }
                                })}
                                selectedValue={serviceAccount}
                                onChange={(e: any) => handleChangeAccount(e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol className="w-50">
                            <label className="font-weight-bold pl-3 pt-3">Branch
                                <span className='red-text'>{' *'}</span>
                            </label>
                            <Select name="accountBranch"
                                label="Select Branch"
                                className="select-div"
                                options={storageReducer.accountBranches.map((option, i) => {
                                    return { text: option.name, value: option.id }
                                })}
                                selectedValue={accountBranch}
                                onChange={(e: any) => chooseAccountBranch(e.target.value)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBCol className="p-4 d-flex justify-content-end w-100">
                        <MainBlueButton
                            title="Save"
                            // className="ml-5"
                            btnClassName="pl-4 pr-4"
                            onClick={() => assignStorage()}
                        />
                    </MDBCol>
                </>}






                <MDBRow className="w-100">
                    <MDBCol className="text-center">
                        <label className="text-left font-weight-bold pt-3">Storage Transactions</label>
                        {
                            storageReducer.storageTransactions.length > 0 ?
                                <div className="celled-table">
                                    <MDBRow start className="ml-4 justify-content-center">
                                        <MDBCol className="text-center">
                                            <DataTable
                                                headerColumns={headerColumns}
                                                rows={rows}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </div> : <div>There are no transactions made on this storage.</div>
                        }
                    </MDBCol>
                </MDBRow>
            </Loader>
        </MDBRow >
    );
}
