import React, { useState } from 'react'
import { MDBCol, MDBRow, MDBIcon } from 'mdbreact';
import { partialText } from '../../../helpers';

interface ICardNotificationTrayProps {
    title: string,
    description: string,
    date: string,
    className?: any,
}
export default function CardNotificationTray(props: ICardNotificationTrayProps) {
    return (
        <MDBRow className="card-notification-tray p-3 mt-3">
            <MDBCol size="1" className="lightGrey-text p-0 pr-2"><MDBIcon icon="bell" /></MDBCol>
            <MDBCol size="8" className="p-0">
                <MDBRow>
                    <MDBCol>
                        <p className="font-weight-bold">
                            {
                                partialText(props.title, 40)
                            }
                        </p>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mt-2">
                    <MDBCol>
                        <p className="lightGrey-text text-weight-400">
                            {
                                partialText(props.description, 40)
                            }
                        </p>
                    </MDBCol>
                </MDBRow>
            </MDBCol>
            <MDBCol size="3" className="p-0">
                <p className="lightGrey-text text-weight-400 word-break">
                    {
                        props.date
                    }
                </p>
            </MDBCol>
        </MDBRow>
    )
}
