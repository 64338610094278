import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import {
    MDBNavbar, MDBNavbarBrand, MDBIcon,
    MDBNavbarNav, MDBNavItem, MDBDropdownToggle,
    MDBContainer, MDBModal, MDBModalBody, MDBModalHeader,
    MDBRow, MDBCol
} from "mdbreact";
import './styles/index.scss'
import { AppState } from "../../../store";
import { push } from 'connected-react-router';
import DClogo from "../../../assets/img/DC+.png";
import HeaderBanner from "../../../assets/img/header-banner.png";
import branchIcon from "../../../assets/img/branch-icon.png";
import { toggleSidebarPropsSaga } from '../../../sagas/sidebar/types';

import { NavLink } from 'react-router-dom';
import CardNotificationTray from './cardNotificationTray';
import { loadNotificationsPropsSaga } from '../../../sagas/notifications/types';
import MultiSelect from "../../../components/multiSelect"

interface IHeaderProps {

}
function Header(props: IHeaderProps) {
    const dispatch = useDispatch();
    const notificationsReducer = useSelector((state: AppState) => state.notificationsReducer)
    const [isNotificationsOpen, toggleNotifications] = useState(false)

    // toggle off notifications on click outside
    const outsideToggle = function (event: any) {
        let target: any = event.target;
        if (target.closest('.notification-popover') == null && isNotificationsOpen) {
            toggleNotifications(false);
        }
    }
    useEffect(() => {
        document.addEventListener('click', outsideToggle);
        // clean event listener
        return () => {
            document.removeEventListener('click', outsideToggle);
        }
    }, [isNotificationsOpen]);
    useEffect(() => {
        dispatch(loadNotificationsPropsSaga(false, 0, 10))
    }, []);

    return (
        <>
            <MDBNavbar className="border-radius-tl border-radius-tr border-radius-bl border-radius-br
            white box-shadow-none w-100 mb-5 z-index-1" expand="md">
                <MDBIcon icon="bars" className="mobile-header-bars cursor lightGrey-text fa-1_5x mr-2" onClick={() => { dispatch(toggleSidebarPropsSaga()) }} />
                <MDBNavbarBrand className={`d-md-block d-none`}>
                    <NavLink to={"/"}>
                        <img className="dclogo" src={DClogo} />
                    </NavLink>
                </MDBNavbarBrand>

                <MDBNavbarNav right>
                    <MDBNavItem className={'d-none d-md-block'}>
                        <MDBDropdownToggle nav>
                            <div className="transparent cursor border-0 lightGrey-text" onClick={() => toggleNotifications(!isNotificationsOpen)}>
                                <MDBIcon icon="bell" />
                                {
                                    (notificationsReducer.notifications.length > 0) && <span className="notification-dot"></span>
                                }
                            </div>
                        </MDBDropdownToggle>
                        <div className={`notification-popover px-5 py-3 ${isNotificationsOpen ? '' : 'd-none'}`}>
                            {
                                _.take(notificationsReducer.notifications, 4).map((notification, i) => {
                                    return (
                                        <CardNotificationTray key={i} title={notification.title}
                                            description={notification.short_description}
                                            date={notification.notification_date} />
                                    )
                                })
                            }
                            <MDBRow className="mt-4">
                                <MDBCol>
                                    <a href={`/notifications`}
                                        target="_blank" className="view-all-link">
                                        view all</a>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </MDBNavItem>
                    <MDBNavItem className={'d-block d-md-none'}>
                    <MDBDropdownToggle nav>
                            <div className="transparent cursor border-0 lightGrey-text" onClick={() => window.location.href = '/notifications'}>
                                <MDBIcon icon="bell" />
                                {
                                    (notificationsReducer.notifications.length > 0) && <span className="notification-dot"></span>
                                }
                            </div>
                        </MDBDropdownToggle>
                    </MDBNavItem>
                </MDBNavbarNav>
            </MDBNavbar >
            {/* <div className="header-banner w-100 mb-2">
                <img src={HeaderBanner} />
            </div> */}
        </>
    )
}

export default Header;