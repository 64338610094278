import {
    LOAD_SERVICE_ACCOUNTS,
    UPDATE_IS_LOADING_SERVICE_ACCOUNTS, DELETE_SERVICE_ACCOUNT,
    UPDATE_SERVICE_ACCOUNT
} from '../../sagas/service_account/types';

export interface ILoadServiceAccountsAction {
    readonly type: typeof LOAD_SERVICE_ACCOUNTS;
    payload: {
        serviceAccounts: any[],
        totalCountPages: number
    }
}

export const loadServiceAccountsProps = (payload: ILoadServiceAccountsAction['payload']): ILoadServiceAccountsAction => {
    return {
        type: LOAD_SERVICE_ACCOUNTS,
        payload: payload
    }
};

export interface IUpdateIsLoadingServiceAccountsAction {
    readonly type: typeof UPDATE_IS_LOADING_SERVICE_ACCOUNTS;
    payload:{
        isLoadingServiceAccounts: boolean
        errorMessage: string | undefined
    }
}

export const updateIsLoadingServiceAccountsProps = (payload: IUpdateIsLoadingServiceAccountsAction['payload']): IUpdateIsLoadingServiceAccountsAction => {
    return {
        type: UPDATE_IS_LOADING_SERVICE_ACCOUNTS,
        payload: payload
    }
};

export interface IDeleteServiceAccountAction {
    readonly type: typeof DELETE_SERVICE_ACCOUNT;
    payload: {
        serviceAccountId: string,
    }
}

export const deleteServiceAccountProps = (payload: IDeleteServiceAccountAction['payload']): IDeleteServiceAccountAction => {
    return {
        type: DELETE_SERVICE_ACCOUNT,
        payload: payload
    }
};

export interface IUpdateServiceAccountAction {
    readonly type: typeof UPDATE_SERVICE_ACCOUNT;
    payload: {
        serviceAccountId: string,
        data: {
            account_settings: any[]
        }
    }
}

export const updateServiceAccountProps = (payload: IUpdateServiceAccountAction['payload']): IUpdateServiceAccountAction => {
    return {
        type: UPDATE_SERVICE_ACCOUNT,
        payload: payload
    }
};

export type ServiceAccountsActions = ILoadServiceAccountsAction | IUpdateIsLoadingServiceAccountsAction |
IDeleteServiceAccountAction | IUpdateServiceAccountAction;