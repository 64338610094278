import React, { useEffect, useState, useRef, useMemo } from 'react';
import _ from 'lodash';
import './styles/index.scss'
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import Loader from "../../components/loader";
import {
    MDBCol, MDBRow, MDBIcon, MDBContainer, MDBModal,
    MDBModalBody, MDBModalHeader, MDBTabPane, MDBTabContent,
    MDBNav, MDBNavItem, MDBNavLink
} from 'mdbreact';
import Select from '../../components/select';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import {
    ILoadServiceAccountsActionSaga,
    loadServiceAccountPropsSaga,
    deleteServiceAccountPropsSaga,
    updateServiceAccountPropsSaga,
} from '../../sagas/service_account/types';
import Constants from '../../constants';
import { addQueryString } from '../../helpers';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { addServiceAccount, fetchCities, fetchCountires, fetchServiceAccount, fetchServiceAccountUserGroups, fetchSettings, } from '../../api/serviceAccount';
import MainBlueButton from "../../components/blueButton"
import IconContainer from "../../components/iconContainer"
import constants from "../../constants"
import TableMenu from "../../components/tableMenu";
import ReactSelect from 'react-select';
import * as Yup from "yup";
import { useFormik } from "formik";
import { usePrevious } from '../../hooks';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

interface IServiceAccountsProps {
}

function ServiceAccount(props: IServiceAccountsProps) {
    const routerReducer = useSelector((state: AppState) => state.router)
    const { searchkeyword, sort, page } = queryString.parse(routerReducer.location.search);
    const [serviceAccountDetailsOpen, toggleServiceAccountDetails] = useState(false);
    const [serviceAccountDetail, updateServiceAccountDetails] = useState<any>();
    const [serviceAccountAddOpen, toggleServiceAccountAdd] = useState(false);
    const [searchText, updateSearchText] = useState(searchkeyword ? searchkeyword.toString() : '');
    const [countriesList, updateCountriesList] = useState<any[]>([]);
    const [statesList, updateStatesList] = useState<any[]>([]);
    const [addSettings, updateSettingsList] = useState<any[]>([]);
    const preCountriesList = usePrevious(countriesList);
    //new ui
    const [view, selectView] = useState(10)
    const [isOpen, toggleModal] = useState(false)

    let headers: any = [
        { name: "ID", width: 1 },
        { name: "Name", width: 3 },
        { name: "Status", width: 3 },
        { name: "Creation Date", width: 3 },
        { name: "", width: 1 }
    ];
    const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);
    const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
    const dispatch = useDispatch();
    const serviceAccountsReducer = useSelector((state: AppState) => state.serviceAccountReducer)

    // container logic
    useEffect(() => {
        const { searchkeyword, sort, page } = queryString.parse(routerReducer.location.search);
        const [columnName, columnSorted] = sort ? sort.toString().split(',') : ['id', 'ascending'];
        const filters: ILoadServiceAccountsActionSaga['filters'] = {
            page: page ? parseInt(page.toString()) - 1 : 0,
            size: view,
            sort: sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc').replace("creation date", "creationDate") : 'id,asc',
            name: searchkeyword ? searchkeyword.toString() : '',
        }
        dispatch(loadServiceAccountPropsSaga(filters));
        // updating components values
        updateHeaderCloumns(updateHeaderSorting(columnName, columnSorted));
        changePage(page ? parseInt(page.toString()) : 1);
        updateSearchText(searchkeyword ? searchkeyword.toString() : '');
    }, [routerReducer.location.search, view]);

    // headers and soring
    const updateHeaderSorting = (columnName?: string, columnSorted?: string): IHeaderColumn[] => {
        return headerColumns.map((headerColumn, i) => {
            if (columnName && headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                switch (columnSorted) {
                    case 'ascending':
                        return {
                            ...headerColumn,
                            sorted: 'ascending'
                        }
                    case 'descending':
                        return {
                            ...headerColumn,
                            sorted: 'descending'
                        }
                    default:
                        return {
                            ...headerColumn,
                            sorted: undefined
                        }
                }
            } else {
                return {
                    ...headerColumn,
                    sorted: undefined
                }
            }
        });
    }

    const handleSort = (columnName: string, columnSorted?: 'ascending' | 'descending') => {
        let sorting = ``;
        headerColumns.map((headerColumn, i) => {
            let colName = headerColumn.name.toLocaleLowerCase()
            if (colName != "" && colName != "account_name") {
                if (headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                    sorting = `${columnName.toLowerCase()},`;
                    switch (columnSorted) {
                        case undefined:
                            sorting += 'ascending';
                            break;
                        case 'ascending':
                            sorting += 'descending';
                            break;
                        default:
                            sorting = '';
                    }
                }
            }
        });
        const newQueryString = addQueryString(routerReducer.location.search, 'sort', sorting);
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }

    // body rows data
    const handleServiceAccountDetails = async (rowValue: any) => {
        const serviceAccount = await fetchServiceAccount(rowValue.id as number);
        const userGroups = await fetchServiceAccountUserGroups(serviceAccount.id);
        editServiceAccountformik.setFieldValue('id', serviceAccount.id);
        editServiceAccountformik.setFieldValue('account_name', serviceAccount.account_name);
        editServiceAccountformik.setFieldValue('branches', _.take(serviceAccount.branches, 5));
        editServiceAccountformik.setFieldValue('user_group', userGroups);
        editServiceAccountformik.setFieldValue('account_settings', serviceAccount.account_settings);
        editServiceAccountformik.setFieldValue('creation_date', serviceAccount.creation_date);
        editServiceAccountformik.setFieldValue('status', serviceAccount.status);
        editServiceAccountformik.setFieldValue('first_name', serviceAccount.account_owner.first_name);
        editServiceAccountformik.setFieldValue('last_name', serviceAccount.account_owner.last_name);
        editServiceAccountformik.setFieldValue('user_id', serviceAccount.account_owner.user_id);
        editServiceAccountformik.setFieldValue('ecommerce_id', serviceAccount.account_owner.ecommerce_id);
        updateServiceAccountDetails(serviceAccount);
        toggleServiceAccountDetails(true);
    }
    const menuItems = [
        {
            title: "View Service Account",
            icon: "eye",
            handleChange: async (rowValue: any) => {
                handleServiceAccountDetails(rowValue);
            }
        },
        // {
        //     title: "Remove service account",
        //     icon: "trash",
        //     handleChange: async (rowValue: any) => {
        //         dispatch(deleteServiceAccountPropsSaga(rowValue.id))
        //     }
        // }
    ]
    const buildRows = (serviceAccounts: any[]): IRow[] => {
        return serviceAccounts.map((serviceAccount: any, i) => {
            let row: IRow = {
                value: serviceAccount,
                props: [
                    'id',
                    {
                        edit: (rowValue: any) => {
                            return (<div className="cursor offblue-text" onClick={async () => {
                                handleServiceAccountDetails(rowValue);
                            }}>{rowValue.account_name}</div>);
                        },
                    },
                    'status',
                    'creation_date',
                    {
                        edit: (rowValue: any) => {
                            return (<TableMenu menuItems={menuItems} rowValue={rowValue} />);
                        }
                    },
                ]
            };
            return row;
        });
    };

    const rows = buildRows(serviceAccountsReducer.serviceAccounts);

    // pagination
    const handlePaginationChange = (e: any, { activePage }: any) => {
        dispatch(push({
            search: addQueryString(routerReducer.location.search, 'page', activePage)
        }));
    }

    //filters
    const handleFilter = (filters: { filterName: string, value: any }[]) => {
        let newQueryString = routerReducer.location.search;
        filters.forEach((filter) => {
            newQueryString = addQueryString(newQueryString, filter.filterName, filter.value);
            newQueryString += '&';
        })
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }

    //reset filters & search
    const handleReset = () => {
        dispatch(push({
            search: ''
        }));
        toggleModal(false)
    }

    const renderModal = () => {
        return <MDBContainer className="filters-modal">
            <MDBModal
                isOpen={isOpen} toggle={() => toggleModal(!isOpen)}
                inline={false} noClickableBodyWithoutBackdrop={false} overflowScroll={false}>
                <MDBModalHeader toggle={() => toggleModal(false)} >Filter</MDBModalHeader>
                <MDBModalBody className="p-3" >
                    <input className="search-input form-control input-rounded" type="text"
                        value={searchText}
                        onChange={(e) => updateSearchText(e.target.value)}
                        placeholder="Search" aria-label="Search" />

                    <MDBRow className="filter-buttons-container w-100 d-flex mt-3 justify-content-center">
                        <MainBlueButton
                            title="Show results"
                            className="mr-2"
                            onClick={() => handleShowResult()}
                        />
                        <MainBlueButton
                            btnClassName="reset-btn"
                            title="Reset"
                            onClick={() => handleReset()}
                        />
                    </MDBRow>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    }

    const handleShowResult = () => {
        const filters: { filterName: string, value: any }[] = [];
        if (searchText != '') {
            filters.push({ filterName: 'searchkeyword', value: searchText });
        }
        handleFilter(filters);
        toggleModal(false);
    }


    // edit ServiceAccount form
    const editServiceAccountvalidationSchema = Yup.object().shape({
        account_name: Yup.string()
            .required('Required')
            .min(2, 'must be at least 2 characters'),
    });
    const editServiceAccountformik = useFormik({
        initialValues: {
            id: '',
            account_name: '',
            branches: [],
            user_group: [],
            account_settings: [],
            status: "",
            creation_date: "",
            first_name: "",
            last_name: "",
            user_id: "",
            ecommerce_id: "",
        },
        validationSchema: editServiceAccountvalidationSchema,
        onSubmit: async (values) => {
            dispatch(updateServiceAccountPropsSaga(
                serviceAccountDetail?.id ? serviceAccountDetail?.id.toString() : '',
                { account_settings: values.account_settings },
            ))
            toggleServiceAccountDetails(false);
        },
    });

    let branchesHeaders: any = [
        { name: "Id", width: 1 },
        { name: "Name", width: 3 },
    ];
    const buildBranchesRows = (branches: any[]): IRow[] => {
        return branches.map((branch: any, i) => {
            let row: IRow = {
                value: branch,
                props: [
                    'id',
                    'name'
                ]
            };
            return row;
        });
    };

    const branchesRows = useMemo(() => buildBranchesRows(editServiceAccountformik.values.branches), [editServiceAccountformik.values.branches]);
    let userGroupHeaders: any = [
        { name: "Id", width: 1 },
        { name: "Name", width: 3 },
    ];
    const buildUserGroupRows = (userGroups: any[]): IRow[] => {
        return userGroups.map((userGroup: any, i) => {
            let row: IRow = {
                value: userGroup,
                props: [
                    'id',
                    'name'
                ]
            };
            return row;
        });
    };

    const userGroupRows = useMemo(() => buildUserGroupRows(editServiceAccountformik.values.user_group), [editServiceAccountformik.values.user_group]);
    // add service account form
    const handleOpenAddForm = async () => {
        const countries = await fetchCountires();
        const settings = await fetchSettings();
        updateSettingsList(settings);
        updateCountriesList(countries.map((country) => ({ value: country.uuid, label: country.name })))
        toggleServiceAccountAdd(true)
    }
    useEffect(() => {
        if (preCountriesList != undefined && (preCountriesList as any).length == 0) {
            const selectedCountry = countriesList.find((country) => country.label.toLowerCase() == 'egypt');
            handleAddCountrySelectOption(selectedCountry);
        }
    }, [countriesList])
    const addServiceAccountvalidationSchema = Yup.object({
        account_name: Yup.string()
            .required('Required'),
        branch_name: Yup.string()
            .required('Required'),
        branch_phone: Yup.string()
            .required('Required')
            .test({
                message: 'Invalid phone number',
                test: (value: any) => {
                    return /^01[0-2]\d{8}$/.test(value);
                }
            }),
        branch_address: Yup.object({
            building_number: Yup.string().required('Required'),
            city: Yup.string().required('Required'),
            country: Yup.mixed().test({
                message: 'Required',
                test: (value: any) => {
                    return value.value != 0;
                }
            }),
            floor: Yup.string().required('Required'),
            state: Yup.mixed().test({
                message: 'Required',
                test: (value: any) => {
                    return value.value != 0;
                }
            }),
            street: Yup.string().required('Required')
        }),
        user: Yup.object({
            ecommerce_id: Yup.string().required('Required'),
            first_name: Yup.string().required('Required'),
            last_name: Yup.string().required('Required'),
            email: Yup.string().required('Required'),
            mobile: Yup.string()
                .required('Required')
                .test({
                    message: 'Invalid phone number',
                    test: (value: any) => {
                        return /^01[0-2]\d{8}$/.test(value);
                    }
                }),
        }),
    });
    const addServiceAccountformik = useFormik({
        initialValues: {
            account_name: '',
            branch_name: '',
            branch_phone: '',
            branch_address: {
                building_number: '',
                city: '',
                country: { value: 0, label: '' },
                floor: '',
                state: { value: 0, label: '' },
                street: ''
            },
            user: {
                first_name: '',
                last_name: '',
                email: '',
                mobile: '',
                ecommerce_id: '',
            },
            settings: []
        },
        validationSchema: addServiceAccountvalidationSchema,
        onSubmit: async (values) => {
            await addServiceAccount({
                service_account_name: values.account_name,
                branch_name: values.account_name,
                branch_phone: values.branch_phone,
                branch_address: {
                    building_number: values.branch_address.building_number,
                    city: values.branch_address.city,
                    country: values.branch_address.country.label,
                    floor: values.branch_address.floor,
                    state: values.branch_address.state.label,
                    street: values.branch_address.street
                },
                user: {
                    first_name: values.user.first_name,
                    last_name: values.user.last_name,
                    email: values.user.email,
                    mobile: values.user.mobile,
                    ecommerce_id: values.user.ecommerce_id,
                },
                account_settings: values.settings.map((setting: any) => ({
                    settings: {
                        id: setting.id,
                        service_account_setting: setting.service_account_setting
                    },
                    value: setting.value
                }))
            });
        },
    });
    const handleAddCountrySelectOption = async (option: any) => {
        addServiceAccountformik.setFieldValue('branch_address.country', option);
        addServiceAccountformik.setFieldValue('branch_address.state', { value: 0, label: '' });
        const cities = await fetchCities(option.value);
        updateStatesList(cities.map((cities) => ({ value: cities.uuid, label: cities.name })))
    }
    const handleAddStateSelectOption = (option: any) => {
        addServiceAccountformik.setFieldValue('branch_address.state', option);
    }
    const onChangeDate = (date: any, setting: any, settingsIndex: number) => {
        addServiceAccountformik.setFieldValue(`settings[${settingsIndex}]`, { id: setting.id, service_account_setting: setting.service_account_setting, value: date })
    }
    const handleServiceAccountAdd = async () => {
        addServiceAccountformik.submitForm();
    }
    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                <Loader isLoading={serviceAccountsReducer.loadingServiceAccounts.isLoadingServiceAccounts} errorMessage={serviceAccountsReducer.loadingServiceAccounts.errorMessage}>
                    {/*filters service account*/}
                    <MDBRow start className="m-2 mb-4">
                        <MDBCol md="2" size="10" className="p-0 d-flex justify-content-md-start justify-content-center">
                            <MainBlueButton
                                btnClassName="pr-3 pl-3 mr-2"
                                title="+ add service account" className="d-contents" onClick={() => { handleOpenAddForm() }} />
                        </MDBCol>
                        <MDBCol md="6" size="12" className="p-0 mt-3 mt-md-0 d-flex justify-content-md-start justify-content-center">
                            <Select name="view"
                                id="view-select"
                                className="mr-2 d-inline"
                                label="View By"
                                options={constants.viewOptions.map((option, i) => {
                                    return { text: option.value, value: option.id }
                                })}
                                selectedValue={view}
                                onChange={(e: any) => {
                                    selectView(e.target.value)
                                    dispatch(push({
                                        search: ''
                                    }));
                                }}
                            />
                            <IconContainer className="mr-2 d-inline" onClick={() => toggleModal(true)} >
                                <MDBIcon icon="filter" />
                            </IconContainer>
                        </MDBCol>
                    </MDBRow>
                    {/*datatable service account*/}
                    <MDBRow start className="justify-content-center mt-2">
                        <MDBCol className="text-center p-0">
                            {
                                serviceAccountsReducer.serviceAccounts.length > 0 ?
                                    <div className="celled-table">
                                        <DataTable
                                            headerColumns={headerColumns}
                                            headerOnClick={handleSort}
                                            rows={rows}
                                            data={serviceAccountsReducer.serviceAccounts}
                                            totalPages={serviceAccountsReducer.totalCountPages / Constants.pagination.default}
                                            activePage={activePage}
                                            onPageChange={handlePaginationChange}
                                        />
                                    </div>
                                    :
                                    <div>No service accounts found.</div>
                            }
                        </MDBCol>
                    </MDBRow>
                    {/*details service account*/}
                    <MDBModal isOpen={serviceAccountDetailsOpen} toggle={() => { toggleServiceAccountDetails(!serviceAccountDetailsOpen) }}
                        fullHeight={true} position="right"
                        inline={false}
                        noClickableBodyWithoutBackdrop={false}
                        overflowScroll={true}
                        className="full-height-modal full-height-modal-right">
                        <div className="custom-modal-header">
                            <MDBRow className="m-0 pt-5 pb-5">
                                <MDBCol size="12" md="7" className="text-left offblue-text">
                                    <h2 className="ml-2 font-weight-bold">{editServiceAccountformik.values.account_name}</h2>
                                </MDBCol>
                                <MDBCol size="12" md="5" className="mt-4 mt-md-0 actions-container">
                                    <MDBRow className="m-0 p-0">
                                        <MainBlueButton title="cancel" className="d-contents"
                                            btnClassName="btn-modal-header-cancel"
                                            onClick={() => { toggleServiceAccountDetails(false) }} />
                                        <MainBlueButton title="save" className="d-contents"
                                            btnClassName="btn-modal-header-save"
                                            onClick={() => {
                                                editServiceAccountformik.submitForm();
                                            }} />
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </div>
                        <MDBModalBody>
                            <MDBRow className="m-0 p-0">
                                <MDBCol className="m-0 p-0">
                                    <MDBRow className="m-0 p-0 justify-content-around">
                                        <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                            <label className="text-capitalize">name</label>
                                            <input
                                                disabled
                                                type="text"
                                                placeholder="name"
                                                onChange={(e) => { editServiceAccountformik.setFieldValue('name', e.target.value); }}
                                                value={editServiceAccountformik.values.account_name}
                                                className="form-control styled-input"
                                            />
                                            {editServiceAccountformik.errors.account_name && editServiceAccountformik.touched.account_name && <p className='red-text'>{editServiceAccountformik.errors.account_name}</p>}
                                        </MDBCol>
                                        <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                            <label className="text-capitalize">Date Created</label>
                                            <input
                                                disabled
                                                type="text"
                                                placeholder="Date Created"
                                                // onChange={(e) => { editServiceAccountformik.setFieldValue('name', e.target.value); }}
                                                value={editServiceAccountformik.values.creation_date}
                                                className="form-control styled-input"
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 p-0 justify-content-around">
                                        <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                            <label className="text-capitalize">Status</label>
                                            <input
                                                disabled
                                                type="text"
                                                placeholder="Status"
                                                // onChange={(e) => { editServiceAccountformik.setFieldValue('name', e.target.value); }}
                                                value={editServiceAccountformik.values.status}
                                                className="form-control styled-input"
                                            />
                                        </MDBCol>
                                        <MDBCol className="p-0 text-left cursor-disabled" size="5">

                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 p-0 mt-4 justify-content-around">
                                        <MDBCol className="m-0 p-0 justify-content-around">
                                            <label className="text-left font-weight-bold pt-3">User Info</label>
                                            <MDBRow className="m-0 justify-content-around">
                                                <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                    <label className="text-capitalize">First Name</label>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        placeholder="First Name"
                                                        // onChange={(e) => { editServiceAccountformik.setFieldValue('name', e.target.value); }}
                                                        value={editServiceAccountformik.values.first_name}
                                                        className="form-control styled-input"
                                                        maxLength={50}
                                                    />
                                                </MDBCol>
                                                <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                    <label className="text-capitalize">Last Name</label>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        placeholder="Last Name"
                                                        // onChange={(e) => { editServiceAccountformik.setFieldValue('name', e.target.value); }}
                                                        value={editServiceAccountformik.values.last_name}
                                                        className="form-control styled-input"
                                                        maxLength={50}
                                                    />
                                                </MDBCol>
                                                <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                    <label className="text-capitalize">UID</label>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        placeholder="UID"
                                                        // onChange={(e) => { editServiceAccountformik.setFieldValue('name', e.target.value); }}
                                                        value={editServiceAccountformik.values.user_id}
                                                        className="form-control styled-input"
                                                        maxLength={50}

                                                    />
                                                </MDBCol>
                                                <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                    <label className="text-capitalize">e-commerce ID</label>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        placeholder="ecommerce_id"
                                                        // onChange={(e) => { editServiceAccountformik.setFieldValue('name', e.target.value); }}
                                                        value={editServiceAccountformik.values.ecommerce_id}
                                                        className="form-control styled-input"
                                                        maxLength={50}
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 p-0 mt-4 justify-content-around">
                                        <MDBCol className="text-center">
                                            <label className="text-left font-weight-bold pt-3">Branches</label>
                                            {
                                                editServiceAccountformik.values.branches.length > 0 ?
                                                    <div className="celled-table">
                                                        <MDBRow start className="ml-4 justify-content-center">
                                                            <MDBCol className="text-center">
                                                                <DataTable
                                                                    headerColumns={branchesHeaders}
                                                                    rows={branchesRows}
                                                                />
                                                                <MDBCol size="4" className="text-center mx-auto">
                                                                    <div className="form-control styled-input">
                                                                        <a href={`/service-account/${editServiceAccountformik.values.id}/branches`}
                                                                            target="_blank" className="view-all-link">
                                                                            view all</a>
                                                                    </div>
                                                                </MDBCol>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </div> : <div>There are no branches.</div>
                                            }
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 p-0 mt-4 justify-content-around">
                                        <MDBCol className="text-center">
                                            <label className="text-left font-weight-bold pt-3">User groups</label>
                                            {
                                                editServiceAccountformik.values.user_group.length > 0 ?
                                                    <div className="celled-table">
                                                        <MDBRow start className="ml-4 justify-content-center">
                                                            <MDBCol className="text-center">
                                                                <DataTable
                                                                    headerColumns={userGroupHeaders}
                                                                    rows={userGroupRows}
                                                                />
                                                                <MDBCol size="4" className="text-center mx-auto">
                                                                    <div className="form-control styled-input">
                                                                        <a href={`/service-account/${editServiceAccountformik.values.id}/user-groups`}
                                                                            target="_blank" className="view-all-link">
                                                                            view all</a>
                                                                    </div>
                                                                </MDBCol>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </div> : <div>There are no user groups.</div>
                                            }
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 p-0 mt-4 justify-content-around">
                                        <MDBCol className="text-center">
                                            <label className="text-left font-weight-bold pt-3">Settings</label>
                                            {
                                                editServiceAccountformik.values.account_settings.map((setting: any, i) => {
                                                    switch (setting.settings.value_type) {
                                                        case Constants.settingsTypes.text:
                                                            return (
                                                                <MDBCol key={i} className="p-0 mt-3 text-left" size="5">
                                                                    <label className="text-capitalize">{setting.settings.service_account_setting}</label>
                                                                    <input
                                                                        type="text"
                                                                        placeholder={setting.settings.service_account_setting}
                                                                        onChange={(e) => { editServiceAccountformik.setFieldValue(`account_settings[${i}]`, { settings: { ...setting.settings }, value: e.target.value }); }}
                                                                        value={editServiceAccountformik.values.account_settings[i] ? (editServiceAccountformik.values.account_settings[i] as any).value : ''}
                                                                        className="form-control styled-input"
                                                                        maxLength={50}
                                                                    />
                                                                </MDBCol>
                                                            );
                                                        case Constants.settingsTypes.number:
                                                            return (
                                                                <MDBCol key={i} className="p-0 mt-3 text-left" size="5">
                                                                    <label className="text-capitalize">{setting.settings.service_account_setting}</label>
                                                                    <input
                                                                        type="number"
                                                                        min={1}
                                                                        placeholder={setting.settings.service_account_setting}
                                                                        onChange={(e) => { editServiceAccountformik.setFieldValue(`account_settings[${i}]`, { settings: { ...setting.settings }, value: e.target.value }); }}
                                                                        value={editServiceAccountformik.values.account_settings[i] ? (editServiceAccountformik.values.account_settings[i] as any).value : ''}
                                                                        className="form-control styled-input"
                                                                    />
                                                                </MDBCol>
                                                            );
                                                        case Constants.settingsTypes.date:
                                                            return (
                                                                <MDBCol key={i} className="calendar-input p-0 mt-3 text-left" size="5">
                                                                    <label className="text-capitalize d-block">{setting.settings.service_account_setting}</label>
                                                                    <DatePicker key={i} wrapperClassName="calender-container"
                                                                        dateFormat="yyyy-MM-dd"
                                                                        selected={editServiceAccountformik.values.account_settings[i] ? typeof (editServiceAccountformik.values.account_settings[i] as any).value == 'string' ? new Date((editServiceAccountformik.values.account_settings[i] as any).value.split('T')[0]) : (editServiceAccountformik.values.account_settings[i] as any).value : new Date()}
                                                                        placeholderText="YYYY-MM-DD"
                                                                        onChange={(date: any) => editServiceAccountformik.setFieldValue(`account_settings[${i}]`, { settings: { ...setting.settings }, value: date })} />
                                                                </MDBCol>
                                                            );
                                                    }
                                                })
                                            }
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>
                    {/*add service account*/}
                    <MDBModal isOpen={serviceAccountAddOpen} toggle={() => { toggleServiceAccountAdd(!serviceAccountAddOpen) }}
                        fullHeight={true} position="right"
                        inline={false}
                        noClickableBodyWithoutBackdrop={false}
                        overflowScroll={true}
                        className="full-height-modal full-height-modal-right">
                        <div className="custom-modal-header">
                            <MDBRow className="m-0 pt-5 pb-5">
                                <MDBCol size="8" className="text-left offblue-text">
                                    <h2 className="font-weight-bold">Add service account</h2>
                                </MDBCol>
                                <div className="mt-4 mt-md-0 actions-container">
                                    <MDBRow className="m-0 p-0">

                                        <MainBlueButton title="cancel" className="d-contents"
                                            btnClassName="btn-modal-header-cancel"
                                            onClick={() => { toggleServiceAccountAdd(false) }} />
                                        <MainBlueButton title="save" className="d-contents"
                                            btnClassName="btn-modal-header-save"
                                            onClick={() => {
                                                handleServiceAccountAdd();
                                            }} />

                                    </MDBRow>
                                </div>
                            </MDBRow>
                        </div>
                        <MDBModalBody>
                            <MDBRow className="m-0 p-0">
                                <MDBCol className="m-0 p-0">
                                    <MDBRow className="m-0 p-0 justify-content-around">
                                        <MDBCol className="p-0 text-left" size="5">
                                            <label className="text-capitalize">service account name</label>
                                            <input
                                                type="text"
                                                placeholder="service account name"
                                                onChange={(e) => { addServiceAccountformik.setFieldValue('account_name', e.target.value); }}
                                                value={addServiceAccountformik.values.account_name}
                                                className="form-control styled-input"
                                                maxLength={50}
                                            />
                                            {addServiceAccountformik.errors.account_name && addServiceAccountformik.touched.account_name && <p className='red-text'>{addServiceAccountformik.errors.account_name}</p>}
                                        </MDBCol>
                                        <MDBCol className="p-0 text-left" size="5"></MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 mt-3 mb-3 p-0">
                                        <MDBCol className="p-0 text-center" size="12">
                                            <h4 className="medium-text">Branch data</h4>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 p-0 justify-content-around">
                                        <MDBCol className="p-0 text-left" size="5">
                                            <label className="text-capitalize">name</label>
                                            <input
                                                type="text"
                                                placeholder="name"
                                                onChange={(e) => { addServiceAccountformik.setFieldValue('branch_name', e.target.value); }}
                                                value={addServiceAccountformik.values.branch_name}
                                                className="form-control styled-input"
                                                maxLength={50}

                                            />
                                            {addServiceAccountformik.errors.branch_name && addServiceAccountformik.touched.branch_name && <p className='red-text'>{addServiceAccountformik.errors.branch_name}</p>}
                                        </MDBCol>
                                        <MDBCol className="p-0 text-left" size="5">
                                            <label className="text-capitalize">phone</label>
                                            <input
                                                type="text"
                                                placeholder="phone"
                                                onChange={(e) => { addServiceAccountformik.setFieldValue('branch_phone', e.target.value); }}
                                                value={addServiceAccountformik.values.branch_phone}
                                                className="form-control styled-input"

                                            />
                                            {addServiceAccountformik.errors.branch_phone && addServiceAccountformik.touched.branch_phone && <p className='red-text'>{addServiceAccountformik.errors.branch_phone}</p>}
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 mt-3 p-0 justify-content-around">
                                        <MDBCol className="p-0 text-left" size="5">
                                            <label className="text-capitalize">building number</label>
                                            <input
                                                type="text"
                                                placeholder="building number"
                                                onChange={(e) => { addServiceAccountformik.setFieldValue('branch_address.building_number', e.target.value); }}
                                                value={addServiceAccountformik.values.branch_address.building_number}
                                                className="form-control styled-input"
                                                maxLength={5}

                                            />
                                            {addServiceAccountformik.errors.branch_address?.building_number && addServiceAccountformik.touched.branch_address?.building_number && <p className='red-text'>{addServiceAccountformik.errors.branch_address.building_number}</p>}
                                        </MDBCol>
                                        <MDBCol className="p-0 text-left" size="5">
                                            <label className="text-capitalize">floor number</label>
                                            <input
                                                type="text"
                                                placeholder="floor number"
                                                onChange={(e) => { addServiceAccountformik.setFieldValue('branch_address.floor', e.target.value); }}
                                                value={addServiceAccountformik.values.branch_address.floor}
                                                className="form-control styled-input"
                                                maxLength={5}
                                            />
                                            {addServiceAccountformik.errors.branch_address?.floor && addServiceAccountformik.touched.branch_address?.floor && <p className='red-text'>{addServiceAccountformik.errors.branch_address.floor}</p>}
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 mt-3 p-0 justify-content-around">
                                        <MDBCol className="p-0 text-left" size="5">
                                            <label className="text-capitalize">street</label>
                                            <input
                                                type="text"
                                                placeholder="street"
                                                onChange={(e) => { addServiceAccountformik.setFieldValue('branch_address.street', e.target.value); }}
                                                value={addServiceAccountformik.values.branch_address.street}
                                                className="form-control styled-input"
                                                maxLength={50}

                                            />
                                            {addServiceAccountformik.errors.branch_address?.street && addServiceAccountformik.touched.branch_address?.street && <p className='red-text'>{addServiceAccountformik.errors.branch_address.street}</p>}
                                        </MDBCol>
                                        <MDBCol className="p-0 text-left" size="5">
                                            <label className="text-capitalize">city</label>
                                            <input
                                                type="text"
                                                placeholder="city"
                                                onChange={(e) => { addServiceAccountformik.setFieldValue('branch_address.city', e.target.value); }}
                                                value={addServiceAccountformik.values.branch_address.city}
                                                className="form-control styled-input"
                                                maxLength={50}

                                            />
                                            {addServiceAccountformik.errors.branch_address?.city && addServiceAccountformik.touched.branch_address?.city && <p className='red-text'>{addServiceAccountformik.errors.branch_address.city}</p>}
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 mt-3 p-0 justify-content-around">
                                        <MDBCol className="p-0 text-left" size="5">
                                            <label className="text-capitalize">country</label>
                                            <ReactSelect
                                                className="basic-single"
                                                defaultValue={addServiceAccountformik.values.branch_address.country}
                                                value={addServiceAccountformik.values.branch_address.country}
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={false}
                                                isRtl={false}
                                                isSearchable={false}
                                                name="country"
                                                options={countriesList}
                                                onChange={handleAddCountrySelectOption}
                                            />
                                            {addServiceAccountformik.errors.branch_address?.country && addServiceAccountformik.touched.branch_address?.country && <p className='red-text'>{addServiceAccountformik.errors.branch_address.country}</p>}
                                        </MDBCol>
                                        <MDBCol className="p-0 text-left" size="5">
                                            <label className="text-capitalize">state</label>
                                            <ReactSelect
                                                className="basic-single"
                                                defaultValue={addServiceAccountformik.values.branch_address.state}
                                                value={addServiceAccountformik.values.branch_address.state}
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={false}
                                                isRtl={false}
                                                isSearchable={false}
                                                name="status"
                                                options={statesList}
                                                onChange={handleAddStateSelectOption}
                                            />
                                            {addServiceAccountformik.errors.branch_address?.state && addServiceAccountformik.touched.branch_address?.state && <p className='red-text'>{addServiceAccountformik.errors.branch_address.state}</p>}
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 mt-3 mb-3 p-0">
                                        <MDBCol className="p-0 text-center" size="12">
                                            <h4 className="medium-text">User data</h4>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 mt-3 p-0 justify-content-around">
                                        <MDBCol className="p-0 text-left" size="5">
                                            <label className="text-capitalize">E-commerce Id</label>
                                            <input
                                                type="text"
                                                placeholder="E-commerce Id"
                                                onChange={(e) => { addServiceAccountformik.setFieldValue('user.ecommerce_id', e.target.value); }}
                                                value={addServiceAccountformik.values.user.ecommerce_id}
                                                className="form-control styled-input"
                                                maxLength={50}

                                            />
                                            {addServiceAccountformik.errors.user?.ecommerce_id && addServiceAccountformik.touched.user?.ecommerce_id && <p className='red-text'>{addServiceAccountformik.errors.user.ecommerce_id}</p>}
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 mt-3 p-0 justify-content-around">
                                        <MDBCol className="p-0 text-left" size="5">
                                            <label className="text-capitalize">first name</label>
                                            <input
                                                type="text"
                                                placeholder="first name"
                                                onChange={(e) => { addServiceAccountformik.setFieldValue('user.first_name', e.target.value); }}
                                                value={addServiceAccountformik.values.user.first_name}
                                                className="form-control styled-input"
                                                maxLength={50}

                                            />
                                            {addServiceAccountformik.errors.user?.first_name && addServiceAccountformik.touched.user?.first_name && <p className='red-text'>{addServiceAccountformik.errors.user.first_name}</p>}
                                        </MDBCol>
                                        <MDBCol className="p-0 text-left" size="5">
                                            <label className="text-capitalize">last name</label>
                                            <input
                                                type="text"
                                                placeholder="last name"
                                                onChange={(e) => { addServiceAccountformik.setFieldValue('user.last_name', e.target.value); }}
                                                value={addServiceAccountformik.values.user.last_name}
                                                className="form-control styled-input"
                                                maxLength={50}
                                            />
                                            {addServiceAccountformik.errors.user?.last_name && addServiceAccountformik.touched.user?.last_name && <p className='red-text'>{addServiceAccountformik.errors.user.last_name}</p>}
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 mt-3 p-0 justify-content-around">
                                        <MDBCol className="p-0 text-left" size="5">
                                            <label className="text-capitalize">email</label>
                                            <input
                                                type="text"
                                                placeholder="email"
                                                onChange={(e) => { addServiceAccountformik.setFieldValue('user.email', e.target.value); }}
                                                value={addServiceAccountformik.values.user.email}
                                                className="form-control styled-input"
                                                maxLength={50}
                                            />
                                            {addServiceAccountformik.errors.user?.email && addServiceAccountformik.touched.user?.email && <p className='red-text'>{addServiceAccountformik.errors.user.email}</p>}
                                        </MDBCol>
                                        <MDBCol className="p-0 text-left" size="5">
                                            <label className="text-capitalize">phone</label>
                                            <input
                                                type="text"
                                                placeholder="phone"
                                                onChange={(e) => { addServiceAccountformik.setFieldValue('user.mobile', e.target.value); }}
                                                value={addServiceAccountformik.values.user.mobile}
                                                className="form-control styled-input"
                                                maxLength={50}
                                            />
                                            {addServiceAccountformik.errors.user?.mobile && addServiceAccountformik.touched.user?.mobile && <p className='red-text'>{addServiceAccountformik.errors.user.mobile}</p>}
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 mt-3 mb-3 p-0">
                                        <MDBCol className="p-0 text-center" size="12">
                                            <h4 className="medium-text">Settings</h4>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 mt-3 p-0 justify-content-around">
                                        {
                                            addSettings.map((setting, i) => {
                                                switch (setting.value_type) {
                                                    case Constants.settingsTypes.text:
                                                        return (
                                                            <MDBCol key={i} className="p-0 mt-3 text-left" size="5">
                                                                <label className="text-capitalize">{setting.service_account_setting}</label>
                                                                <input
                                                                    type="text"
                                                                    placeholder={setting.service_account_setting}
                                                                    onChange={(e) => { addServiceAccountformik.setFieldValue(`settings[${i}]`, { id: setting.id, service_account_setting: setting.service_account_setting, value: e.target.value }); }}
                                                                    value={addServiceAccountformik.values.settings[i] ? (addServiceAccountformik.values.settings[i] as any).value : ''}
                                                                    className="form-control styled-input"
                                                                    maxLength={50}
                                                                />
                                                            </MDBCol>
                                                        );
                                                    case Constants.settingsTypes.number:
                                                        return (
                                                            <MDBCol key={i} className="p-0 mt-3 text-left" size="5">
                                                                <label className="text-capitalize">{setting.service_account_setting}</label>
                                                                <input
                                                                    type="number"
                                                                    min={1}
                                                                    placeholder={setting.service_account_setting}
                                                                    onChange={(e) => { addServiceAccountformik.setFieldValue(`settings[${i}]`, { id: setting.id, service_account_setting: setting.service_account_setting, value: e.target.value }); }}
                                                                    value={addServiceAccountformik.values.settings[i] ? (addServiceAccountformik.values.settings[i] as any).value : ''}
                                                                    className="form-control styled-input"
                                                                    maxLength={50}
                                                                />
                                                            </MDBCol>
                                                        );
                                                    case Constants.settingsTypes.date:
                                                        return (
                                                            <MDBCol key={i} className="calendar-input p-0 mt-3 text-left" size="5">
                                                                <label className="text-capitalize d-block">{setting.service_account_setting}</label>
                                                                <DatePicker key={i} wrapperClassName="calender-container" minDate={new Date()}
                                                                    dateFormat="yyyy-MM-dd"
                                                                    selected={addServiceAccountformik.values.settings[i] ? (addServiceAccountformik.values.settings[i] as any).value : ''}
                                                                    placeholderText="YYYY-MM-DD"
                                                                    onChange={(date: any) => onChangeDate(date, setting, i)} />
                                                            </MDBCol>
                                                        );
                                                }
                                            })
                                        }
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>
                </Loader>
                {renderModal()}
            </MDBCol>
        </MDBRow>
    )
}

export default ServiceAccount;