import { IUpdateIsLoadingStoragesAction, StorageActions } from './types';
import {
    LOAD_STORAGES, LOAD_STORAGE,
    LOAD_STORAGE_TRANSACTIONS,
    LOAD_MODELS, LOAD_SERVICE_ACCOUNTS,
    LOAD_ACCOUNT_BRANCHES, UPDATE_STORAGE,
    ADD_NEW_STORAGE,
} from "../../sagas/storage/types";
import IStorage from "../../dtos/IStorage"
import IModel from "../../dtos/IModel"
import IServiceAccount from "../../dtos/IServiceAccount"
import IBranch from "../../dtos/IBranch"
interface IStorageStatus {
    storages: IStorage[],
    totalStoragesCount: number,
    storage: IStorage,
    storageTransactions: any,

    models: IModel[],
    serviceAccounts: IServiceAccount[],
    accountBranches: IBranch[]
    storageAdded: boolean,
    storageUpdated: boolean,

    loadingStorages: IUpdateIsLoadingStoragesAction['payload'],
}
const StorageStatus: IStorageStatus = {
    storages: [],
    totalStoragesCount: 0,
    storage: {
        storage_id: '',
        name: '',
        type: '',
        model: {
            model_id: 0,
            name: '',
            size: 0
        },
        last_accessed_by_id: null
    },
    storageTransactions: [],
    models: [],
    serviceAccounts: [],
    accountBranches: [],
    storageUpdated: false,
    storageAdded: false,
    loadingStorages: {
        isLoadingStorages: false,
        errorMessage: undefined
    },
}
const storageReducer = (state = StorageStatus, action: StorageActions): IStorageStatus => {
    switch (action.type) {
        case LOAD_STORAGES:
            return {
                ...state,
                storages: action.payload.storages,
                totalStoragesCount: action.payload.totalStoragesCount
            }
        case LOAD_STORAGE:
            return {
                ...state,
                storage: action.payload.storage
            }
        case LOAD_STORAGE_TRANSACTIONS:
            return {
                ...state,
                storageTransactions: action.payload.storageTransactions
            }
        case LOAD_MODELS:
            return {
                ...state,
                models: action.payload.models
            }
        case LOAD_SERVICE_ACCOUNTS:
            return {
                ...state,
                serviceAccounts: action.payload.serviceAccounts
            }
        case LOAD_ACCOUNT_BRANCHES:
            return {
                ...state,
                accountBranches: action.payload.accountBranches
            }
        case ADD_NEW_STORAGE:
            return {
                ...state,
                storageAdded: action.payload.storageAdded
            }
        case UPDATE_STORAGE:
            return {
                ...state,
                storageUpdated: action.payload.storageUpdated
            }
        default:
            return state;
    }
}
export default storageReducer;

