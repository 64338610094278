import React from "react";
import './index.scss'
import { MDBRow, MDBCol, MDBCardText } from 'mdbreact';
import CardRequest from "../../containers/card_requests";
import cardRequestsIcon from "../../assets/img/pages-icons/card-requests-icon.png";

interface ICardRequestsPageProps {
}

export default function CardRequestsPage(props: ICardRequestsPageProps) {
    return (
        <MDBRow className="m-0">
            <MDBRow className="ml-2 mt-0 mb-0 w-100">
                <MDBCol size={`12`} md={`6`}>
                    <img className="page-image d-inline-block" src={cardRequestsIcon} />
                    <MDBCardText className="page-title d-inline-block">Card Requests</MDBCardText>
                    <span className="page-line d-inline-block" />
                </MDBCol>
            </MDBRow>
            <CardRequest />
        </MDBRow>
    )
}