import React from "react";
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import InvitationsListPage from "./invitationsList";
import UsersListPage from "./usersList"
import TabsButtons from "../../components/tabsButtons/index"
import { MDBRow, MDBCardText, MDBCol } from 'mdbreact';
import usersIcon from "../../assets/img/pages-icons/users-icon.png";

interface IUsersPageProps {
}

export default function UsersPage(props: IUsersPageProps) {
    let { path } = useRouteMatch();
    const tabs = [
        {
            title: 'Users',
            path: '/users',
        },
        {
            title: 'Invitations',
            path: '/users/invitations',
        },

    ]
    return (
        <>
            <MDBRow className="m-0">
                <MDBRow className="ml-2 mt-0 mb-0 w-100">
                    <MDBCol size={`12`} md={`6`}>
                        <img className="page-image d-inline-block" src={usersIcon} />
                        <MDBCardText className="page-title d-inline-block">Users</MDBCardText>
                        <span className="page-line d-inline-block" />
                    </MDBCol>
                    <MDBCol size={`12`} md={`6`} className={`d-flex justify-content-center justify-content-md-end`}>
                        <TabsButtons tabs={tabs} />
                    </MDBCol>
                </MDBRow>
            </MDBRow>
            <Switch>
                <Route path={`${path}/invitations`} component={InvitationsListPage} />
                <Route exact path={path} component={UsersListPage} />
            </Switch>
        </>
    )
}