import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import './styles/index.scss'
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import Loader from "../../components/loader";
import {
    MDBCol, MDBRow, MDBIcon, MDBContainer, MDBModal,
    MDBModalBody, MDBModalHeader, MDBTabPane, MDBTabContent,
    MDBNav, MDBNavItem, MDBNavLink
} from 'mdbreact';
import Select from '../../components/select';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import {
    ILoadCardRequestsActionSaga,
    loadCardRequestsPropsSaga,
    deleteCardRequestPropsSaga,
    updateCardRequestPropsSaga,
} from '../../sagas/card_requests/types';
import Constants from '../../constants';
import { addQueryString } from '../../helpers';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { addCardRequest, fetchCardRequest, fetchCardRequestStatuses } from '../../api/cardRequests';
import MainBlueButton from "../../components/blueButton"
import IconContainer from "../../components/iconContainer"
import constants from "../../constants"
import TableMenu from "../../components/tableMenu";
import ReactSelect from 'react-select';
import * as Yup from "yup";
import { useFormik } from "formik";

interface ICardRequestsProps {
}

function CardRequest(props: ICardRequestsProps) {
    const routerReducer = useSelector((state: AppState) => state.router)
    const { searchkeyword, sort, page } = queryString.parse(routerReducer.location.search);
    const [cardRequestDetailsOpen, toggleCardRequestDetails] = useState(false);
    const [cardRequestDetail, updateCardRequestDetails] = useState<any>();
    const [cardRequestStatuses, updateCardRequestStatuses] = useState<any[]>([]);
    const [cardRequestAddOpen, toggleCardRequestAdd] = useState(false);
    const [searchText, updateSearchText] = useState(searchkeyword ? searchkeyword.toString() : '');
    //new ui
    const [view, selectView] = useState(10)
    const [isOpen, toggleModal] = useState(false)

    let headers: any = [
        { name: "Id", width: 1 },
        { name: "Tag Number", width: 3 },
        { name: "Owner", width: 3 },
        { name: "Status", width: 2 },
        { name: 'Requested date', width: 3 },
        { name: "", width: 1 },
    ];
    const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);
    const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
    const dispatch = useDispatch();
    const cardRequestsReducer = useSelector((state: AppState) => state.cardRequestsReducer)

    // container logic
    useEffect(() => {
        const { searchkeyword, sort, page } = queryString.parse(routerReducer.location.search);
        const [columnName, columnSorted] = sort ? sort.toString().split(',') : ['owner', 'ascending'];
        const filters: ILoadCardRequestsActionSaga['filters'] = {
            page: page ? parseInt(page.toString()) - 1 : 0,
            size: view,
            sort: sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc') : 'owner,asc',
        }
        dispatch(loadCardRequestsPropsSaga(filters));
        // updating components values
        updateHeaderCloumns(updateHeaderSorting(columnName, columnSorted));
        changePage(page ? parseInt(page.toString()) : 1);
        updateSearchText(searchkeyword ? searchkeyword.toString() : '');
    }, [routerReducer.location.search, view]);

    // headers and soring
    const updateHeaderSorting = (columnName?: string, columnSorted?: string): IHeaderColumn[] => {
        return headerColumns.map((headerColumn, i) => {
            if (columnName && headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                switch (columnSorted) {
                    case 'ascending':
                        return {
                            ...headerColumn,
                            sorted: 'ascending'
                        }
                    case 'descending':
                        return {
                            ...headerColumn,
                            sorted: 'descending'
                        }
                    default:
                        return {
                            ...headerColumn,
                            sorted: undefined
                        }
                }
            } else {
                return {
                    ...headerColumn,
                    sorted: undefined
                }
            }
        });
    }

    const handleSort = (columnName: string, columnSorted?: 'ascending' | 'descending') => {
        let sorting = ``;
        headerColumns.map((headerColumn, i) => {
            let colName = headerColumn.name.toLocaleLowerCase()
            if (colName != "" && colName != "status" && colName != "requested_date" && colName != "tag_number") {
                if (headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                    sorting = `${columnName.toLowerCase()},`;
                    switch (columnSorted) {
                        case undefined:
                            sorting += 'ascending';
                            break;
                        case 'ascending':
                            sorting += 'descending';
                            break;
                        default:
                            sorting = '';
                    }
                }
            }
        });
        const newQueryString = addQueryString(routerReducer.location.search, 'sort', sorting);
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }

    // body rows data
    const handleOpenDetails = async (rowValue: any) => {
        const cardRequest = await fetchCardRequest(rowValue.id as number);
        const cardStatuses = await fetchCardRequestStatuses(cardRequest.status.name);
        updateCardRequestStatuses(cardStatuses.map((cardStatus: any, i: number) => ({ value: cardStatus.id, label: cardStatus.name })));
        editCardRequestformik.setFieldValue('owner', cardRequest.owner);
        editCardRequestformik.setFieldValue('status', { value: cardRequest.status.id, label: cardRequest.status.name });
        editCardRequestformik.setFieldValue('tag_number', cardRequest.tag_number ? cardRequest.tag_number : editCardRequestformik.values.tag_number);
        updateCardRequestDetails(cardRequest);
        toggleCardRequestDetails(true);
    }
    const menuItems = [
        {
            title: "Edit card request",
            icon: "edit",
            handleChange: (rowValue: any) => {
                handleOpenDetails(rowValue);
            }
        },
    ]
    const buildRows = (cardRequests: any[]): IRow[] => {
        return cardRequests.map((cardRequest: any, i) => {
            let row: IRow = {
                value: cardRequest,
                props: [
                    'id',
                    'tag_number',
                    {
                        edit: (rowValue: any) => {
                            return (<div className="cursor offblue-text" onClick={() => {
                                handleOpenDetails(rowValue);
                            }}>{rowValue.owner}</div>);
                        },
                    },
                    'status.name',
                    'requested_date',
                    {
                        edit: (rowValue: any) => {
                            return (<TableMenu menuItems={menuItems} rowValue={rowValue} />);
                        }
                    }
                ]
            };
            return row;
        });
    };

    const rows = buildRows(cardRequestsReducer.cardRequests);

    // pagination
    const handlePaginationChange = (e: any, { activePage }: any) => {
        dispatch(push({
            search: addQueryString(routerReducer.location.search, 'page', activePage)
        }));
    }

    //filters
    const handleFilter = (filters: { filterName: string, value: any }[]) => {
        let newQueryString = routerReducer.location.search;
        filters.forEach((filter) => {
            newQueryString = addQueryString(newQueryString, filter.filterName, filter.value);
            newQueryString += '&';
        })
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }

    //reset filters & search
    const handleReset = () => {
        dispatch(push({
            search: ''
        }));
        toggleModal(false)
    }

    const renderModal = () => {
        return <MDBContainer className="filters-modal">
            <MDBModal
                isOpen={isOpen} toggle={() => toggleModal(!isOpen)}
                inline={false} noClickableBodyWithoutBackdrop={false} overflowScroll={false}>
                <MDBModalHeader toggle={() => toggleModal(false)} >Filter</MDBModalHeader>
                <MDBModalBody className="p-3" >
                    <input className="search-input form-control input-rounded" type="text"
                        value={searchText}
                        onChange={(e) => updateSearchText(e.target.value)}
                        placeholder="Search" aria-label="Search" />

                    <MDBRow className="filter-buttons-container w-100 d-flex mt-3 justify-content-center">
                        <MainBlueButton
                            title="Show results"
                            className="mr-2"
                            onClick={() => handleShowResult()}
                        />
                        <MainBlueButton
                            btnClassName="reset-btn"
                            title="Reset"
                            onClick={() => handleReset()}
                        />
                    </MDBRow>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    }

    const handleShowResult = () => {
        const filters: { filterName: string, value: any }[] = [];
        if (searchText != '') {
            filters.push({ filterName: 'searchkeyword', value: searchText });
        }
        handleFilter(filters);
        toggleModal(false);
    }


    // edit CardRequest form
    const editCardRequestvalidationSchema = Yup.object().shape({
        owner: Yup.string()
            .required('Required')
            .min(2, 'must be at least 2 characters'),
        status: Yup.object().required('Required'),
        tag_number: Yup.string()
            .required('Required')
            .min(2, 'must be at least 2 characters'),
    });
    const editCardRequestformik = useFormik({
        initialValues: {
            owner: '',
            status: { value: 0, label: '' },
            tag_number: ''
        },
        validationSchema: editCardRequestvalidationSchema,
        onSubmit: async (values) => {
            dispatch(updateCardRequestPropsSaga(
                cardRequestDetail?.id ? cardRequestDetail?.id.toString() : '',
                { tag_number: values.tag_number.toString(), status: { id: values.status.value.toString(), name: values.status.label } },
            ))
            toggleCardRequestDetails(false);
        },
    });
    const handleSelectOption = (option: any) => {
        editCardRequestformik.setFieldValue('status', option);
    }

    // add card request form
    const addCardRequestvalidationSchema = Yup.object().shape({
        owner: Yup.string()
            .required('Required'),
    });
    const addCardRequestformik = useFormik({
        initialValues: {
            owner: '',
        },
        validationSchema: addCardRequestvalidationSchema,
        onSubmit: async (values) => {
            await addCardRequest({ owner: values.owner })
            window.location.reload();
        },
    });
    const handleCardRequestAdd = async () => {
        addCardRequestformik.submitForm();
    }
    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                <Loader isLoading={cardRequestsReducer.loadingCardRequests.isLoadingCardRequests} errorMessage={cardRequestsReducer.loadingCardRequests.errorMessage}>
                    {/*filters card request*/}
                    <MDBRow start className="m-2 mb-4">
                        {/* <MDBCol md="2" size="12" className="p-0 d-flex justify-content-md-start justify-content-center">
                            <MainBlueButton
                                btnClassName="pr-3 pl-3 mr-2"
                                title="+ add card request" className="d-contents" onClick={() => { toggleCardRequestAdd(true) }} />
                        </MDBCol> */}
                        <MDBCol md="6" size="12" className="p-0 mt-3 mt-md-0 d-flex justify-content-md-start justify-content-center">
                            <Select name="view"
                                id="view-select"
                                className="mr-2 d-inline"
                                label="View By"
                                options={constants.viewOptions.map((option, i) => {
                                    return { text: option.value, value: option.id }
                                })}
                                selectedValue={view}
                                onChange={(e: any) => {
                                    selectView(e.target.value)
                                    dispatch(push({
                                        search: ''
                                    }));
                                }}
                            />
                            <IconContainer className="mr-2 d-inline" onClick={() => toggleModal(true)} >
                                <MDBIcon icon="filter" />
                            </IconContainer>
                        </MDBCol>
                    </MDBRow>
                    {/*datatable card request*/}
                    <MDBRow start className="justify-content-center mt-2">
                        <MDBCol className="text-center p-0">
                            {
                                cardRequestsReducer.cardRequests.length > 0 ?
                                    <div className="celled-table">
                                        <DataTable
                                            headerColumns={headerColumns}
                                            headerOnClick={handleSort}
                                            rows={rows}
                                            data={cardRequestsReducer.cardRequests}
                                            totalPages={cardRequestsReducer.totalCountPages / Constants.pagination.default}
                                            activePage={activePage}
                                            onPageChange={handlePaginationChange}
                                        />
                                    </div>
                                    :
                                    <div>No card requests found.</div>
                            }
                        </MDBCol>
                    </MDBRow>
                    {/*details card request*/}
                    <MDBModal isOpen={cardRequestDetailsOpen} toggle={() => { toggleCardRequestDetails(!cardRequestDetailsOpen) }}
                        fullHeight={true} position="right"
                        inline={false}
                        noClickableBodyWithoutBackdrop={false}
                        overflowScroll={true}
                        className="full-height-modal full-height-modal-right">
                        <div className="custom-modal-header">
                            <MDBRow className="m-0 pt-5 pb-5">
                                <MDBCol size="12" md="7" className="text-left offblue-text">
                                    <h2 className="ml-2 font-weight-bold">{editCardRequestformik.values.owner}</h2>
                                </MDBCol>
                                <MDBCol size="12" md="5" className="mt-4 mt-md-0 actions-container">
                                    <MDBRow className="m-0 p-0">
                                        <MainBlueButton title="cancel" className="d-contents"
                                            btnClassName="btn-modal-header-cancel"
                                            onClick={() => { toggleCardRequestDetails(false) }} />
                                        <MainBlueButton title="save" className="d-contents"
                                            btnClassName="btn-modal-header-save"
                                            onClick={() => {
                                                editCardRequestformik.submitForm();
                                            }} />
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </div>
                        <MDBModalBody>
                            <MDBRow className="m-0 p-0">
                                <MDBCol className="m-0 p-0">
                                    <MDBRow className="m-0 p-0 justify-content-around">
                                        <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                            <label className="text-capitalize">Owner</label>
                                            <input
                                                disabled
                                                type="text"
                                                placeholder="owner"
                                                onChange={(e) => { editCardRequestformik.setFieldValue('owner', e.target.value); }}
                                                value={editCardRequestformik.values.owner}
                                                className="form-control styled-input"
                                            />
                                            {editCardRequestformik.errors.owner && <p className='red-text'>{editCardRequestformik.errors.owner}</p>}
                                        </MDBCol>
                                        <MDBCol className="p-0 text-left" size="5">
                                            <label className="text-capitalize">Status</label>
                                            <ReactSelect
                                                className="basic-single"
                                                defaultValue={editCardRequestformik.values.status}
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={false}
                                                isRtl={false}
                                                isSearchable={false}
                                                name="status"
                                                options={cardRequestStatuses}
                                                onChange={handleSelectOption}
                                            />
                                            {editCardRequestformik.errors.status && <p className='red-text'>{editCardRequestformik.errors.status}</p>}
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 p-0 mt-4 justify-content-around">
                                        <MDBCol className="p-0 text-left" size="5">
                                            <label className="text-capitalize">Tag number</label>
                                            {
                                                cardRequestDetail?.tag_number ?
                                                    <p className="mini-text">{cardRequestDetail.tag_number}</p>
                                                    :
                                                    <input
                                                        type="text"
                                                        placeholder="enter tag number"
                                                        onChange={(e) => { editCardRequestformik.setFieldValue('tag_number', e.target.value); }}
                                                        value={editCardRequestformik.values.tag_number}
                                                        className="form-control styled-input"
                                                    />
                                            }
                                            {editCardRequestformik.errors.tag_number && editCardRequestformik.touched.tag_number && <p className='red-text mt-1'>{editCardRequestformik.errors.tag_number}</p>}

                                        </MDBCol>
                                        <MDBCol className="p-0 text-left" size="5">

                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mt-5 text-left">
                                <MDBCol>

                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>
                    {/*add card request*/}
                    <MDBModal isOpen={cardRequestAddOpen} toggle={() => { toggleCardRequestAdd(!cardRequestAddOpen) }}
                        fullHeight={true} position="right"
                        inline={false}
                        noClickableBodyWithoutBackdrop={false}
                        overflowScroll={true}
                        className="full-height-modal full-height-modal-right">
                        <div className="custom-modal-header">
                            <MDBRow className="m-0 pt-5 pb-5">
                                <MDBCol size="8" className="text-left offblue-text">
                                    <h2 className="font-weight-bold">Add card request</h2>
                                </MDBCol>
                                <div className="mt-4 mt-md-0 actions-container">
                                    <MDBRow className="m-0 p-0">

                                        <MainBlueButton title="cancel" className="d-contents"
                                            btnClassName="btn-modal-header-cancel"
                                            onClick={() => { toggleCardRequestAdd(false) }} />
                                        <MainBlueButton title="save" className="d-contents"
                                            btnClassName="btn-modal-header-save"
                                            onClick={() => {
                                                handleCardRequestAdd();
                                            }} />

                                    </MDBRow>
                                </div>
                            </MDBRow>
                        </div>
                        <MDBModalBody>
                            <MDBRow className="mt-2 text-left">
                                <MDBCol>
                                    <label className="text-capitalize">card request name</label>
                                    <input
                                        type="text"
                                        placeholder="card request name"
                                        onChange={(e) => { addCardRequestformik.setFieldValue('owner', e.target.value); }}
                                        value={addCardRequestformik.values.owner}
                                        className="form-control styled-input"
                                    />
                                    {addCardRequestformik.errors.owner && <p className='red-text'>{addCardRequestformik.errors.owner}</p>}
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>
                </Loader>
                {renderModal()}
            </MDBCol>
        </MDBRow>
    )
}

export default CardRequest;