import { axiosInstance } from "../store";
import { handlStatusCodeMessage } from "../helpers";
import { IOrder } from "../dtos/IOrder";
import { IFProduct } from "../dtos/IFProduct";


export function fetchTotalOrdersCount(): Promise<number> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/operation/orders?status=O,P&packing_status=ready,not_ready`)
        .then((res) => res.status == 200 ? res.headers['x-total-count'] : 0)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'fulfillments-center' });
            return 0
        })
}
export function fetchTotalItemsCount(): Promise<number> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/operation/fulfillment_products?order_status=O,P&order_packing_status=ready,not_ready`)
        .then((res) => res.status == 200 ? res.headers['x-total-count'] : 0)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'fulfillments-center' });
            return 0
        })
}
export function fetchReadyOrdersCount(): Promise<number> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/operation/orders?status=O,P&packing_status=ready`)
        .then((res) => res.status == 200 ? res.headers['x-total-count'] : 0)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'fulfillments-center' });
            return 0
        })
}
export function fetchPendingOrdersCount(): Promise<number> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/operation/orders?status=O,P&packing_status=not_ready`)
        .then((res) => res.status == 200 ? res.headers['x-total-count'] : 0)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'fulfillments-center' });
            return 0
        })
}
export function fetchInProgressOrdersCount(): Promise<number> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/operation/orders?status=O,P&packing_status=in_progress&page=0`)
        .then((res) => res.status == 200 ? res.headers['x-total-count'] : 0)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'fulfillments-center' });
            return 0
        })
}
export function fetchReadyItemsCount(): Promise<number> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/operation/fulfillment_products?order_status=O,P&order_packing_status=ready`)
        .then((res) => res.status == 200 ? res.headers['x-total-count'] : 0)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'fulfillments-center' });
            return 0
        })
}
export function fetchPendingItemsCount(): Promise<number> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/operation/fulfillment_products?order_status=O,P&order_packing_status=not_ready`)
        .then((res) => res.status == 200 ? res.headers['x-total-count'] : 0)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'fulfillments-center' });
            return 0
        })
}
export function fetchReadyOrders(page = 0, size = 10, sort = "", status = ""): Promise<{ readyOrders?: IOrder[]; readyOrdersCount?: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/operation/orders?status=O,P&packing_status=${status}&page=${page}&size=${size}&sort=${sort}`)
        .then((res) =>
            res.status == 200 ? {
                readyOrders: res.data,
                readyOrdersCount: res.headers["x-total-count"],
            }
                : {
                    readyOrders: [],
                    readyOrdersCount: 0,
                }
        )
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'fulfillments-center' });
            return {
                readyOrders: [],
                readyOrdersCount: 0,
            };
        });
}

export function fetchInProgressOrders(size = 8): Promise<IOrder[]> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/operation/orders?status=O,P&packing_status=in_progress&page=0&size=${size}`)
        .then((res) =>
            res.status == 200 ? res.data : []
        )
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'fulfillments-center' });
            return []
        });
}
export function fetchInProgressItems(page = 0, size = 10, orderIds = ''): Promise<{ inProgressItems?: IFProduct[]; inProgressItemsCount?: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/operation/fulfillment_products?order_status=O,P&order_packing_status=in_progress&page=${page}&size=${size}&order_ids=${orderIds}`)
        .then((res) => {
            if (res.status == 200) {
                return {
                    inProgressItems: res.data,
                    inProgressItemsCount: res.headers["x-total-count"],
                }
            }
            else {
                return {
                    inProgressItems: [],
                    inProgressItemsCount: 0,
                }
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'fulfillments-center' });
            return {
                inProgressItems: [],
                inProgressItemsCount: 0,
            };
        });
}

export function exportItemsReport(token: string, orderIds = ''): Promise<any> {
    return fetch(`${process.env.REACT_APP_API_URL}/operation/fulfillment_products/export?order_status=O,P&order_packing_status=in_progress&order_ids=${orderIds}`, {
        headers: {
            'Authorization': `Bearer ${!token ? '' : token}`,
            'Content-Type': 'application/csv'
        }
    })
        .then((res) => {
            console.log("ressss", res)
            handlStatusCodeMessage({ code: res.status, entity: 'fulfillments-center' });
            return res.text();
        })
        .catch((err) => {
            return ''
        })
}

export function fetchPackedOrders(page = 0, size = 10): Promise<{ packedOrders?: IOrder[]; packedOrdersCount?: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/operation/orders?status=O,P&packing_status=in_progress&page=${page}&size=${size}`)
        .then((res) =>
            res.status == 200 ? {
                packedOrders: res.data,
                packedOrdersCount: res.headers["x-total-count"],
            }
                : {
                    packedOrders: [],
                    packedOrdersCount: 0,
                }
        )
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'fulfillments-center' });
            return {
                packedOrders: [],
                packedOrdersCount: 0,
            };
        });
}

export function putOrder(order: IOrder): Promise<boolean> {
    return axiosInstance.put(`${process.env.REACT_APP_API_URL}/operation/orders/${order.order_id}`, order)
        .then((res) => res.status == 200 ? true : false)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'fulfillments-center' });
            return false
        });
}

export function sendOrders(): Promise<boolean> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/operation/orders/pack`)
        .then((res) =>
            res.status == 200 ? true : false
        )
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'fulfillments-center' });
            return false
        });
}

