import { IOrder } from "../../dtos/IOrder";

export const LOAD_FULFILLMENTS_CENTER = "LOAD_FULFILLMENTS_CENTER";
export const LOAD_FULFILLMENTS_CENTER_SAGA = "LOAD_FULFILLMENTS_CENTER_SAGA";

export const UPDATE_IS_LOADING_FULFILLMENTS_CENTER = "UPDATE_IS_LOADING_FULFILLMENTS_CENTER"

//ready orders
export const LOAD_READY_ORDERS = "LOAD_READY_ORDERS"
export const LOAD_READY_ORDERS_SAGA = "LOAD_READY_ORDERS_SAGA"
export const UPDATE_IS_LOADING_READY_ORDERS = "UPDATE_IS_LOADING_READY_ORDERS"

//in progress
export const LOAD_IN_PROGRESS_ORDERS_COUNT = "LOAD_IN_PROGRESS_ORDERS_COUNT"
export const LOAD_IN_PROGRESS_ORDERS_COUNT_SAGA = "LOAD_IN_PROGRESS_ORDERS_COUNT_SAGA"

export const LOAD_IN_PROGRESS_ORDERS = "LOAD_IN_PROGRESS_ORDERS"
export const LOAD_IN_PROGRESS_ORDERS_SAGA = "LOAD_IN_PROGRESS_ORDERS_SAGA"

export const LOAD_IN_PROGRESS_ITEMS = "LOAD_IN_PROGRESS_ITEMS"
export const LOAD_IN_PROGRESS_ITEMS_SAGA = "LOAD_IN_PROGRESS_ITEMS_SAGA"

export const UPDATE_IS_LOADING_IN_PROGRESS_ORDERS = "UPDATE_IS_LOADING_IN_PROGRESS_ORDERS"

//packed orders
export const LOAD_PACKED_ORDERS = "LOAD_PACKED_ORDERS"
export const LOAD_PACKED_ORDERS_SAGA = "LOAD_PACKED_ORDERS_SAGA"
export const UPDATE_IS_LOADING_PACKED_ORDERS = "UPDATE_IS_LOADING_PACKED_ORDERS"
//update order
export const UPDATE_ORDER = "UPDATE_ORDER"
export const UPDATE_ORDER_SAGA = "UPDATE_ORDER_SAGA"
//finish orders
export const FINISH_ORDERS = "FINISH_ORDERS"
export const FINISH_ORDERS_SAGA = "FINISH_ORDERS_SAGA"

export interface ILoadFulfillmentsCenterActionSaga {
    readonly type: typeof LOAD_FULFILLMENTS_CENTER_SAGA;
}

export const loadFulfillmentsCenterPropsSaga = (): ILoadFulfillmentsCenterActionSaga => {
    return {
        type: LOAD_FULFILLMENTS_CENTER_SAGA,
    }
};
//ready orders
export interface ILoadReadyOrdersActionSaga {
    readonly type: typeof LOAD_READY_ORDERS_SAGA;
    page: number,
    size: number,
    sort: string,
    status: string
}

export const loadReadyOrdersPropsSaga = (page = 0, size = 10, sort = '', status = ''): ILoadReadyOrdersActionSaga => {
    return {
        type: LOAD_READY_ORDERS_SAGA,
        page: page,
        size: size,
        sort: sort,
        status: status
    }
};
//in progress
export interface ILoadInProgressOrdersCountActionSaga {
    readonly type: typeof LOAD_IN_PROGRESS_ORDERS_COUNT_SAGA;
}

export const loadInProgressOrdersCountPropsSaga = (): ILoadInProgressOrdersCountActionSaga => {
    return {
        type: LOAD_IN_PROGRESS_ORDERS_COUNT_SAGA,
    }
};

export interface ILoadInProgressOrdersActionSaga {
    readonly type: typeof LOAD_IN_PROGRESS_ORDERS_SAGA;
    size: number,
}

export const loadInProgressOrdersPropsSaga = (size = 8): ILoadInProgressOrdersActionSaga => {
    return {
        type: LOAD_IN_PROGRESS_ORDERS_SAGA,
        size: size,
    }
};
export interface ILoadInProgressItemsActionSaga {
    readonly type: typeof LOAD_IN_PROGRESS_ITEMS_SAGA;
    page: number,
    size: number,
    orderIds: string,
}

export const loadInProgressItemsPropsSaga = (page = 0, size = 10, orderIds = ''): ILoadInProgressItemsActionSaga => {
    return {
        type: LOAD_IN_PROGRESS_ITEMS_SAGA,
        page: page,
        size: size,
        orderIds: orderIds,
    }
};
//packed orders
export interface ILoadPackedOrdersActionSaga {
    readonly type: typeof LOAD_PACKED_ORDERS_SAGA;
    page: number,
    size: number
}

export const loadPackedOrdersPropsSaga = (page = 0, size = 10, sort = '', status = ''): ILoadPackedOrdersActionSaga => {
    return {
        type: LOAD_PACKED_ORDERS_SAGA,
        page: page,
        size: size
    }
};
export interface IUpdateOrderActionSaga {
    readonly type: typeof UPDATE_ORDER_SAGA;
    order: IOrder,
}

export const updateOrderPropsSaga = (order: IOrder): IUpdateOrderActionSaga => {
    return {
        type: UPDATE_ORDER_SAGA,
        order: order,
    }
};
export interface IFinishOrdersActionSaga {
    readonly type: typeof FINISH_ORDERS_SAGA;
}

export const finishOrdersPropsSaga = (): IFinishOrdersActionSaga => {
    return {
        type: FINISH_ORDERS_SAGA,
    }
};
