import React from "react";
import './index.scss'
import { MDBRow, MDBCol, MDBCardText } from 'mdbreact';
import ServiceAccount from "../../containers/service_account";
import ServiceAccountBranchesList from "../../containers/service_account/branchesList";
import ServiceAccountUserGroupsList from "../../containers/service_account/userGroupsList";
import serviceAccountIcon from "../../assets/img/pages-icons/service-account-icon.png";
import { Switch, Route, useRouteMatch } from "react-router-dom";

interface IServiceAccountPageProps {
}

export default function ServiceAccountPage(props: IServiceAccountPageProps) {
    let { path } = useRouteMatch();
    return (
        <MDBRow className="m-0">
            <MDBRow className="ml-2 mt-0 mb-0 w-100">
                <MDBCol size={`12`} md={`6`}>
                    <img className="page-image d-inline-block" src={serviceAccountIcon} />
                    <MDBCardText className="page-title d-inline-block">ServiceAccount</MDBCardText>
                    <span className="page-line d-inline-block" />
                </MDBCol>
            </MDBRow>
            <Switch>
                <Route path={`${path}/:serviceAccountId/user-groups`}>
                    <ServiceAccountUserGroupsList />
                </Route>
                <Route path={`${path}/:serviceAccountId/branches`}>
                    <ServiceAccountBranchesList />
                </Route>
                <Route path="/">
                    <ServiceAccount />
                </Route>
            </Switch>
        </MDBRow>
    )
}