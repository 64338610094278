import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { MDBRow, MDBAlert, MDBContainer } from 'mdbreact';
import UsersList from "../../containers/usersList/index";

interface IUsersListPageProps {
}

export default function UsersListPage(props: IUsersListPageProps) {
    const userReducer = useSelector((state: AppState) => state.userReducer)
    return (
        <>
            <MDBRow className="m-0">
                <UsersList />
            </MDBRow>
        </>
    )
}