import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import { loadAdminsPropsSaga } from "../../sagas/user/types";
import { AppState } from "../../store";
import Loader from "../../components/loader";
import { MDBCol, MDBRow, MDBModal, MDBModalBody, MDBBtn } from 'mdbreact';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import IUser, { ACCESSCARDSTATUS } from "../../dtos/IUser"
import { addQueryString, partialText } from '../../helpers';
import queryString from 'query-string';
import MainBlueButton from "../../components/blueButton";
import Select from "../../components/select"
import MultiSelect from 'react-select';
import constants from "../../constants";
import InvitationForm, { invitationFormik } from "../invitationForm"
import TableMenu from '../../components/tableMenu';
import { deactivateUserCard, deleteUser, fetchUser, requestUserCard, updateUser } from '../../api/users';
import { updateGlobalModalPropsSaga } from '../../sagas/global/types';
import { toast } from 'react-toastify';
import { loadUserGroupPropsSaga } from '../../sagas/user-group/types';
import { fetchUserGroups } from '../../api/user-group';

interface IUsersListProps {
}

function UsersList(props: IUsersListProps) {
    const dispatch = useDispatch();
    const userReducer = useSelector((state: AppState) => state.userReducer)
    const routerReducer = useSelector((state: AppState) => state.router)
    const { sort, page } = queryString.parse(routerReducer.location.search);
    const [view, selectView] = useState(10)
    const [invitationModalOpen, toggleInvitationModal] = useState(false)
    const [userDetailsOpen, toggleUserDetails] = useState(false);
    const [userDetail, updateUserDetails] = useState<IUser>();
    const [accessCardRequested, updateAccessCardRequested] = useState<boolean>(false)
    useEffect(() => {
        const pageNum: number = page ? parseInt(page.toString()) - 1 : 0;
        const [columnName, columnSorted] = sort ? sort.toString().split(',') : [];
        const sorting: string = sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc') : '';
        if (userReducer.invitationSent) {
            userReducer.invitationSent = false;
            toggleInvitationModal(false)
        }
        updateHeaderCloumns(updateHeaderSorting(columnName, columnSorted));
        changePage(page ? parseInt(page.toString()) : 1);
        dispatch(loadAdminsPropsSaga(pageNum, view, sorting));

    }, [routerReducer.location.search, view, userReducer.invitationSent]);
    const updateHeaderSorting = (columnName?: string, columnSorted?: string): IHeaderColumn[] => {
        return headerColumns.map((headerColumn, i) => {
            if (columnName && headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                switch (columnSorted) {
                    case 'ascending':
                        return {
                            ...headerColumn,
                            sorted: 'ascending'
                        }
                    case 'descending':
                        return {
                            ...headerColumn,
                            sorted: 'descending'
                        }
                    default:
                        return {
                            ...headerColumn,
                            sorted: undefined
                        }
                }
            } else {
                return {
                    ...headerColumn,
                    sorted: undefined
                }
            }
        });
    }
    const handleSort = (columnName: string, columnSorted?: 'ascending' | 'descending') => {
        let sorting = ``;
        headerColumns.map((headerColumn, i) => {
            if (headerColumn.name.toLowerCase() == 'user_id') {
                if (headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                    sorting = `${columnName.toLowerCase()},`;
                    switch (columnSorted) {
                        case undefined:
                            sorting += 'ascending';
                            break;
                        case 'ascending':
                            sorting += 'descending';
                            break;
                        default:
                            sorting = '';
                    }
                }
            }
        });
        const newQueryString = addQueryString(routerReducer.location.search, 'sort', sorting);
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }
    const headers: any = [
        { name: "Id", width: 1 },
        { name: "Name", width: 3 },
        { name: "Email", width: 2 },
        { name: "Number", width: 2 },
        { name: "Status", width: 1 },
        { name: "", width: 1 },
    ].map((header) => {
        return { ...header };
    });
    const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);
    const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
    const handlePaginationChange = (e: any, { activePage }: any) => {
        dispatch(push({
            search: addQueryString(routerReducer.location.search, 'page', activePage)
        }));
    }

    const menuItems = [
        {
            title: "View User",
            icon: "eye",
            handleChange: async (rowValue: IUser) => {
                const user = await fetchUser(rowValue.user_id as number);
                const userGroups:any = await fetchUserGroups(0,100);
                console.log("UGs=>",userGroups);
                updateUserDetails(user);
                // updateUserBranches(user.branches);
                // updateSelectedUserBranches(user.branches.map((b: any) => { return { label: b.name, value: b } }))
                
                // updateUserUserGroups(user.user_groups);
                updateUserUserGroups(userGroups.userGroups);
                updateSelectedUserUserGroups(user.user_groups.map((b: any) => { return { label: b.name, value: b.id } }))
                toggleUserDetails(true);
            }
        },
        {
            title: "Delete User",
            icon: "ban",
            handleChange: async (rowValue: IUser) => {
                dispatch(updateGlobalModalPropsSaga({
                    isOpen: true,
                    title: 'Delete User',
                    body: <h3>Are you sure you want to delete this user?</h3>,
                    confirm: async () => {
                        await deleteUser(rowValue.user_id);
                        dispatch(updateGlobalModalPropsSaga({ isOpen: false }));
                        window.location.reload();
                    },
                    close: () => {
                        dispatch(updateGlobalModalPropsSaga({ isOpen: false }));
                    },
                }));
            }
        },
    ];
    const buildRows = (admins: IUser[]): IRow[] => {
        return admins.map((admin: IUser, i) => {
            return {
                value: admin,
                props: ['user_id',
                    {
                        edit: (rowValue: IUser) => {
                            return (<MDBRow className="pl-3 cursor offblue-text" onClick={async () => {
                                const user = await fetchUser(rowValue.user_id as number);
                                const userGroups:any = await fetchUserGroups(0,100);
                                console.log("UGs ",userGroups.userGroups);
                                updateUserDetails(user);
                                // updateUserBranches(user.branches);
                                // updateSelectedUserBranches(user?.branches?.map((b: any) => { return { label: b.name, value: b } }))
                                updateUserUserGroups(userGroups.userGroups);
                                updateSelectedUserUserGroups(user.user_groups.map((b: any) => { return { label: b.name, value: b.id } }))
                                toggleUserDetails(true);
                            }}>
                                {rowValue.first_name ? `${rowValue.first_name} ${rowValue.last_name}` : ''}
                            </MDBRow >);
                        }
                    }
                    , 'email', 'mobile', 'status',
                    {
                        edit: (rowValue: IUser) => {
                            return (<TableMenu menuItems={menuItems} rowValue={rowValue} />);
                        }
                    }
                ]
            }
        });
    };
    const rows = useMemo(() => buildRows(userReducer.admins), [userReducer.admins]);

    // request user card
    const handleCardRequest = async (userId: IUser['user_id']) => {
        const results = await requestUserCard(userId);
        if (!results) {
            toast.error("Card requested failed.");
            return;
        }
        toast.success("Card requested successfully.");
        updateAccessCardRequested(true);
    }
    // user details deactivate card
    const handleCardDeactivation = (userId: IUser['user_id'], cardId: number) => {
        dispatch(updateGlobalModalPropsSaga({
            isOpen: true,
            title: 'Card Deactivation',
            body: <h3>Are you sure you want to preform this operation?</h3>,
            savebtnText: 'Yes',
            closebtnText: 'No',
            confirm: async () => {
                await deactivateUserCard(userId, cardId);
                dispatch(updateGlobalModalPropsSaga({ isOpen: false }));
                toggleUserDetails(false);
            },
            close: () => {
                dispatch(updateGlobalModalPropsSaga({ isOpen: false }));
            },
        }));
    }
    //user branches
    // const [userBranches, updateUserBranches] = useState<any[]>([]);
    // const [selectedUserBranches, updateSelectedUserBranches] = useState<any[]>([]);
    //user user-groups
    const [userUserGroups, updateUserUserGroups] = useState<any[]>([]);
    const [selectedUserUserGroups, updateSelectedUserUserGroups] = useState<any[]>([]);

    const renderInvitationModal = () => {
        return <MDBModal isOpen={invitationModalOpen} toggle={() => { toggleInvitationModal(!invitationModalOpen) }}
            fullHeight={true} position="right"
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll={true}
            className="full-height-modal full-height-modal-right">
            <div className="custom-modal-header">
                <MDBRow className="m-0 pt-5 pb-5">
                    <MDBCol size="12" md="7" className="text-left offblue-text">
                        <h2 className="font-weight-bold">Send new Invitation</h2>
                    </MDBCol>
                    <MDBCol size="12" md="5" className="mt-4 mt-md-0 actions-container">
                        <MDBRow className="m-0 p-0">
                            <MainBlueButton title="cancel" className="d-contents"
                                btnClassName="btn-modal-header-cancel"
                                onClick={() => toggleInvitationModal(false)} />
                            <MainBlueButton form="invitation-form" type="submit" title="send" className="d-contents"
                                btnClassName="btn-modal-header-save"
                                onClick={() => invitationFormik.submitForm()}
                            />
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div>
            <MDBModalBody>
                <InvitationForm toggleInvitationModal={toggleInvitationModal} />
            </MDBModalBody>
        </MDBModal>
    }
    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                <Loader isLoading={userReducer.loadingAdmins.isLoadingAdmins} errorMessage={userReducer.loadingAdmins.errorMessage}>
                    <MDBRow start className="m-2 mb-4">
                        <MDBCol md="2" size="12" className="p-0 d-flex justify-content-md-start justify-content-center">
                            <MainBlueButton
                                btnClassName="pl-3 pr-3 mr-2"
                                title="+ Send Invitation"
                                onClick={() => {
                                    toggleInvitationModal(true)
                                }}
                            />
                        </MDBCol>
                        <MDBCol md="6" size="12" className="p-0 mt-3 mt-md-0 d-flex justify-content-md-start justify-content-center">
                            <Select name="view"
                                className="mr-2 d-inline"
                                id="view-select"
                                label="View By"
                                options={constants.viewOptions.map((option, i) => {
                                    return { text: option.value, value: option.id }
                                })}
                                selectedValue={view}
                                onChange={(e: any) => {
                                    selectView(e.target.value)
                                    dispatch(push({
                                        search: ''
                                    }));
                                }}
                            />
                        </MDBCol>
                        <MDBCol>

                        </MDBCol>
                    </MDBRow>
                    <MDBRow start className="justify-content-center mt-2">
                        <MDBCol className="text-center p-0">
                            {
                                userReducer.admins.length > 0 ?
                                    <DataTable
                                        headerColumns={headerColumns}
                                        rows={rows}
                                        headerOnClick={handleSort}
                                        totalPages={userReducer.totalAdminsCount / view}
                                        activePage={activePage}
                                        onPageChange={handlePaginationChange}
                                    />
                                    : <div>You don't have any users.</div>
                            }
                        </MDBCol>
                    </MDBRow>
                    {
                        userDetail && <MDBModal isOpen={userDetailsOpen} toggle={() => { toggleUserDetails(!userDetailsOpen) }}
                            fullHeight={true} position="right"
                            inline={false}
                            noClickableBodyWithoutBackdrop={false}
                            overflowScroll={true}
                            className="full-height-modal full-height-modal-right">
                            <div className="custom-modal-header">
                                <MDBRow className="m-0 pt-5 pb-5">
                                    <MDBCol size="12" className="text-left offblue-text">
                                        <h2 className="ml-2 font-weight-bold">{`${userDetail.first_name} ${userDetail.last_name}`}</h2>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                            <MDBModalBody>
                                <MDBRow className="m-0 p-0">
                                    <MDBCol className="m-0 p-0">
                                        <MDBRow className="m-0 p-0 justify-content-around">
                                            <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                <label className="text-capitalize">first name</label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    placeholder="name"
                                                    onChange={(e) => { updateUserDetails({ ...userDetail, first_name: e.target.value }) }}
                                                    value={userDetail.first_name}
                                                    className="form-control styled-input"
                                                />
                                            </MDBCol>
                                            <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                <label className="text-capitalize">last name</label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    placeholder="name"
                                                    onChange={(e) => { updateUserDetails({ ...userDetail, last_name: e.target.value }) }}
                                                    value={userDetail.last_name}
                                                    className="form-control styled-input"
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="m-0 mt-4 p-0">
                                    <MDBCol className="m-0 p-0">
                                        <MDBRow className="m-0 p-0 justify-content-around">
                                            <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                <label className="text-capitalize">email</label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    placeholder="name"
                                                    onChange={(e) => { updateUserDetails({ ...userDetail, first_name: e.target.value }) }}
                                                    value={userDetail.email}
                                                    className="form-control styled-input"
                                                />
                                            </MDBCol>
                                            <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                <label className="text-capitalize">status</label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    placeholder="name"
                                                    onChange={(e) => { updateUserDetails({ ...userDetail, last_name: e.target.value }) }}
                                                    value={userDetail.status}
                                                    className="form-control styled-input"
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-5 justify-content-center">
                                    <MDBCol size="12" className="text-left transaction-table-title">
                                        <h4>User Card</h4>
                                    </MDBCol>
                                    {
                                        userDetail.access_card ?
                                            <MDBCol size="12" className="text-center transaction-table">
                                                {
                                                    (userDetail.access_card.status.name.toLowerCase() == ACCESSCARDSTATUS.Active.toString().toLowerCase()) &&
                                                    <MDBRow className="m-0 mt-4 p-0">
                                                        <MDBCol className="m-0 p-0">
                                                            <MDBRow className="m-0 p-0 justify-content-around">
                                                                <MDBCol className="p-0 text-left" size="12" md="5">
                                                                    <MDBBtn color="danger"
                                                                        onClick={
                                                                            () => handleCardDeactivation(userDetail.user_id, userDetail.access_card.id)
                                                                        }>Deactivate</MDBBtn>
                                                                </MDBCol>
                                                            </MDBRow>
                                                        </MDBCol>
                                                    </MDBRow>
                                                }
                                                <MDBRow className="m-0 mt-4 p-0">
                                                    <MDBCol className="m-0 p-0">
                                                        <MDBRow className="m-0 p-0 justify-content-around">
                                                            <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                                <label className="text-capitalize">tag number</label>
                                                                <input
                                                                    disabled
                                                                    type="text"
                                                                    placeholder="tag number"
                                                                    value={userDetail.access_card.tag_number}
                                                                    className="form-control styled-input"
                                                                />
                                                            </MDBCol>
                                                            <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                                <label className="text-capitalize">status</label>
                                                                <input
                                                                    disabled
                                                                    type="text"
                                                                    placeholder="status"
                                                                    value={userDetail.access_card.status.name}
                                                                    className="form-control styled-input"
                                                                />
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="m-0 mt-4 p-0">
                                                    <MDBCol className="m-0 p-0">
                                                        <MDBRow className="m-0 p-0 justify-content-around">
                                                            <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                                <label className="text-capitalize">last changed by</label>
                                                                <input
                                                                    disabled
                                                                    type="text"
                                                                    placeholder="last changed by"
                                                                    value={userDetail.access_card.last_changed_by.first_name}
                                                                    className="form-control styled-input"
                                                                />
                                                            </MDBCol>
                                                            <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                                <label className="text-capitalize">change reason</label>
                                                                <input
                                                                    disabled
                                                                    type="text"
                                                                    placeholder="change reason"
                                                                    value={userDetail.access_card.change_reason.name}
                                                                    className="form-control styled-input"
                                                                />
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="m-0 mt-4 p-0">
                                                    <MDBCol className="m-0 p-0">
                                                        <MDBRow className="m-0 p-0 justify-content-around">
                                                            <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                                <label className="text-capitalize">last changed at</label>
                                                                <input
                                                                    disabled
                                                                    type="text"
                                                                    placeholder="last changed at"
                                                                    value={userDetail.access_card.last_changed_at}
                                                                    className="form-control styled-input"
                                                                />
                                                            </MDBCol>
                                                            <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                                <label className="text-capitalize">requested date</label>
                                                                <input
                                                                    disabled
                                                                    type="text"
                                                                    placeholder="requested date"
                                                                    value={userDetail.access_card.requested_date}
                                                                    className="form-control styled-input"
                                                                />
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="m-0 mt-4 p-0">
                                                    <MDBCol className="m-0 p-0">
                                                        <MDBRow className="m-0 p-0 justify-content-around">
                                                            <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                                                <label className="text-capitalize">activation date</label>
                                                                <input
                                                                    disabled
                                                                    type="text"
                                                                    placeholder="activation date"
                                                                    value={userDetail.access_card.activation_date}
                                                                    className="form-control styled-input"
                                                                />
                                                            </MDBCol>
                                                            <MDBCol className="p-0 text-left" size="5">

                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCol> :
                                            <MDBCol size="12" className="text-center transaction-table">
                                                <MDBRow className="m-0 mt-4 p-0">
                                                    <MDBCol className="m-0 p-0">
                                                        <MDBRow className="m-0 p-0 justify-content-around">
                                                            <MDBCol className="p-0 text-left" size="12" md="5">
                                                                <MDBBtn color={accessCardRequested ? 'grey' : 'success'}
                                                                    onClick={
                                                                        () => accessCardRequested ? undefined : handleCardRequest(userDetail.user_id)
                                                                    }>Request card</MDBBtn>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCol>
                                    }
                                </MDBRow>
                                <MDBRow className="mt-5 justify-content-center">
                                    <MDBCol size="12" className="text-left transaction-table-title">
                                        <h4>User User-Groups</h4>
                                    </MDBCol>
                                    <MDBCol size="11" className="text-center transaction-table pt-3">
                                        {console.log("Hello=>",userUserGroups)}
                                        <MultiSelect
                                            isMulti={true}
                                            defaultValue={selectedUserUserGroups}
                                            options={userUserGroups.map((b: any) => { return { label: b.name, value: b.id } })}
                                            onChange={(selectedOptions) => {
                                                updateSelectedUserUserGroups(selectedOptions as any)
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-5 justify-content-center">
                                    <MDBCol size="12" className="text-left transaction-table-title">
                                        <MainBlueButton
                                            title="Save changes"
                                            className=""
                                            onClick={async () => {
                                                console.log(selectedUserUserGroups);
                                                await updateUser(
                                                    userDetail.user_id,
                                                    selectedUserUserGroups.map((ug) => ug.value),
                                                    // selectedUserBranches.map((ub) => ub.value.id)
                                                );
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBModalBody>
                        </MDBModal>
                    }
                </Loader>
            </MDBCol>
            {renderInvitationModal()}
        </MDBRow>

    )
}

export default UsersList;