import {
  IUpdateIsLoadingInvitationsAction,
  IUpdateIsLoadingAdminsAction,
  UserActions,
} from "./types";
import {
  INVITE_NEW_USER,
  VALIDATE_INVITATION,
  LOAD_ADMINS,
  UPDATE_IS_LOADING_ADMINS,
  LOAD_INVITATIONS,
  UPDATE_IS_LOADING_INVITATIONS,
} from "../../sagas/user/types";
import IUser from "../../dtos/IUser";
interface IUserState {
  invitationSent: boolean;
  valid: boolean;
  invitations: any[];
  totalInvitationsCount: number;
  loadingInvitations: IUpdateIsLoadingInvitationsAction["payload"];
  admins: IUser[];
  totalAdminsCount: number;
  loadingAdmins: IUpdateIsLoadingAdminsAction["payload"];
}
const UserState: IUserState = {
  invitationSent: false,
  valid: false,
  invitations: [],
  totalInvitationsCount: 0,
  loadingInvitations: {
    isLoadingInvitations: false,
    errorMessage: undefined,
  },
  admins: [],
  totalAdminsCount: 0,
  loadingAdmins: {
    isLoadingAdmins: false,
    errorMessage: undefined,
  }
};
const userReducer = (state = UserState, action: UserActions): IUserState => {
  switch (action.type) {
    case INVITE_NEW_USER:
      return {
        ...state,
        invitationSent: action.payload.invitationSent,
      };
    case VALIDATE_INVITATION:
      return {
        ...state,
        valid: action.payload.valid,
      };
    case LOAD_INVITATIONS:
      return {
        ...state,
        invitations: action.payload.invitations,
        totalInvitationsCount: action.payload.totalInvitationsCount,
      };
    case UPDATE_IS_LOADING_INVITATIONS:
      return {
        ...state,
        loadingInvitations: action.payload,
      };

      case LOAD_ADMINS:
        return {
          ...state,
          admins: action.payload.admins,
          totalAdminsCount: action.payload.totalAdminsCount,
        };
      case UPDATE_IS_LOADING_ADMINS:
        return {
          ...state,
          loadingAdmins: action.payload,
        };
    default:
      return state;
  }
};
export default userReducer;
