import { put, takeLatest, call } from 'redux-saga/effects'
import {
    LOAD_CARD_REQUESTS_SAGA, ILoadCardRequestsActionSaga,
    DELETE_CARD_REQUEST_SAGA, IDeleteCardRequestActionSaga,
    UPDATE_CARD_REQUEST_SAGA, IUpdateCardRequestActionSaga
} from "./types";
import {
    loadCardRequestsProps, updateIsLoadingCardRequestsProps,
    deleteCardRequestProps, updateCardRequestProps,
} from "../../reducers/card_requests/types";
import { fetchDataTableCardRequests, removeCardRequest, putCardRequest } from '../../api/cardRequests'
import { displayErrorMessageProps } from "../../reducers/global/types";

function* loadCardRequests(action: ILoadCardRequestsActionSaga) {
    try {
        yield put(updateIsLoadingCardRequestsProps({ isLoadingCardRequests: true, errorMessage: undefined }));
        const CardRequestsReasults: { cardRequests: any[], totalCountPages: number } = yield call(fetchDataTableCardRequests, action.filters.page);
        yield put(loadCardRequestsProps({
            cardRequests: CardRequestsReasults.cardRequests,
            totalCountPages: CardRequestsReasults.totalCountPages,
        }));
        yield put(updateIsLoadingCardRequestsProps({ isLoadingCardRequests: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingCardRequestsProps({ isLoadingCardRequests: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}

export function* watchLoadCardRequests() {
    yield takeLatest(LOAD_CARD_REQUESTS_SAGA, loadCardRequests)
}

function* deleteCardRequest(action: IDeleteCardRequestActionSaga) {
    const deletedCardRequestResults: boolean = yield call(removeCardRequest, action.cardRequestId);
    yield put(deleteCardRequestProps({ cardRequestId: action.cardRequestId }));
}

export function* watchDeleteCardRequest() {
    yield takeLatest(DELETE_CARD_REQUEST_SAGA, deleteCardRequest);
}

function* updateCardRequest(action: IUpdateCardRequestActionSaga) {
    const updatedCardRequestResults: any = yield call(putCardRequest, action.cardRequestId, action.data);
    if (updatedCardRequestResults !== false)
        yield put(updateCardRequestProps({ cardRequestId: action.cardRequestId, data: action.data }))
}

export function* watchUpdateCardRequest() {
    yield takeLatest(UPDATE_CARD_REQUEST_SAGA, updateCardRequest);
}

export default [
    watchLoadCardRequests(),
    watchDeleteCardRequest(),
    watchUpdateCardRequest(),
]