import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React,{useState,useEffect} from 'react';
import { IPermission } from '../../dtos/IUserGroup';
import { IUserGroupModule } from './form';
import { permissionNames } from './namings';

interface IPermissionGroupProps{
    module:IUserGroupModule,
    permissions:IPermission[],
    setPermissions:any,
}
export const PermissionGroup = (props:IPermissionGroupProps)=>{
    const [expanded, setExpanded] = useState(true);
    const mapPermissions = ()=>{
        props.module.permissions.map((permission, i) => (
            <div key={i} className="custom-control custom-control-inline custom-checkbox">
                <input type="checkbox" className="custom-control-input"
                    id={`${permission.id}`}
                    checked={_.find(props.permissions, (p, key) => p.id === permission.id) ? true : false}
                    onChange={(e) => {
                        console.log(props.permissions)
                        if (e.target.checked) {
                            props.setPermissions([...props.permissions, { ...permission }])
                        } else {
                            let updatedPermissions = [...props.permissions];
                            _.remove(updatedPermissions, (o) => o.id === permission.id)
                            props.setPermissions(updatedPermissions)
                        }
                    }}
                />
                <label className="custom-control-label text-capitalize" htmlFor={`${permission.id}`}>{permissionNames[permission.name as ReturnType<typeof permissionNames>]}</label>
            </div>
        ))
    }
    return(<>
    {/* <div className="expand-btn">
        {!expanded?<span className="muted">Show More </span>:<span className="muted">Show Less </span>}
        {expanded?<i className="fas fa-caret-right" onClick={()=>{setExpanded(!expanded)}}></i>:<i className="fas fa-caret-down" onClick={()=>{setExpanded(!expanded)}}></i>}

    </div> */}
    {expanded?(<>{
        props.module.permissions.map((permission, i) => (
            <div key={i} className="custom-control custom-control-inline custom-checkbox">
                <input type="checkbox" className="custom-control-input"
                    id={`${permission.id}`}
                    checked={_.find(props.permissions, (p, key) => p.id === permission.id) ? true : false}
                    onChange={(e) => {
                        console.log(props.permissions)
                        if (e.target.checked) {
                            props.setPermissions([...props.permissions, { ...permission }])
                        } else {
                            let updatedPermissions = [...props.permissions];
                            _.remove(updatedPermissions, (o) => o.id === permission.id)
                            props.setPermissions(updatedPermissions)
                        }
                    }}
                />
                <label className="custom-control-label text-capitalize" htmlFor={`${permission.id}`}>{permissionNames[permission.name as ReturnType<typeof permissionNames>]||permission.name}</label>
            </div>
        ))
    }</>):""}
    </>)
}