export const LOAD_FULFILLMENT_REQUESTS_SAGA = "LOAD_FULFILLMENT_REQUESTS_SAGA";
export const LOAD_FULFILLMENT_REQUESTS = "LOAD_FULFILLMENT_REQUESTS";
export const UPDATE_IS_LOADING_FULFILLMENT_REQUESTS = "UPDATE_IS_LOADING_FULFILLMENT_REQUESTS";
export interface ILoadFulfillmentRequestsActionSaga {
  readonly type: typeof LOAD_FULFILLMENT_REQUESTS_SAGA;
  filters: {
    page: number;
    size: number;
    sort: string;
    name: string;
  };
}

export const loadFulfillmentRequestsPropsSaga = (filters: ILoadFulfillmentRequestsActionSaga['filters']): ILoadFulfillmentRequestsActionSaga => {
  return {
    type: LOAD_FULFILLMENT_REQUESTS_SAGA,
    filters: {
      page: filters.page,
      size: filters.size,
      sort: filters.sort,
      name: filters.name,
    },
  };
};

export const DELETE_FULFILLMENT_REQUEST_SAGA = "DELETE_FULFILLMENT_REQUEST_SAGA";
export const DELETE_FULFILLMENT_REQUEST = "DELETE_FULFILLMENT_REQUEST";
export interface IDeleteFulfillmentRequestActionSaga {
  readonly type: typeof DELETE_FULFILLMENT_REQUEST_SAGA;
  fulfillmentRequestId: string
}

export const deleteFulfillmentRequestPropsSaga = (fulfillmentRequestId: IDeleteFulfillmentRequestActionSaga['fulfillmentRequestId']): IDeleteFulfillmentRequestActionSaga => {
  return {
    type: DELETE_FULFILLMENT_REQUEST_SAGA,
    fulfillmentRequestId: fulfillmentRequestId
  };
};

export const UPDATE_FULFILLMENT_REQUEST_SAGA = "UPDATE_FULFILLMENT_REQUEST_SAGA";
export const UPDATE_FULFILLMENT_REQUEST = "UPDATE_FULFILLMENT_REQUEST";
export interface IUpdateFulfillmentRequestActionSaga {
  readonly type: typeof UPDATE_FULFILLMENT_REQUEST_SAGA;
  fulfillmentRequestId: string;
  data: {
    entries: any[],
    notes: string
  }
}

export const updateFulfillmentRequestPropsSaga = (fulfillmentRequestId: string, data: IUpdateFulfillmentRequestActionSaga['data']): IUpdateFulfillmentRequestActionSaga => {
  return {
    type: UPDATE_FULFILLMENT_REQUEST_SAGA,
    fulfillmentRequestId: fulfillmentRequestId,
    data: data
  };
};