import React from "react";
import FulfillmentPicking from "../../containers/fulfillmentsCenter/fulfillmentPicking";

interface IFulfillmentPickingPageProps {
}

export default function FulfillmentPickingPage(props: IFulfillmentPickingPageProps) {
    return (
        <FulfillmentPicking />
    )
}