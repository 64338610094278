import React from "react";
import FulfillmentPacked from "../../containers/fulfillmentsCenter/fulfillmentPacked";

interface IFulfillmentPackedPageProps {
}

export default function FulfillmentPackedPage(props: IFulfillmentPackedPageProps) {
    return (
        <FulfillmentPacked />
    )
}