import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import { loadInvitationsPropsSaga } from "../../sagas/user/types";
import { AppState } from "../../store";
import Loader from "../../components/loader";
import { MDBCol, MDBRow, MDBModal, MDBModalBody, MDBBtn } from 'mdbreact';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import { addQueryString, partialText } from '../../helpers';
import queryString from 'query-string';
import MainBlueButton from "../../components/blueButton";
import Select from "../../components/select"
import constants from "../../constants";
import InvitationForm, { invitationFormik } from "../invitationForm"
import TableMenu from '../../components/tableMenu';
import { toast } from 'react-toastify';
import { resendInvitation } from '../../api/users';

interface IInvitationsListProps {
}

function InvitationsList(props: IInvitationsListProps) {
    const dispatch = useDispatch();
    const userReducer = useSelector((state: AppState) => state.userReducer)
    const routerReducer = useSelector((state: AppState) => state.router)
    const { sort, page } = queryString.parse(routerReducer.location.search);
    const [view, selectView] = useState(10)
    const [invitationModalOpen, toggleInvitationModal] = useState(false)

    useEffect(() => {
        const pageNum: number = page ? parseInt(page.toString()) - 1 : 0;
        const [columnName, columnSorted] = sort ? sort.toString().split(',') : [];
        const sorting: string = sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc') : '';
        userReducer.invitationSent = false;
        updateHeaderCloumns(updateHeaderSorting(columnName, columnSorted));
        changePage(page ? parseInt(page.toString()) : 1);
        dispatch(loadInvitationsPropsSaga(pageNum, view, sorting));
        

    }, [routerReducer.location.search, view, userReducer.invitationSent]);
    const updateHeaderSorting = (columnName?: string, columnSorted?: string): IHeaderColumn[] => {
        return headerColumns.map((headerColumn, i) => {
            if (columnName && headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                switch (columnSorted) {
                    case 'ascending':
                        return {
                            ...headerColumn,
                            sorted: 'ascending'
                        }
                    case 'descending':
                        return {
                            ...headerColumn,
                            sorted: 'descending'
                        }
                    default:
                        return {
                            ...headerColumn,
                            sorted: undefined
                        }
                }
            } else {
                return {
                    ...headerColumn,
                    sorted: undefined
                }
            }
        });
    }
    const handleSort = (columnName: string, columnSorted?: 'ascending' | 'descending') => {
        let sorting = ``;
        headerColumns.map((headerColumn, i) => {
            if (headerColumn.name.toLowerCase() == 'user_id') {
                if (headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                    sorting = `${columnName.toLowerCase()},`;
                    switch (columnSorted) {
                        case undefined:
                            sorting += 'ascending';
                            break;
                        case 'ascending':
                            sorting += 'descending';
                            break;
                        default:
                            sorting = '';
                    }
                }
            }
        });
        const newQueryString = addQueryString(routerReducer.location.search, 'sort', sorting);
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }
    const headers: any = [
        { name: "Id", width: 1 },
        { name: "Name", width: 3 },
        { name: "Email", width: 2 },
        { name: "Status", width: 1 },
        { name: "", width: 1 },
    ].map((header) => {
        return { ...header };
    });
    const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);
    const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
    const handlePaginationChange = (e: any, { activePage }: any) => {
        dispatch(push({
            search: addQueryString(routerReducer.location.search, 'page', activePage)
        }));
    }

    const menuItems = [
        {
            title: "Re-send invitation",
            icon: "redo",
            handleChange: async (rowValue: any) => {
                await resendInvitation(rowValue.user_id);
                toast.success('invitation sent successfully.');
            }
        },
    ];
    const buildRows = (invitations: any[]): IRow[] => {
        return invitations.reverse().map((invitation: any, i) => {
            return {
                value: invitation,
                props: [
                    'user_id',
                    {
                        edit: (rowValue: any) => {
                            return (<MDBRow className="pl-3 cursor">
                                {rowValue.first_name ? `${rowValue.first_name} ${rowValue.last_name}` : ''}
                            </MDBRow >);
                        }
                    }
                    , 'email', 'status',
                    {
                        edit: (rowValue: any) => {
                            return (<TableMenu menuItems={menuItems} rowValue={rowValue} />);
                        }
                    }
                ]
            }
        });
    };
    const rows = useMemo(() => buildRows(userReducer.invitations), [userReducer.invitations]);
    const renderInvitationModal = () => {
        return <MDBModal isOpen={invitationModalOpen} toggle={() => { toggleInvitationModal(!invitationModalOpen) }}
            fullHeight={true} position="right"
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll={true}
            className="full-height-modal full-height-modal-right">
            <div className="custom-modal-header">
                <MDBRow className="m-0 pt-5 pb-5">
                    <MDBCol size="12" md="7" className="text-left offblue-text">
                        <h2 className="font-weight-bold">Send new Invitation</h2>
                    </MDBCol>
                    <MDBCol size="12" md="5" className="mt-4 mt-md-0 actions-container">
                        <MDBRow className="m-0 p-0">
                            <MainBlueButton title="cancel" className="d-contents"
                                btnClassName="btn-modal-header-cancel"
                                onClick={() => toggleInvitationModal(false)} />
                            <MainBlueButton form="invitation-form" type="submit" title="send" className="d-contents"
                                btnClassName="btn-modal-header-save"
                                onClick={() => invitationFormik.submitForm()}
                            />
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div>
            <MDBModalBody>
                <InvitationForm toggleInvitationModal={toggleInvitationModal} />
            </MDBModalBody>
        </MDBModal>
    }
    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                <Loader isLoading={userReducer.loadingInvitations.isLoadingInvitations} errorMessage={userReducer.loadingInvitations.errorMessage}>
                    <MDBRow start className="m-2 mb-4">
                        <MDBCol md="2" size="12" className="p-0 d-flex justify-content-md-start justify-content-center">
                            <MainBlueButton
                                btnClassName="pl-3 pr-3 mr-2"
                                title="+ Send Invitation"
                                onClick={() => {
                                    toggleInvitationModal(true)
                                }}
                            />
                        </MDBCol>
                        <MDBCol md="6" size="12" className="p-0 mt-3 mt-md-0 d-flex justify-content-md-start justify-content-center">
                            <Select name="view"
                                className="mr-2 d-inline"
                                id="view-select"
                                label="View By"
                                options={constants.viewOptions.map((option, i) => {
                                    return { text: option.value, value: option.id }
                                })}
                                selectedValue={view}
                                onChange={(e: any) => {
                                    selectView(e.target.value)
                                    dispatch(push({
                                        search: ''
                                    }));
                                }}
                            />
                        </MDBCol>
                        <MDBCol>

                        </MDBCol>
                    </MDBRow>
                    <MDBRow start className="justify-content-center mt-2">
                        <MDBCol className="text-center p-0">
                            {
                                userReducer.invitations.length > 0 ?
                                    <DataTable
                                        headerColumns={headerColumns}
                                        rows={rows}
                                        headerOnClick={handleSort}
                                        totalPages={userReducer.totalInvitationsCount / view}
                                        activePage={activePage}
                                        onPageChange={handlePaginationChange}
                                    />
                                    : <div>You don't have any invitations.</div>
                            }
                        </MDBCol>
                    </MDBRow>
                </Loader>
            </MDBCol>
            {renderInvitationModal()}
        </MDBRow>

    )
}

export default InvitationsList;