import React, { useState } from 'react';
import {
    MDBRow, MDBCol, MDBIcon,
} from "mdbreact";
import { push } from 'connected-react-router';
import './styles/index.scss'
import { useDispatch, useSelector } from "react-redux";
import { AppState } from '../../../store';
import SideBarLink from './link';
import Can from '../../can';
import { logoutPropsSaga } from '../../../sagas/header/types';
import { toggleSidebarPropsSaga } from '../../../sagas/sidebar/types';
import storagesIcon from '../../../assets/img/sidemenu_icons/storages.png';
import storagesBlueIcon from '../../../assets/img/pages-icons/storages-icon.png';
import branchesIcon from '../../../assets/img/sidemenu_icons/branches.png';
import branchesBlueIcon from '../../../assets/img/pages-icons/branches-icon.png';
import categoriesIcon from '../../../assets/img/sidemenu_icons/categories.png';
import categoriesBlueIcon from '../../../assets/img/pages-icons/categories-icon.png';
import dashboardIcon from '../../../assets/img/sidemenu_icons/dashboard.png';
import dashboardBlueIcon from '../../../assets/img/pages-icons/dashboard-icon.png';
import inventoryIcon from '../../../assets/img/sidemenu_icons/inventory.png';
import inventoryBlueIcon from '../../../assets/img/pages-icons/inventory-icon.png';
import logoutIcon from '../../../assets/img/sidemenu_icons/log-out.png';
import logoutBlueIcon from '../../../assets/img/pages-icons/logout-icon.png';
import serviceAccountIcon from '../../../assets/img/sidemenu_icons/service-account.png';
import serviceAccountBlueIcon from '../../../assets/img/pages-icons/service-account-icon.png';
import settingsIcon from '../../../assets/img/sidemenu_icons/settings.png';
import settingsBlueIcon from '../../../assets/img/pages-icons/settings-icon.png';
import usersIcon from '../../../assets/img/sidemenu_icons/users.png';
import usersBlueIcon from '../../../assets/img/pages-icons/users-icon.png';
import userAccountIcon from '../../../assets/img/sidemenu_icons/user-account.png';
import userAccountBlueIcon from '../../../assets/img/pages-icons/user-account-icon.png';
import userGroupIcon from '../../../assets/img/sidemenu_icons/user-group.png';
import userGroupBlueIcon from '../../../assets/img/pages-icons/user-group-icon.png';
import vendorsIcon from '../../../assets/img/sidemenu_icons/vendor-icon.png';
import vendorsBlueIcon from '../../../assets/img/pages-icons/vendor-icon.png';
import fulfillmentRequestsIcon from "../../../assets/img/sidemenu_icons/fulfillment-requests.png";
import fulfillmentRequestsBlueIcon from "../../../assets/img/pages-icons/fulfillment-requests-icon.png";
import ordersIcon from "../../../assets/img/sidemenu_icons/orders.png";
import ordersBlueIcon from "../../../assets/img/pages-icons/orders-icon.png";
import cardRequestsIcon from "../../../assets/img/sidemenu_icons/card-requests.png";
import cardRequestsBlueIcon from "../../../assets/img/pages-icons/card-requests-icon.png";


interface ISideBarProps {

}

function SideBar(props: ISideBarProps) {
    const dispatch = useDispatch();
    const globalReducer = useSelector((state: AppState) => state.globalReducer);
    const sidebarReducer = useSelector((state: AppState) => state.sidebarReducer);
    const currentLocation = useSelector((state: AppState) => state.router.location);
    return (
        <>
            <MDBRow className="m-0 p-0 border-radius-tl border-radius-tr border-radius-bl border-radius-br white position-fixed z-index-1">
                <MDBCol md="12" className={`side-header pt-3 pb-3 pr-2 pl-2 border-radius-tl border-radius-tr text-center`}>
                    <div className="bars pt-2 pb-2 cursor" onClick={() => { dispatch(toggleSidebarPropsSaga()) }}>
                        <hr />
                        <hr />
                    </div>
                    <div className="side-header-account">
                        <MDBRow className="m-0 text-left cursor" onClick={() => dispatch(push({ pathname: `/my-account` }))}>
                            <MDBCol className="side-header-account-icon online m-0 p-0" size="3">
                                <MDBIcon icon="user" className="lightGrey-text" />
                            </MDBCol>
                            <MDBCol className="m-0 p-0">
                                <div className="text-capitalize d-inline-block">
                                    <h4 className="offblue-text">
                                        <strong>{globalReducer.authUser.idTokenParsed.name.replace('-', ' ')}</strong>
                                    </h4>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </div>
                    <div className="mobile-side-close cursor white-text" onClick={() => { dispatch(toggleSidebarPropsSaga()) }}>
                        x
                    </div>
                </MDBCol>
                <MDBCol md="12" className={`side-links p-0 border-radius-bl border-radius-br ${sidebarReducer.open ? 'opened' : 'closed text-center'}`}>
                    <SideBarLink exact name="my-account" icon="user"
                        imageIcon={
                            <div className="my-account-img">
                                <img src={currentLocation.pathname.includes('my-account') ? userAccountBlueIcon : userAccountIcon} className={`link-icon-dimensions`} />
                            </div>}
                        className="my-account-side-link online-link" currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} />

                    {/* <SideBarLink exact isBase name="dashboard" icon="columns"
                        imageIcon={
                            <img src={currentLocation.pathname == '/' ? dashboardBlueIcon : dashboardIcon} className={`link-icon-dimensions`} />
                        }
                        currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} />
                    <SideBarLink exact name="users" icon="columns"
                        imageIcon={
                            <img src={currentLocation.pathname == '/users' ? usersBlueIcon : usersIcon} className={`link-icon-dimensions`} />
                        }
                        currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} />
                        currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open} /> */}

                    <SideBarLink exact name="service-account" icon="layer-group"
                        imageIcon={
                            <img src={currentLocation.pathname.includes('service-account') ? serviceAccountBlueIcon : serviceAccountIcon} className={`link-icon-dimensions`} />
                        }
                        isSideMenuOpened={sidebarReducer.open} currentPathname={currentLocation.pathname} />
                    <SideBarLink exact name="users" icon="layer-group"
                        imageIcon={
                            <img src={currentLocation.pathname.includes('users') ? usersBlueIcon : usersIcon} className={`link-icon-dimensions`} />
                        }
                        isSideMenuOpened={sidebarReducer.open} currentPathname={currentLocation.pathname} />
                    <SideBarLink exact name="user-group" icon="layer-group"
                        imageIcon={
                            <img src={currentLocation.pathname.includes('user-group') ? userGroupBlueIcon : userGroupIcon} className={`link-icon-dimensions`} />
                        } isSideMenuOpened={sidebarReducer.open} currentPathname={currentLocation.pathname} />
                    <SideBarLink exact name="PO" icon="layer-group"
                        imageIcon={
                            <img src={currentLocation.pathname.includes('po') ? dashboardBlueIcon : dashboardIcon} className={`link-icon-dimensions`} />
                        }
                        isSideMenuOpened={sidebarReducer.open} currentPathname={currentLocation.pathname} />

                    <SideBarLink exact name="Orders" icon="layer-group"
                        imageIcon={
                            <img src={currentLocation.pathname.includes('orders') ? dashboardBlueIcon : dashboardIcon} className={`link-icon-dimensions`} />
                        }
                        onClick={()=> window.open(`${process.env.REACT_APP_STAFF_URI}`, "_blank")}
                        isSideMenuOpened={sidebarReducer.open} currentPathname={currentLocation.pathname} />
                    {/* <SideBarLink exact name="Orders" icon="layer-group"
                        imageIcon={
                            <img src={currentLocation.pathname.includes('orders') ? dashboardBlueIcon : dashboardIcon} className={`link-icon-dimensions`} />
                        }
                        currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open}
                        onClick={()=> window.open(`${process.env.REACT_APP_STAFF_URI}`, "_blank")}
                        className="last-side-link"
                    /> */}
                    <SideBarLink exact name="fulfillments-center" icon="layer-group" breadcrumpName="fulfillments"
                        imageIcon={
                            <img src={currentLocation.pathname.includes('fulfillments-center') ? vendorsBlueIcon : vendorsIcon} className={`link-icon-dimensions`} />
                        }
                        isSideMenuOpened={sidebarReducer.open} currentPathname={currentLocation.pathname} />
                    <SideBarLink exact name="cards" icon="layer-group" breadcrumpName={"cards"}
                        imageIcon={
                            <img src={currentLocation.pathname.includes('cards') ? cardRequestsBlueIcon : cardRequestsIcon} className={`link-icon-dimensions`} />
                        }
                        isSideMenuOpened={sidebarReducer.open} currentPathname={currentLocation.pathname} />

                    <SideBarLink exact name="requests" icon="layer-group" breadcrumpName="requests"
                        imageIcon={
                            <img src={currentLocation.pathname.includes('requests') ? fulfillmentRequestsBlueIcon : fulfillmentRequestsIcon} className={`link-icon-dimensions`} />
                        }
                        isSideMenuOpened={sidebarReducer.open} currentPathname={currentLocation.pathname} />

                    <SideBarLink exact name="storages" icon="layer-group"
                        imageIcon={
                            <img src={currentLocation.pathname.includes('storages') ? storagesBlueIcon : storagesIcon} className={`link-icon-dimensions`} />
                        }
                        isSideMenuOpened={sidebarReducer.open} currentPathname={currentLocation.pathname} />

                    <SideBarLink exact name="settings" icon="cog"
                        imageIcon={
                            <img src={currentLocation.pathname.includes('settings') ? settingsBlueIcon : settingsIcon} className={`link-icon-dimensions`} />
                        }
                        currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open}
                        className="settings-side-link"
                    />

                    <SideBarLink exact name="logout" icon="power-off"
                        imageIcon={
                            <img src={currentLocation.pathname.includes('logout') ? logoutBlueIcon : logoutIcon} className={`link-icon-dimensions`} />
                        }
                        currentPathname={currentLocation.pathname} isSideMenuOpened={sidebarReducer.open}
                        onClick={() => dispatch(logoutPropsSaga())}
                        className="last-side-link"
                    />
                </MDBCol>
            </MDBRow>
        </>
    )
}

export default SideBar;