import {
    LOAD_FULFILLMENT_REQUESTS,
    UPDATE_IS_LOADING_FULFILLMENT_REQUESTS, DELETE_FULFILLMENT_REQUEST,
    UPDATE_FULFILLMENT_REQUEST
} from '../../sagas/fulfillment_requests/types';

export interface ILoadFulfillmentRequestsAction {
    readonly type: typeof LOAD_FULFILLMENT_REQUESTS;
    payload: {
        fulfillmentRequests: any[],
        totalCountPages: number
    }
}

export const loadFulfillmentRequestsProps = (payload: ILoadFulfillmentRequestsAction['payload']): ILoadFulfillmentRequestsAction => {
    return {
        type: LOAD_FULFILLMENT_REQUESTS,
        payload: payload
    }
};

export interface IUpdateIsLoadingFulfillmentRequestsAction {
    readonly type: typeof UPDATE_IS_LOADING_FULFILLMENT_REQUESTS;
    payload:{
        isLoadingFulfillmentRequests: boolean
        errorMessage: string | undefined
    }
}

export const updateIsLoadingFulfillmentRequestsProps = (payload: IUpdateIsLoadingFulfillmentRequestsAction['payload']): IUpdateIsLoadingFulfillmentRequestsAction => {
    return {
        type: UPDATE_IS_LOADING_FULFILLMENT_REQUESTS,
        payload: payload
    }
};

export interface IDeleteFulfillmentRequestAction {
    readonly type: typeof DELETE_FULFILLMENT_REQUEST;
    payload: {
        fulfillmentRequestId: string,
    }
}

export const deleteFulfillmentRequestProps = (payload: IDeleteFulfillmentRequestAction['payload']): IDeleteFulfillmentRequestAction => {
    return {
        type: DELETE_FULFILLMENT_REQUEST,
        payload: payload
    }
};

export interface IUpdateFulfillmentRequestAction {
    readonly type: typeof UPDATE_FULFILLMENT_REQUEST;
    payload: {
        fulfillmentRequestId: string,
        data: {
            entries: any[],
            notes: string
        }
    }
}

export const updateFulfillmentRequestProps = (payload: IUpdateFulfillmentRequestAction['payload']): IUpdateFulfillmentRequestAction => {
    return {
        type: UPDATE_FULFILLMENT_REQUEST,
        payload: payload
    }
};

export type FulfillmentRequestsActions = ILoadFulfillmentRequestsAction | IUpdateIsLoadingFulfillmentRequestsAction |
IDeleteFulfillmentRequestAction | IUpdateFulfillmentRequestAction;