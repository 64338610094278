import { all } from "redux-saga/effects";
import watchGlobal from "./global";
import watchDashboard from "./dashboard";
import watchSidebar from './sidebar';
import watchHeader from './header';
import watchNotifications from "./notifications"
import watchUsers from "./user"
import watchServiceAccount from "./service_account"
import watchStorages from "./storage"
import watchCardRequests from "./card_requests"
import watchFulfillmentRequestsRequests from "./fulfillment_requests"
import watchUserGroup from "./user-group";
import watchFulfillmentsCenter from "./fulfillments_center"

export default function* rootSaga() {
  yield all([
    ...watchGlobal,
    ...watchDashboard,
    ...watchSidebar,
    ...watchHeader,
    ...watchNotifications,
    ...watchUsers,
    ...watchServiceAccount,
    ...watchStorages,
    ...watchCardRequests,
    ...watchFulfillmentRequestsRequests,
    ...watchUserGroup,
    ...watchFulfillmentsCenter,
  ])
}

