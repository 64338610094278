import {IUpdateIsLoadingFulfillmentRequestsAction, FulfillmentRequestsActions} from './types'
import {
    LOAD_FULFILLMENT_REQUESTS, UPDATE_IS_LOADING_FULFILLMENT_REQUESTS,
    DELETE_FULFILLMENT_REQUEST, UPDATE_FULFILLMENT_REQUEST
} from "../../sagas/fulfillment_requests/types";
import _ from 'lodash';

interface IFulfillmentRequestsState {
    filters: {
        sortCol: string,
        sortOrder: 'ascending' | 'descending'
    },
    fulfillmentRequests: any[],
    totalCountPages: number,
    loadingFulfillmentRequests: IUpdateIsLoadingFulfillmentRequestsAction['payload'],
}

const fulfillmentRequestsState: IFulfillmentRequestsState = {
    filters: {
        sortCol: '',
        sortOrder: 'ascending'
    },
    fulfillmentRequests: [],
    totalCountPages: 0,
    loadingFulfillmentRequests: {
        isLoadingFulfillmentRequests: false,
        errorMessage: undefined
    }
};

const fulfillmentRequestsReducer = (state = fulfillmentRequestsState, action: FulfillmentRequestsActions): IFulfillmentRequestsState => {
    switch (action.type) {
        case LOAD_FULFILLMENT_REQUESTS:
            return {
                ...state,
                fulfillmentRequests: action.payload.fulfillmentRequests,
                totalCountPages: action.payload.totalCountPages
            };
        case UPDATE_IS_LOADING_FULFILLMENT_REQUESTS:
            return {
                ...state,
                loadingFulfillmentRequests: action.payload,
            };
        case DELETE_FULFILLMENT_REQUEST:
            const updatedFulfillmentRequests = [...state.fulfillmentRequests];
            _.remove(updatedFulfillmentRequests, (fulfillmentRequest, i) => fulfillmentRequest.id === action.payload.fulfillmentRequestId)
            return {
                ...state,
                fulfillmentRequests: updatedFulfillmentRequests
            };
        case UPDATE_FULFILLMENT_REQUEST:
            return {
                ...state,
                fulfillmentRequests: state.fulfillmentRequests.map((fulfillmentRequest, i) => fulfillmentRequest.id?.toString() == action.payload.fulfillmentRequestId? {...fulfillmentRequest, ...action.payload.data}:fulfillmentRequest)
            };
        default:
            return state;
    }
};

export default fulfillmentRequestsReducer;
