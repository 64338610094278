import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import './styles/index.scss'
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import Loader from "../../components/loader";
import {
    MDBCol, MDBRow, MDBIcon, MDBContainer, MDBModal,
    MDBModalBody, MDBModalHeader, MDBTabPane, MDBTabContent,
    MDBNav, MDBNavItem, MDBNavLink
} from 'mdbreact';
import Select from '../../components/select';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import {
    ILoadFulfillmentRequestsActionSaga,
    loadFulfillmentRequestsPropsSaga,
    deleteFulfillmentRequestPropsSaga,
    updateFulfillmentRequestPropsSaga,
} from '../../sagas/fulfillment_requests/types';
import Constants from '../../constants';
import { addQueryString } from '../../helpers';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { addFulfillmentRequest, fetchFulfillmentRequest, fetchVendor } from '../../api/fulfillmentRequests';
import MainBlueButton from "../../components/blueButton"
import IconContainer from "../../components/iconContainer"
import constants from "../../constants"
import TableMenu from "../../components/tableMenu";
import AsyncSelect from 'react-select/async';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMemo } from 'react';
import EditableText from '../../components/editableText';
import { toast } from 'react-toastify';

interface IFulfillmentRequestsProps {
}

function FulfillmentRequest(props: IFulfillmentRequestsProps) {
    const routerReducer = useSelector((state: AppState) => state.router)
    const { searchkeyword, sort, page } = queryString.parse(routerReducer.location.search);
    const [fulfillmentRequestDetailsOpen, toggleFulfillmentRequestDetails] = useState(false);
    const [fulfillmentRequestDetail, updateFulfillmentRequestDetails] = useState<any>();
    const [fulfillmentRequestAddOpen, toggleFulfillmentRequestAdd] = useState(false);
    const [searchText, updateSearchText] = useState(searchkeyword ? searchkeyword.toString() : '');
    //new ui
    const [view, selectView] = useState(10)
    const [isOpen, toggleModal] = useState(false)

    let headers: any = [
        { name: "Id", width: 1 },
        { name: "Vendor", width: 3 },
        { name: "Branch", width: 2 },
        { name: "", width: 1 },
    ];
    const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);
    const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
    const dispatch = useDispatch();
    const fulfillmentRequestsReducer = useSelector((state: AppState) => state.fulfillmentRequestsReducer)

    // container logic
    useEffect(() => {
        const { searchkeyword, sort, page } = queryString.parse(routerReducer.location.search);
        const [columnName, columnSorted] = sort ? sort.toString().split(',') : ['name', 'ascending'];
        const filters: ILoadFulfillmentRequestsActionSaga['filters'] = {
            page: page ? parseInt(page.toString()) - 1 : 0,
            size: view,
            sort: sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc') : 'name,asc',
            name: searchkeyword ? searchkeyword.toString() : '',
        }
        dispatch(loadFulfillmentRequestsPropsSaga(filters));
        // updating components values
        updateHeaderCloumns(updateHeaderSorting(columnName, columnSorted));
        changePage(page ? parseInt(page.toString()) : 1);
        updateSearchText(searchkeyword ? searchkeyword.toString() : '');
    }, [routerReducer.location.search, view]);

    // headers and soring
    const updateHeaderSorting = (columnName?: string, columnSorted?: string): IHeaderColumn[] => {
        return headerColumns.map((headerColumn, i) => {
            if (columnName && headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                switch (columnSorted) {
                    case 'ascending':
                        return {
                            ...headerColumn,
                            sorted: 'ascending'
                        }
                    case 'descending':
                        return {
                            ...headerColumn,
                            sorted: 'descending'
                        }
                    default:
                        return {
                            ...headerColumn,
                            sorted: undefined
                        }
                }
            } else {
                return {
                    ...headerColumn,
                    sorted: undefined
                }
            }
        });
    }

    const handleSort = (columnName: string, columnSorted?: 'ascending' | 'descending') => {
        let sorting = ``;
        headerColumns.map((headerColumn, i) => {
            let colName = headerColumn.name.toLocaleLowerCase()
            if (colName != "" && colName != "name") {
                if (headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                    sorting = `${columnName.toLowerCase()},`;
                    switch (columnSorted) {
                        case undefined:
                            sorting += 'ascending';
                            break;
                        case 'ascending':
                            sorting += 'descending';
                            break;
                        default:
                            sorting = '';
                    }
                }
            }
        });
        const newQueryString = addQueryString(routerReducer.location.search, 'sort', sorting);
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }

    // body rows data
    const handleOpenDetails = async (rowValue: any) => {
        const fulfillmentRequest = await fetchFulfillmentRequest(rowValue.id as number);
        const vendor = await fetchVendor(fulfillmentRequest.vendor.id);
        editFulfillmentRequestformik.setFieldValue('vendor_name', fulfillmentRequest.vendor?.name);
        editFulfillmentRequestformik.setFieldValue('branch_name', fulfillmentRequest.branch?.name);
        editFulfillmentRequestformik.setFieldValue('status', fulfillmentRequest.status?.name);
        editFulfillmentRequestformik.setFieldValue('created_at', fulfillmentRequest.created_at);
        editFulfillmentRequestformik.setFieldValue('notes', fulfillmentRequest.notes);
        editFulfillmentRequestformik.setFieldValue('address', vendor.address);
        editFulfillmentRequestformik.setFieldValue('entries', fulfillmentRequest.entries);
        updateFulfillmentRequestDetails(fulfillmentRequest);
        toggleFulfillmentRequestDetails(true);
    }
    const menuItems = [
        {
            title: "Edit fulfillment request",
            icon: "edit",
            handleChange: async (rowValue: any) => {
                handleOpenDetails(rowValue);
            }
        },
        // {
        //     title: "Remove fulfillment request",
        //     icon: "trash",
        //     handleChange: async (rowValue: any) => {
        //         dispatch(deleteFulfillmentRequestPropsSaga(rowValue.id))
        //     }
        // }
    ]
    const buildRows = (fulfillmentRequests: any[]): IRow[] => {
        return fulfillmentRequests.map((fulfillmentRequest: any, i) => {
            let row: IRow = {
                value: fulfillmentRequest,
                props: [
                    'id',
                    {
                        edit: (rowValue: any) => {
                            return (<div className="cursor offblue-text" onClick={async () => {
                                handleOpenDetails(rowValue);
                            }}>{rowValue.vendor.name}</div>);
                        },
                    },
                    'branch.name',
                    {
                        edit: (rowValue: any) => {
                            return (<TableMenu menuItems={menuItems} rowValue={rowValue} />);
                        }
                    }
                ]
            };
            return row;
        });
    };

    const rows = buildRows(fulfillmentRequestsReducer.fulfillmentRequests);

    // pagination
    const handlePaginationChange = (e: any, { activePage }: any) => {
        dispatch(push({
            search: addQueryString(routerReducer.location.search, 'page', activePage)
        }));
    }

    //filters
    const handleFilter = (filters: { filterName: string, value: any }[]) => {
        let newQueryString = routerReducer.location.search;
        filters.forEach((filter) => {
            newQueryString = addQueryString(newQueryString, filter.filterName, filter.value);
            newQueryString += '&';
        })
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }

    //reset filters & search
    const handleReset = () => {
        dispatch(push({
            search: ''
        }));
        toggleModal(false)
    }

    const renderModal = () => {
        return <MDBContainer className="filters-modal">
            <MDBModal
                isOpen={isOpen} toggle={() => toggleModal(!isOpen)}
                inline={false} noClickableBodyWithoutBackdrop={false} overflowScroll={false}>
                <MDBModalHeader toggle={() => toggleModal(false)} >Filter</MDBModalHeader>
                <MDBModalBody className="p-3" >
                    <input className="search-input form-control input-rounded" type="text"
                        value={searchText}
                        onChange={(e) => updateSearchText(e.target.value)}
                        placeholder="Search" aria-label="Search" />

                    <MDBRow className="filter-buttons-container w-100 d-flex mt-3 justify-content-center">
                        <MainBlueButton
                            title="Show results"
                            className="mr-2"
                            onClick={() => handleShowResult()}
                        />
                        <MainBlueButton
                            btnClassName="reset-btn"
                            title="Reset"
                            onClick={() => handleReset()}
                        />
                    </MDBRow>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    }

    const handleShowResult = () => {
        const filters: { filterName: string, value: any }[] = [];
        if (searchText != '') {
            filters.push({ filterName: 'searchkeyword', value: searchText });
        }
        handleFilter(filters);
        toggleModal(false);
    }


    // edit Fulfillment request form
    const editFulfillmentRequestvalidationSchema = Yup.object().shape({
        vendor_name: Yup.string()
            .required('Required')
            .min(2, 'must be at least 2 characters'),
    });
    const editFulfillmentRequestformik = useFormik({
        initialValues: {
            vendor_name: '',
            branch_name: '',
            status: '',
            created_at: '',
            notes: '',
            address: {
                building_number: "",
                city: "",
                country: "",
                floor: "",
                state: "",
            },
            entries: [],
        },
        validationSchema: editFulfillmentRequestvalidationSchema,
        onSubmit: async (values) => {
            dispatch(updateFulfillmentRequestPropsSaga(
                fulfillmentRequestDetail?.id ? fulfillmentRequestDetail?.id.toString() : '',
                { ...fulfillmentRequestDetail, entries: values.entries, notes: values.notes}
            ))
            toggleFulfillmentRequestDetails(false);
            toast.success("Changes Saved Successfully");
        },
    });

    //edit fulfillment form entries
    const editFormEntriesMenuItems = [
        {
            title: "Remove",
            icon: "trash",
            handleChange: async (rowValue: any) => {
                const updatedEntries = [...editFulfillmentRequestformik.values.entries];
                _.remove(updatedEntries, (entry: any) => rowValue.id === entry.id)
                editFulfillmentRequestformik.setFieldValue('entries', updatedEntries);
            }
        },
    ]
    let editFormEntriesHeaders: any = [
        { name: "Id", width: 1 },
        { name: "name", width: 3 },
        { name: "price", width: 2 },
        { name: "quantity", width: 2 },
        { name: "", width: 1 },
    ];
    const buildEditFormEntriesRows = (entries: any[]): IRow[] => {
        return entries.map((entry: any, i) => {
            let row: IRow = {
                value: entry,
                props: [
                    'id',
                    'product.alias',
                    'price',
                    {
                        edit: (rowValue: any) => {
                            return (
                                <EditableText
                                    type="number"
                                    name="actual_quantity"
                                    key={rowValue.id}
                                    defaultValue={rowValue.actual_quantity}
                                    onChange={(e: any) => { }}
                                    onSave={(e: any) => editFulfillmentRequestformik.setFieldValue(`entries[${i}].actual_quantity`, e.value)}
                                />
                            );
                        }
                    },
                    {
                        edit: (rowValue: any) => {
                            return (<TableMenu menuItems={editFormEntriesMenuItems} rowValue={rowValue} />);
                        }
                    }
                ]
            };
            return row;
        });
    };

    const editFormEntriesrows = useMemo(() => buildEditFormEntriesRows(editFulfillmentRequestformik.values.entries), [editFulfillmentRequestformik.values.entries]);

    // add fulfillment request form
    const addFulfillmentRequestvalidationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required'),
    });
    const addFulfillmentRequestformik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: addFulfillmentRequestvalidationSchema,
        onSubmit: async (values) => {
            await addFulfillmentRequest({ name: values.name })
            window.location.reload();
        },
    });
    const handleFulfillmentRequestAdd = async () => {
        addFulfillmentRequestformik.submitForm();
    }
    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                <Loader isLoading={fulfillmentRequestsReducer.loadingFulfillmentRequests.isLoadingFulfillmentRequests} errorMessage={fulfillmentRequestsReducer.loadingFulfillmentRequests.errorMessage}>
                    {/*filters fulfillment request*/}
                    <MDBRow start className="m-2 mb-4">
                        {/* <MDBCol md="2" size="12" className="p-0 d-flex justify-content-md-start justify-content-center">
                            <MainBlueButton
                                btnClassName="pr-3 pl-3 mr-2"
                                title="+ add fulfillment request" className="d-contents" onClick={() => { toggleFulfillmentRequestAdd(true) }} />
                        </MDBCol> */}
                        <MDBCol md="6" size="12" className="p-0 mt-3 mt-md-0 d-flex justify-content-md-start justify-content-center">
                            <Select name="view"
                                id="view-select"
                                className="mr-2 d-inline"
                                label="View By"
                                options={constants.viewOptions.map((option, i) => {
                                    return { text: option.value, value: option.id }
                                })}
                                selectedValue={view}
                                onChange={(e: any) => {
                                    selectView(e.target.value)
                                    dispatch(push({
                                        search: ''
                                    }));
                                }}
                            />
                            <IconContainer className="mr-2 d-inline" onClick={() => toggleModal(true)} >
                                <MDBIcon icon="filter" />
                            </IconContainer>
                        </MDBCol>
                    </MDBRow>
                    {/*datatable fulfillment request*/}
                    <MDBRow start className="justify-content-center mt-2">
                        <MDBCol className="text-center p-0">
                            {
                                fulfillmentRequestsReducer.fulfillmentRequests.length > 0 ?
                                    <div className="celled-table">
                                        <DataTable
                                            headerColumns={headerColumns}
                                            headerOnClick={handleSort}
                                            rows={rows}
                                            data={fulfillmentRequestsReducer.fulfillmentRequests}
                                            totalPages={fulfillmentRequestsReducer.totalCountPages / Constants.pagination.default}
                                            activePage={activePage}
                                            onPageChange={handlePaginationChange}
                                        />
                                    </div>
                                    :
                                    <div>No fulfillment requests found.</div>
                            }
                        </MDBCol>
                    </MDBRow>
                    {/*details fulfillment request*/}
                    <MDBModal isOpen={fulfillmentRequestDetailsOpen} toggle={() => { toggleFulfillmentRequestDetails(!fulfillmentRequestDetailsOpen) }}
                        fullHeight={true} position="right"
                        inline={false}
                        noClickableBodyWithoutBackdrop={false}
                        overflowScroll={true}
                        className="full-height-modal full-height-modal-right">
                        <div className="custom-modal-header">
                            <MDBRow className="m-0 pt-5 pb-5">
                                <MDBCol size="12" md="7" className="text-left offblue-text">
                                    <h2 className="ml-2 font-weight-bold">{editFulfillmentRequestformik.values.vendor_name}</h2>
                                </MDBCol>
                                <MDBCol size="12" md="5" className="mt-4 mt-md-0 actions-container">
                                    <MDBRow className="m-0 p-0">
                                        <MainBlueButton title="cancel" className="d-contents"
                                            btnClassName="btn-modal-header-cancel"
                                            onClick={() => { toggleFulfillmentRequestDetails(false) }} />
                                        <MainBlueButton title="save" className="d-contents"
                                            btnClassName="btn-modal-header-save"
                                            onClick={() => {
                                                editFulfillmentRequestformik.submitForm();
                                            }} />
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </div>
                        <MDBModalBody>
                            <MDBRow className="m-0 p-0">
                                <MDBCol className="m-0 p-0">
                                    <MDBRow className="m-0 p-0 justify-content-around">
                                        <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                            <label className="text-capitalize">vendor name</label>
                                            <input
                                                disabled
                                                type="text"
                                                placeholder="vendor name"
                                                onChange={(e) => { editFulfillmentRequestformik.setFieldValue('vendor_name', e.target.value); }}
                                                value={editFulfillmentRequestformik.values.vendor_name}
                                                className="form-control styled-input"
                                            />
                                            {editFulfillmentRequestformik.errors.vendor_name && editFulfillmentRequestformik.touched.vendor_name && <p className='red-text'>{editFulfillmentRequestformik.errors.vendor_name}</p>}
                                        </MDBCol>
                                        <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                            <label className="text-capitalize">branch name</label>
                                            <input
                                                disabled
                                                type="text"
                                                placeholder="branch name"
                                                onChange={(e) => { editFulfillmentRequestformik.setFieldValue('branch_name', e.target.value); }}
                                                value={editFulfillmentRequestformik.values.branch_name}
                                                className="form-control styled-input"
                                            />
                                            {editFulfillmentRequestformik.errors.branch_name && editFulfillmentRequestformik.touched.branch_name && <p className='red-text'>{editFulfillmentRequestformik.errors.branch_name}</p>}
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="m-0 p-0 mt-4 justify-content-around">
                                        <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                            <label className="text-capitalize">status</label>
                                            <input
                                                disabled
                                                type="text"
                                                placeholder="status"
                                                onChange={(e) => { editFulfillmentRequestformik.setFieldValue('status', e.target.value); }}
                                                value={editFulfillmentRequestformik.values.status}
                                                className="form-control styled-input"
                                            />
                                            {editFulfillmentRequestformik.errors.status && editFulfillmentRequestformik.touched.status && <p className='red-text'>{editFulfillmentRequestformik.errors.status}</p>}
                                        </MDBCol>
                                        <MDBCol className="p-0 text-left cursor-disabled" size="5">
                                            <label className="text-capitalize">create at</label>
                                            <input
                                                disabled
                                                type="text"
                                                placeholder="create at"
                                                onChange={(e) => { editFulfillmentRequestformik.setFieldValue('created_at', e.target.value); }}
                                                value={editFulfillmentRequestformik.values.created_at}
                                                className="form-control styled-input"
                                            />
                                            {editFulfillmentRequestformik.errors.created_at && editFulfillmentRequestformik.touched.created_at && <p className='red-text'>{editFulfillmentRequestformik.errors.created_at}</p>}
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mt-5 cursor-disabled text-left">
                                <MDBCol>
                                    <label className="text-capitalize">vendor address</label>
                                    <input
                                        disabled
                                        type="text"
                                        placeholder="vendor address"
                                        onChange={(e) => { editFulfillmentRequestformik.setFieldValue('address', e.target.value); }}
                                        value={`${editFulfillmentRequestformik.values.address.country}, ${editFulfillmentRequestformik.values.address.city}, ${editFulfillmentRequestformik.values.address.building_number}, ${editFulfillmentRequestformik.values.address.floor}`}
                                        className="form-control resize-none styled-input"
                                    />
                                    {editFulfillmentRequestformik.errors.address && editFulfillmentRequestformik.touched.address && <p className='red-text'>{editFulfillmentRequestformik.errors.address}</p>}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mt-5 text-left">
                                <MDBCol>
                                    <label className="text-capitalize">notes</label>
                                    <textarea
                                        placeholder="notes"
                                        onChange={(e) => { editFulfillmentRequestformik.setFieldValue('notes', e.target.value); }}
                                        value={editFulfillmentRequestformik.values.notes}
                                        className="form-control resize-none styled-input"
                                    />
                                    {editFulfillmentRequestformik.errors.notes && editFulfillmentRequestformik.touched.notes && <p className='red-text'>{editFulfillmentRequestformik.errors.notes}</p>}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mt-5 text-left">
                                <MDBCol>
                                    <DataTable
                                        headerColumns={editFormEntriesHeaders}
                                        rows={editFormEntriesrows}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>
                    {/*add fulfillment request*/}
                    <MDBModal isOpen={fulfillmentRequestAddOpen} toggle={() => { toggleFulfillmentRequestAdd(!fulfillmentRequestAddOpen) }}
                        fullHeight={true} position="right"
                        inline={false}
                        noClickableBodyWithoutBackdrop={false}
                        overflowScroll={true}
                        className="full-height-modal full-height-modal-right">
                        <div className="custom-modal-header">
                            <MDBRow className="m-0 pt-5 pb-5">
                                <MDBCol size="8" className="text-left offblue-text">
                                    <h2 className="font-weight-bold">Add fulfillment request</h2>
                                </MDBCol>
                                <div className="mt-4 mt-md-0 actions-container">
                                    <MDBRow className="m-0 p-0">

                                        <MainBlueButton title="cancel" className="d-contents"
                                            btnClassName="btn-modal-header-cancel"
                                            onClick={() => { toggleFulfillmentRequestAdd(false) }} />
                                        <MainBlueButton title="save" className="d-contents"
                                            btnClassName="btn-modal-header-save"
                                            onClick={() => {
                                                handleFulfillmentRequestAdd();
                                            }} />

                                    </MDBRow>
                                </div>
                            </MDBRow>
                        </div>
                        <MDBModalBody>
                            <MDBRow className="mt-2 text-left">
                                <MDBCol>
                                    <label className="text-capitalize">fulfillment request name</label>
                                    <input
                                        type="text"
                                        placeholder="fulfillment request name"
                                        onChange={(e) => { addFulfillmentRequestformik.setFieldValue('name', e.target.value); }}
                                        value={addFulfillmentRequestformik.values.name}
                                        className="form-control styled-input"
                                    />
                                    {addFulfillmentRequestformik.errors.name && <p className='red-text'>{addFulfillmentRequestformik.errors.name}</p>}
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>
                </Loader>
                {renderModal()}
            </MDBCol>
        </MDBRow>
    )
}

export default FulfillmentRequest;