export const LOAD_SERVICE_ACCOUNTS_SAGA = "LOAD_SERVICE_ACCOUNTS_SAGA";
export const LOAD_SERVICE_ACCOUNTS = "LOAD_SERVICE_ACCOUNTS";
export const UPDATE_IS_LOADING_SERVICE_ACCOUNTS = "UPDATE_IS_LOADING_SERVICE_ACCOUNTS";
export interface ILoadServiceAccountsActionSaga {
  readonly type: typeof LOAD_SERVICE_ACCOUNTS_SAGA;
  filters: {
    page: number;
    size: number;
    sort: string;
    name: string;
  };
}

export const loadServiceAccountPropsSaga = (filters: ILoadServiceAccountsActionSaga['filters']): ILoadServiceAccountsActionSaga => {
  console.log({filters})
  return {
    type: LOAD_SERVICE_ACCOUNTS_SAGA,
    filters: {
      page: filters.page,
      size: filters.size,
      sort: filters.sort,
      name: filters.name,
    },
  };
};

export const DELETE_SERVICE_ACCOUNT_SAGA = "DELETE_SERVICE_ACCOUNT_SAGA";
export const DELETE_SERVICE_ACCOUNT = "DELETE_SERVICE_ACCOUNT";
export interface IDeleteServiceAccountActionSaga {
  readonly type: typeof DELETE_SERVICE_ACCOUNT_SAGA;
  serviceAccountId: string
}

export const deleteServiceAccountPropsSaga = (serviceAccountId: IDeleteServiceAccountActionSaga['serviceAccountId']): IDeleteServiceAccountActionSaga => {
  return {
    type: DELETE_SERVICE_ACCOUNT_SAGA,
    serviceAccountId: serviceAccountId
  };
};

export const UPDATE_SERVICE_ACCOUNT_SAGA = "UPDATE_SERVICE_ACCOUNT_SAGA";
export const UPDATE_SERVICE_ACCOUNT = "UPDATE_SERVICE_ACCOUNT";
export interface IUpdateServiceAccountActionSaga {
  readonly type: typeof UPDATE_SERVICE_ACCOUNT_SAGA;
  serviceAccountId: string;
  data: {
    account_settings: any[]
  }
}

export const updateServiceAccountPropsSaga = (serviceAccountId: string, data: IUpdateServiceAccountActionSaga['data']): IUpdateServiceAccountActionSaga => {
  return {
    type: UPDATE_SERVICE_ACCOUNT_SAGA,
    serviceAccountId: serviceAccountId,
    data: data
  };
};