import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import { loadInProgressOrdersPropsSaga, loadInProgressOrdersCountPropsSaga, loadInProgressItemsPropsSaga } from "../../sagas/fulfillments_center/types";

import { AppState } from "../../store";
import Loader from "../../components/loader";
import {
    MDBCol, MDBRow, MDBCard, MDBCardBody,
    MDBIcon, MDBContainer, MDBModal,
    MDBModalBody, MDBModalHeader, MDBTabPane, MDBTabContent,
    MDBNav, MDBNavItem, MDBNavLink
} from 'mdbreact';
import Switch from "react-switch";
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import { addQueryString, partialText } from '../../helpers';
import queryString from 'query-string';
import MainBlueButton from "../../components/blueButton";
import { IFProduct } from '../../dtos/IFProduct';
import CustomCheckbox from "../../components/customCheckbox"
import { NavLink } from 'react-router-dom';
import { IOrder } from '../../dtos/IOrder';
import OrderCard from "../../components/orderCard";
//reports
import { exportItemsReport } from "../../api/fulfillmentsCenter"
import ExportButton from "../../components/exportButton"
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";


export default function FulfillmentPicking() {
    const dispatch = useDispatch();
    const fulfillmentsCenterReducer = useSelector((state: AppState) => state.fulfillmentsCenterReducer)
    const routerReducer = useSelector((state: AppState) => state.router);
    const globalReducer = useSelector((state: AppState) => state.globalReducer);
    const { page, orders } = queryString.parse(routerReducer.location.search);
    const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
    const [collapsed, changeCollapse] = useState(false);
    const [confirmed, confirm] = useState(false);
    const [selectedOrders, selectOrder] = useState<any>(orders?.toString().split(',') || []);
    //reports
    const csvLink = useRef<any>();
    const [fileData, setFileDate] = useState('');
    const [isOpen, toggleModal] = useState(false)

    useEffect(() => {
        const pageNum: number = page ? parseInt(page.toString()) - 1 : 0;
        changePage(page ? parseInt(page.toString()) : 1);
        // dispatch(loadInProgressOrdersPropsSaga(8));
        getAllInProgress(collapsed);
        dispatch(loadInProgressOrdersCountPropsSaga());
        dispatch(loadInProgressItemsPropsSaga(pageNum, 10, orders?.toString()));
    }, [routerReducer.location.search]);
    useEffect(() => {
        if (confirmed) {
            // window.scrollTo(0, 0);
            toggleModal(false);
            dispatch(push("/fulfillments-center/packed"))
        }
    }, [confirmed])
    const getAllInProgress = (show: boolean) => {
        changeCollapse(show);
        if (show == true) { dispatch(loadInProgressOrdersPropsSaga(1000)); }
        else {
            dispatch(loadInProgressOrdersPropsSaga(8));
        }
    }
    const headers: any = [
        { name: "#", width: 1 },
        { name: "Product Name", width: 3 },
        { name: "Product ID", width: 1 },
        { name: "SKU", width: 1 },
        { name: "No. of Items", width: 1 },
        { name: "Location", width: 1 },
        { name: "SO", width: 3 },
    ].map((header) => {
        return { ...header };
    });
    const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);
    const handlePaginationChange = (e: any, { activePage }: any) => {
        dispatch(push({
            search: addQueryString(routerReducer.location.search, 'page', activePage)
        }));
    }



    const buildRows = (inProgressItems: IFProduct[]): IRow[] => {
        return inProgressItems.map((item: IFProduct, i) => {
            return {
                value: item,
                props: [
                    {
                        edit: () => {
                            return <h6 className={`text-font-black m-0 pr-5 text-center`}>{i + 1}</h6>
                        }
                    },
                    'product.name', 'product.external_reference', 'product.sku',
                    {
                        edit: (rowValue: IFProduct) => {
                            let quantity = 0;
                            rowValue.orders.map((o: any) => {
                                quantity = quantity + o.quantity
                            })
                            return <h6 className={`text-font-black m-0 text-center`}>{quantity}</h6>
                        }
                    }
                    , 'location',
                    {
                        edit: (rowValue: IFProduct) => {
                            return rowValue.orders.map((o: any) => {
                                return <MDBRow className="p-1 cursor">
                                    <h6 className={`text-font-black m-0 pr-5 text-center`}>#{o.external_reference}</h6>
                                    <h6 className={`text-font-black m-0 text-center`}>{`${o.quantity} items`}</h6>
                                </MDBRow>
                            })
                        }
                    }

                ]
            }
        });
    };
    const rows = useMemo(() => buildRows(fulfillmentsCenterReducer.inProgressItems), [fulfillmentsCenterReducer.inProgressItems]);
    const handleSelectOrder = (orderID: number) => {
        const existed = selectedOrders.find((id: number) => orderID == id)
        let orders: number[] = selectedOrders;
        if (!existed) {
            orders.push(orderID)
        }
        else {
            orders = orders.filter((id: number) => orderID != id)
        }
        selectOrder(orders)
        const ordersString: string = orders.toString();
        if (ordersString != '') {
            const newQueryString = `orders=${ordersString}`
            dispatch(push({
                search: addQueryString(newQueryString, 'page', 1)
            }));
        }
        else {
            dispatch(push({
                search: ''
            }));
        }
    }
    const handleReset = () => {
        selectOrder([])
        dispatch(push({
            search: ''
        }));
        window.location.reload();
    }
    const handleExport = async () => {
        await exportItemsReport(globalReducer.authUser.token, orders?.toString()).then((result) => {
            setFileDate(result)
        })
        csvLink.current.link.click();
    }
    //confirmation
    const renderModal = () => {
        return <MDBContainer className="confirmation-modal">
            <MDBModal
                isOpen={isOpen}
                toggle={() => toggleModal(!isOpen)}
                inline={false}
                noClickableBodyWithoutBackdrop={false}
                overflowScroll={true}>
                <MDBModalBody className="p-4" >
                    <div className="pt-2 text-center" >
                        <p className={`fa-1_5x text-weight-400 text-font-black`}>Are you sure?</p>
                    </div>
                    <MDBRow className="filter-buttons-container w-100 d-flex mt-3 ml-2 justify-content-center">
                        {/* <NavLink to={"/fulfillments-center/packed"}> */}
                        <MainBlueButton
                            title="Yes"
                            className="mr-1"
                            btnClassName="pt-2 pb-2 pl-4 pr-4"
                            onClick={() => {
                                toggleModal(false)
                                confirm(true)
                                // dispatch(push("/fulfillments-center/packed"))
                            }}
                        />
                        {/* </NavLink> */}
                        <MainBlueButton
                            className="reset-button"
                            title="No"
                            btnClassName="pt-2 pb-2 pl-4 pr-4"
                            onClick={() => {
                                toggleModal(false)
                                confirm(false)
                            }}
                        />
                    </MDBRow>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>

    }
    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                {fulfillmentsCenterReducer.inProgressOrdersCount > 8 && <h6 onClick={() => getAllInProgress(!collapsed)} className={`grey-text m-0 pr-1 text-right cursor`} style={{ textDecoration: 'underline' }}>{collapsed ? 'See less' : 'See more'}</h6>}
                <MDBRow start className="justify-content-center mt-2 w-100">
                    {fulfillmentsCenterReducer.inProgressOrders.map((o: IOrder) => {
                        return <OrderCard handleSelectOrder={handleSelectOrder} order={o}
                            selectedOrder={selectedOrders.find((id: any) => id == o.external_reference) ? true : false}
                        />
                    })}
                </MDBRow>
                <Loader isLoading={fulfillmentsCenterReducer.loadingInProgressOrders.isLoadingInProgressOrders} errorMessage={fulfillmentsCenterReducer.loadingInProgressOrders.errorMessage}>
                    <MDBRow start className="justify-content-center mt-2 bg-white fulfillment-container">
                        {
                            fulfillmentsCenterReducer.inProgressItemsCount > 0 ?
                                <MDBCol className="text-center p-0 ">
                                    <MDBRow className="">
                                        <MDBCol sm={`9`} className='pl-5 pt-3 text-left'>
                                            <MDBRow className="m-0 p-0">
                                                <h6 className={`text-font-black font-weight-bold pr-5`}>Items</h6>
                                            </MDBRow>
                                        </MDBCol>
                                        <MDBCol sm={`1`} className='m-0 p-0 pt-3 text-left'>
                                            <MainBlueButton
                                                title="Reset"
                                                className={`h1 d-contents ${selectedOrders.length > 0 ? 'active-reset-button' : 'reset-button'}`}
                                                disabled={selectedOrders.length > 0 ? false : true}
                                                icon="sync"
                                                btnClassName="pt-2 pb-2 pl-3 pr-3"
                                                onClick={() => handleReset()}
                                            />
                                        </MDBCol>
                                        <MDBCol className='m-0 p-0 pt-3 text-left'>
                                            <MainBlueButton
                                                title="Export"
                                                className="h1 d-contents"
                                                icon="print"
                                                btnClassName="pt-2 pb-2 pl-5 pr-5"
                                                onClick={handleExport}
                                            />
                                            <CSVLink
                                                data={fileData}
                                                filename="items-report.csv"
                                                className='hidden'
                                                ref={csvLink}
                                                target='_blank'
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <DataTable
                                        headerColumns={headerColumns}
                                        rows={rows}
                                        // headerOnClick={handleSort}
                                        totalPages={fulfillmentsCenterReducer.inProgressItemsCount / 10}
                                        activePage={activePage}
                                        onPageChange={handlePaginationChange}
                                        bottomCelled={true}
                                    />
                                </MDBCol>
                                : <div>You don't have any in progress items.</div>
                        }
                    </MDBRow>
                    <MDBCol className="p-4 d-flex justify-content-center w-100">
                        {/* <NavLink to={"/fulfillments-center/packed"}> */}
                        <MainBlueButton
                            title="Done"
                            className="h1 d-contents"
                            btnClassName="pt-4 pb-4 pl-5 pr-5"
                            largeButton={true}
                            onClick={() => toggleModal(!isOpen)}
                        />
                        {/* </NavLink> */}
                    </MDBCol>
                </Loader>
            </MDBCol>
            {renderModal()}
        </MDBRow>
    )
}
