import { IFProduct } from '../../dtos/IFProduct';
import { IOrder } from '../../dtos/IOrder';
import {
    LOAD_FULFILLMENTS_CENTER, UPDATE_IS_LOADING_FULFILLMENTS_CENTER,
    //ready
    LOAD_READY_ORDERS, UPDATE_IS_LOADING_READY_ORDERS,
    //in progress
    LOAD_IN_PROGRESS_ORDERS_COUNT,
    LOAD_IN_PROGRESS_ORDERS, LOAD_IN_PROGRESS_ITEMS,
    UPDATE_IS_LOADING_IN_PROGRESS_ORDERS,
    //packed
    LOAD_PACKED_ORDERS, UPDATE_IS_LOADING_PACKED_ORDERS,
    UPDATE_ORDER, FINISH_ORDERS,

} from '../../sagas/fulfillments_center/types';
export interface ILoadFulfillmentsCenterAction {
    readonly type: typeof LOAD_FULFILLMENTS_CENTER;
    payload: {
        totalOrdersCount: number,
        totalItemsCount: number,
        readyOrdersCount: number,
        pendingOrdersCount: number,
        readyItemsCount: number,
        pendingItemsCount: number
    }
}

export const loadFulfillmentsCenterProps = (payload: ILoadFulfillmentsCenterAction['payload']): ILoadFulfillmentsCenterAction => {
    return {
        type: LOAD_FULFILLMENTS_CENTER,
        payload: payload
    }
};

export interface IUpdateIsLoadingFulfillmentsCenterAction {
    readonly type: typeof UPDATE_IS_LOADING_FULFILLMENTS_CENTER;
    payload: {
        isLoadingFulfillmentsCenter: boolean
        errorMessage: string | undefined
    }
}

export const updateIsLoadingFulfillmentsCenterProps = (payload: IUpdateIsLoadingFulfillmentsCenterAction['payload']): IUpdateIsLoadingFulfillmentsCenterAction => {
    return {
        type: UPDATE_IS_LOADING_FULFILLMENTS_CENTER,
        payload: payload
    }
};
//ready orders
export interface ILoadReadyOrdersAction {
    readonly type: typeof LOAD_READY_ORDERS;
    payload: {
        readyOrders: IOrder[],
        readyOrdersCount: number,
    }
}

export const loadReadyOrdersProps = (payload: ILoadReadyOrdersAction['payload']): ILoadReadyOrdersAction => {
    return {
        type: LOAD_READY_ORDERS,
        payload: payload
    }
};

export interface IUpdateIsLoadingReadyOrdersAction {
    readonly type: typeof UPDATE_IS_LOADING_READY_ORDERS;
    payload: {
        isLoadingReadyOrders: boolean
        errorMessage: string | undefined
    }
}

export const updateIsLoadingReadyOrdersProps = (payload: IUpdateIsLoadingReadyOrdersAction['payload']): IUpdateIsLoadingReadyOrdersAction => {
    return {
        type: UPDATE_IS_LOADING_READY_ORDERS,
        payload: payload
    }
};
//in progress
export interface ILoadInProgressOrdersCountAction {
    readonly type: typeof LOAD_IN_PROGRESS_ORDERS_COUNT;
    payload: {
        inProgressOrdersCount: number,
    }
}

export const loadInProgressOrdersCountProps = (payload: ILoadInProgressOrdersCountAction['payload']): ILoadInProgressOrdersCountAction => {
    return {
        type: LOAD_IN_PROGRESS_ORDERS_COUNT,
        payload: payload
    }
};
export interface ILoadInProgressOrdersAction {
    readonly type: typeof LOAD_IN_PROGRESS_ORDERS;
    payload: {
        inProgressOrders: IOrder[],
    }
}

export const loadInProgressOrdersProps = (payload: ILoadInProgressOrdersAction['payload']): ILoadInProgressOrdersAction => {
    return {
        type: LOAD_IN_PROGRESS_ORDERS,
        payload: payload
    }
};
export interface ILoadInProgressItemsAction {
    readonly type: typeof LOAD_IN_PROGRESS_ITEMS;
    payload: {
        inProgressItems: IFProduct[],
        inProgressItemsCount: number,
    }
}

export const loadInProgressItemsProps = (payload: ILoadInProgressItemsAction['payload']): ILoadInProgressItemsAction => {
    return {
        type: LOAD_IN_PROGRESS_ITEMS,
        payload: payload
    }
};

export interface IUpdateIsLoadingInProgressOrdersAction {
    readonly type: typeof UPDATE_IS_LOADING_IN_PROGRESS_ORDERS;
    payload: {
        isLoadingInProgressOrders: boolean
        errorMessage: string | undefined
    }
}

export const updateIsLoadingInProgressOrdersProps = (payload: IUpdateIsLoadingInProgressOrdersAction['payload']): IUpdateIsLoadingInProgressOrdersAction => {
    return {
        type: UPDATE_IS_LOADING_IN_PROGRESS_ORDERS,
        payload: payload
    }
};
//packed orders
export interface ILoadPackedOrdersAction {
    readonly type: typeof LOAD_PACKED_ORDERS;
    payload: {
        packedOrders: IOrder[],
        packedOrdersCount: number,
    }
}

export const loadPackedOrdersProps = (payload: ILoadPackedOrdersAction['payload']): ILoadPackedOrdersAction => {
    return {
        type: LOAD_PACKED_ORDERS,
        payload: payload
    }
};

export interface IUpdateIsLoadingPackedOrdersAction {
    readonly type: typeof UPDATE_IS_LOADING_PACKED_ORDERS;
    payload: {
        isLoadingPackedOrders: boolean
        errorMessage: string | undefined
    }
}

export const updateIsLoadingPackedOrdersProps = (payload: IUpdateIsLoadingPackedOrdersAction['payload']): IUpdateIsLoadingPackedOrdersAction => {
    return {
        type: UPDATE_IS_LOADING_PACKED_ORDERS,
        payload: payload
    }
};
//update order
export interface IUpdateOrderAction {
    readonly type: typeof UPDATE_ORDER;
    payload: {
        orderUpdated: boolean
    }
}

export const updateOrderProps = (payload: IUpdateOrderAction['payload']): IUpdateOrderAction => {
    return {
        type: UPDATE_ORDER,
        payload: payload
    }
};
//finish orders
export interface IFinishOrdersAction {
    readonly type: typeof FINISH_ORDERS;
    payload: {
        ordersFinished: boolean
    }
}

export const finishOrdersProps = (payload: IFinishOrdersAction['payload']): IFinishOrdersAction => {
    return {
        type: FINISH_ORDERS,
        payload: payload
    }
};
export type FulfillmentsCenterActions = ILoadFulfillmentsCenterAction | IUpdateIsLoadingFulfillmentsCenterAction
    | ILoadReadyOrdersAction | IUpdateIsLoadingReadyOrdersAction
    | ILoadInProgressOrdersCountAction | ILoadInProgressOrdersAction | ILoadInProgressItemsAction | IUpdateIsLoadingInProgressOrdersAction
    | ILoadPackedOrdersAction | IUpdateIsLoadingPackedOrdersAction
    | IUpdateOrderAction | IFinishOrdersAction;