import React, { useState } from 'react';
import './index.scss';
import { MDBRow } from 'mdbreact';

interface IInputProps {
    className?: any,
    fieldClassName?: any,
    label: string,
    required: boolean,
    placeholder: string,
    type?: string,
    name?: string,
    defaultValue?: string,
    value?: any,
    onChange?: any,
    key?: any,
    step?: any,
    disabled?: boolean,
    maxlength?: number
}
function Input(props: IInputProps) {
    return (
        <MDBRow key={props.key && props.key}
            className={`pl-3 {props.className}`}>
            {props.label != "" && <label htmlFor={props.name} className="font-weight-bold pl-3 pt-3">{props.label}
                {props.required && <span className='red-text'>{' *'}</span>}
            </label>}
            <input
                key={props.key && props.key}
                id={props.name && props.name}
                className={`form-control input-rounded pt-4 pb-4 pl-3 ${props.fieldClassName && props.fieldClassName}`}
                placeholder={props.placeholder}
                type={props.type ? props.type : 'text'}
                name={props.name ? props.name : " "}
                defaultValue={props.defaultValue ? props.defaultValue : ''}
                value={props.value && props.value}
                onChange={props.onChange ? props.onChange : ''}
                min="0"
                step={props.step && props.step}
                disabled={props.disabled ? props.disabled : false}
                maxLength={props.maxlength ? props.maxlength : undefined}
                onKeyDown={(e: any) => {
                    if (props.type == "number" && e.code == "Minus") {
                        e.preventDefault();
                        return false
                    }
                }}
            />
        </MDBRow>
    );
}

export default Input;
