export interface IDropdown{
  id: string,
  name: string
}

export enum Months{
  _0 = 'Jan',
  _1 = 'Feb',
  _2 = 'Mar',
  _3 = 'Apri',
  _4 = 'May',
  _5 = 'Jun',
  _6 = 'Jul',
  _7 = 'Aug',
  _8 = 'Sep',
  _9 = 'Oct',
  _10 = 'Nov',
  _11 = 'Dec',
}