import React from "react";
import FulfillmentOrders from "../../containers/fulfillmentsCenter/fulfillmentOrders";

interface IFulfillmentOrdersPageProps {
}

export default function FulfillmentOrdersPage(props: IFulfillmentOrdersPageProps) {
    return (
        <FulfillmentOrders />
    )
}