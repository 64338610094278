import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAuthUserPropsSaga } from '../../sagas/global/types';
import { validateInvitationProps } from '../../sagas/user/types';
import { AppState } from "../../store";
import { MDBRow, MDBBtn, MDBCardText } from 'mdbreact';
interface IInvitationValidationPageProps {
}

export default function InvitationValidationPage(props: IInvitationValidationPageProps) {
    const dispatch = useDispatch();
    const userReducer = useSelector((state: AppState) => state.userReducer);
    const loginWithKeycloak: boolean = (process.env.REACT_APP_AUTH_KEY_CLOAK && process.env.REACT_APP_AUTH_KEY_CLOAK.toLowerCase() === 'true')? true : false;


    useEffect(() => {
        let hash = window.location.hash
        let invitationToken = hash.split('=')[1]
        dispatch(validateInvitationProps(invitationToken))
    }, [userReducer.valid]);
    const signIn = () => {
        const url = window.location.href.substr(0, window.location.href.indexOf('#'));
        window.history.replaceState({}, document.title, url);
        dispatch(updateAuthUserPropsSaga({authWithKeycloak: loginWithKeycloak}));
    }
    return (
        <>
            <MDBRow className="m-0 bg-white">
                {userReducer.valid ? <MDBRow className="m-5 w-100">
                    <MDBCardText className="ml-5" tag="h1">Welcome!</MDBCardText>
                    <MDBRow className="m-5 w-100">
                        <MDBCardText tag="h3">Good to see you on Dentacarts Admin!<br /> Please click below to continue</MDBCardText>
                    </MDBRow>
                    <MDBRow className="w-100 justify-content-center">
                        <MDBBtn className="main-blue-button" color="amber"
                            onClick={() => signIn()}
                        >Sign In</MDBBtn>
                    </MDBRow>
                </MDBRow> :
                    <MDBRow className="m-5 w-100">
                        <MDBCardText className="ml-5" tag="h1">:(</MDBCardText>
                        <MDBRow className="m-5 w-100">
                            <MDBCardText tag="h3">Unfortunely, This invitation has been expired</MDBCardText>
                        </MDBRow>
                    </MDBRow>
                }
            </MDBRow>
        </>
    )
}
