import {IUpdateIsLoadingServiceAccountsAction, ServiceAccountsActions} from './types'
import {
    LOAD_SERVICE_ACCOUNTS, UPDATE_IS_LOADING_SERVICE_ACCOUNTS,
    DELETE_SERVICE_ACCOUNT, UPDATE_SERVICE_ACCOUNT
} from "../../sagas/service_account/types";
import _ from 'lodash';

interface IServiceAccountsState {
    filters: {
        sortCol: string,
        sortOrder: 'ascending' | 'descending'
    },
    serviceAccounts: any[],
    totalCountPages: number,
    loadingServiceAccounts: IUpdateIsLoadingServiceAccountsAction['payload'],
}

const ServiceAccountsState: IServiceAccountsState = {
    filters: {
        sortCol: '',
        sortOrder: 'ascending'
    },
    serviceAccounts: [],
    totalCountPages: 0,
    loadingServiceAccounts: {
        isLoadingServiceAccounts: false,
        errorMessage: undefined
    }
};

const serviceAccountsReducer = (state = ServiceAccountsState, action: ServiceAccountsActions): IServiceAccountsState => {
    switch (action.type) {
        case LOAD_SERVICE_ACCOUNTS:
            return {
                ...state,
                serviceAccounts: action.payload.serviceAccounts,
                totalCountPages: action.payload.totalCountPages,
            };
        case UPDATE_IS_LOADING_SERVICE_ACCOUNTS:
            return {
                ...state,
                loadingServiceAccounts: action.payload,
            };
        case DELETE_SERVICE_ACCOUNT:
            const updatedServiceAccounts = [...state.serviceAccounts];
            _.remove(updatedServiceAccounts, (serviceAccount, i) => serviceAccount.id === action.payload.serviceAccountId)
            return {
                ...state,
                serviceAccounts: updatedServiceAccounts
            };
        case UPDATE_SERVICE_ACCOUNT:
            return {
                ...state,
                serviceAccounts: state.serviceAccounts.map((serviceAccount, i) => serviceAccount.id?.toString() == action.payload.serviceAccountId? {...serviceAccount, ...action.payload.data}:serviceAccount)
            };
        default:
            return state;
    }
};

export default serviceAccountsReducer;
