import React from "react";
import FulfillmentsCenter from "../../containers/fulfillmentsCenter";

interface IFulfillmentDashboardPageProps {
}

export default function FulfillmentDashboardPage(props: IFulfillmentDashboardPageProps) {
    return (
        <FulfillmentsCenter />
    )
}