import { put, takeLatest, call } from "redux-saga/effects";
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
  INVITE_NEW_USER_SAGA, IInviteNewUserActionSaga,
  VALIDATE_INVITATION_SAGA, IValidateInvitationActionSaga,
  LOAD_INVITATIONS_SAGA, ILoadInvitationsActionSaga,
  LOAD_ADMINS_SAGA, ILoadAdminsActionSaga,
} from "./types";
import {
  sendInvitation,
  validateInvitation,
  fetchInvitations,
  fetchAdmins
} from "../../api/users";
import {
  InviteNewUserProps,
  validateInvitationProps,
  updateIsLoadingInvitationsProps,
  loadInvitationsProps,
  loadAdminsProps,
  updateIsLoadingAdminsProps,
} from "../../reducers/user/types";
import IUser from "../../dtos/IUser";


function* sendInvitationData(action: IInviteNewUserActionSaga) {
  try {
    const invitationSent: boolean = yield call(sendInvitation, action.invitation);
    yield put(InviteNewUserProps({ invitationSent }));
  } catch (error) {
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchSendInvitationData() {
  yield takeLatest(INVITE_NEW_USER_SAGA, sendInvitationData);
}

function* validateInvitationToken(action: IValidateInvitationActionSaga) {
  try {
    const valid: boolean = yield call(validateInvitation, action.token);
    yield put(validateInvitationProps({ valid }));
  } catch (error) {
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchValidateInvitationToken() {
  yield takeLatest(VALIDATE_INVITATION_SAGA, validateInvitationToken);
}

function* loadInvitations(action: ILoadInvitationsActionSaga) {
  try {
    yield put(
      updateIsLoadingInvitationsProps({
        isLoadingInvitations: true,
        errorMessage: undefined,
      })
    );
    const invitationsData: {
      invitations: any[];
      totalInvitationsCount: number;
    } = yield call(fetchInvitations, action.page, action.size, action.sort);
    yield put(
      loadInvitationsProps({
        invitations: invitationsData.invitations,
        totalInvitationsCount: invitationsData.totalInvitationsCount,
      })
    );
    yield put(
      updateIsLoadingInvitationsProps({
        isLoadingInvitations: false,
        errorMessage: undefined,
      })
    );
  } catch (error) {
    yield put(
      updateIsLoadingInvitationsProps({
        isLoadingInvitations: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}

export function* watchLoadInvitations() {
  yield takeLatest(LOAD_INVITATIONS_SAGA, loadInvitations);
}


function* loadAdmins(action: ILoadAdminsActionSaga) {
  try {
    yield put(
      updateIsLoadingAdminsProps({
        isLoadingAdmins: true,
        errorMessage: undefined,
      })
    );
    const adminsData: {
      admins: IUser[];
      totalAdminsCount: number;
    } = yield call(fetchAdmins, action.page, action.size, action.sort,action.name);
    yield put(
      loadAdminsProps({
        admins: adminsData.admins,
        totalAdminsCount: adminsData.totalAdminsCount,
      })
    );
    yield put(
      updateIsLoadingAdminsProps({
        isLoadingAdmins: false,
        errorMessage: undefined,
      })
    );
  } catch (error) {
    yield put(
      updateIsLoadingAdminsProps({
        isLoadingAdmins: false,
        errorMessage: error.message,
      })
    );
    yield put(displayErrorMessageProps({ message: error.message }));
  }
}
export function* watchLoadAdmins() {
  yield takeLatest(LOAD_ADMINS_SAGA, loadAdmins);
}

export default [
  watchSendInvitationData(),
  watchValidateInvitationToken(),
  watchLoadInvitations(),
  watchLoadAdmins(),
];
