import IStorage from "../../dtos/IStorage";

export const LOAD_STORAGES = "LOAD_STORAGES"
export const LOAD_STORAGES_SAGA = "LOAD_STORAGES_SAGA"

export const LOAD_STORAGE = "LOAD_STORAGE"
export const LOAD_STORAGE_SAGA = "LOAD_STORAGE_SAGA"

export const LOAD_STORAGE_TRANSACTIONS = "LOAD_STORAGE_TRANSACTIONS"
export const LOAD_STORAGE_TRANSACTIONS_SAGA = "LOAD_STORAGE_TRANSACTIONS_SAGA"

export const LOAD_MODELS = "LOAD_MODELS"
export const LOAD_MODELS_SAGA = "LOAD_MODELS_SAGA"

export const LOAD_SERVICE_ACCOUNTS = "LOAD_SERVICE_ACCOUNTS"
export const LOAD_SERVICE_ACCOUNTS_SAGA = "LOAD_SERVICE_ACCOUNTS_SAGA"

export const LOAD_ACCOUNT_BRANCHES = "LOAD_ACCOUNT_BRANCHES"
export const LOAD_ACCOUNT_BRANCHES_SAGA = "LOAD_ACCOUNT_BRANCHES_SAGA"

export const UPDATE_STORAGE = "UPDATE_STORAGE"
export const UPDATE_STORAGE_SAGA = "UPDATE_STORAGE_SAGA"

export const ADD_NEW_STORAGE = "ADD_NEW_STORAGE"
export const ADD_NEW_STORAGE_SAGA = "ADD_NEW_STORAGE_SAGA"

export const UPDATE_IS_LOADING_STORAGES = "UPDATE_IS_LOADING_STORAGES"

export interface ILoadStoragesActionSaga {
    readonly type: typeof LOAD_STORAGES_SAGA;
    page: number,
    size: number,
    sort: string,
    name: string,
    status: string,
    storageModel: string,
    serviceAccount: string
}
export const loadStoragesPropsSaga = (page = 0, size = 0, sort = '', name = '', status = '', storageModel = '', serviceAccount = ''): ILoadStoragesActionSaga => {
    return {
        type: LOAD_STORAGES_SAGA,
        page: page,
        sort: sort,
        size: size,
        name: name,
        status: status,
        storageModel: storageModel,
        serviceAccount: serviceAccount
    }
};

export interface ILoadStorageActionSaga {
    readonly type: typeof LOAD_STORAGE_SAGA;
    storageId: string
}
export const loadStoragePropsSaga = (storageId = ''): ILoadStorageActionSaga => {
    return {
        type: LOAD_STORAGE_SAGA,
        storageId: storageId
    }
};

export interface ILoadStorageTransactionsActionSaga {
    readonly type: typeof LOAD_STORAGE_TRANSACTIONS_SAGA;
    storageId: string
}
export const loadStorageTransactionsPropsSaga = (storageId = ''): ILoadStorageTransactionsActionSaga => {
    return {
        type: LOAD_STORAGE_TRANSACTIONS_SAGA,
        storageId: storageId
    }
};

export interface ILoadModelsActionSaga {
    readonly type: typeof LOAD_MODELS_SAGA;
}
export const loadModelsPropsSaga = (): ILoadModelsActionSaga => {
    return {
        type: LOAD_MODELS_SAGA,
    }
};

export interface ILoadServiceAccountsActionSaga {
    readonly type: typeof LOAD_SERVICE_ACCOUNTS_SAGA;
}
export const loadServiceAccountsPropsSaga = (): ILoadServiceAccountsActionSaga => {
    return {
        type: LOAD_SERVICE_ACCOUNTS_SAGA,
    }
};

export interface ILoadAccountBranchesActionSaga {
    readonly type: typeof LOAD_ACCOUNT_BRANCHES_SAGA;
    serviceAccount: number
}
export const loadAccountBranchesPropsSaga = (serviceAccount: number): ILoadAccountBranchesActionSaga => {
    return {
        type: LOAD_ACCOUNT_BRANCHES_SAGA,
        serviceAccount: serviceAccount
    }
};

export interface IAddNewStorageActionSaga {
    readonly type: typeof ADD_NEW_STORAGE_SAGA;
    storage: IStorage
}
export const addNewStoragePropsSaga = (storage: IStorage): IAddNewStorageActionSaga => {
    return {
        type: ADD_NEW_STORAGE_SAGA,
        storage: storage
    }
};

export interface IUpdateStorageActionSaga {
    readonly type: typeof UPDATE_STORAGE_SAGA;
    storage: IStorage
}
export const updateStoragePropsSaga = (storage: IStorage): IUpdateStorageActionSaga => {
    return {
        type: UPDATE_STORAGE_SAGA,
        storage: storage
    }
};
