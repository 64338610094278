import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom'
import Select from '../../components/select';
import { push } from 'connected-react-router'
import { AppState } from "../../store";
import { MDBCol, MDBRow, MDBIcon } from 'mdbreact';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import "../../assets/styles/app.scss"
import { addQueryString } from '../../helpers';
import queryString from 'query-string';
import constants from "../../constants"
import "react-datepicker/dist/react-datepicker.css";
import { fetchDataTableServiceAccountBranches } from '../../api/serviceAccount';

interface IServiceAccountBranchesListProps { }
export default function ServiceAccountBranchesList(props: IServiceAccountBranchesListProps) {
    const params: any = useParams();
    const dispatch = useDispatch();
    const [branches, updateBranches] = useState<any[]>([]);
    const [branchesTotalPages, updateBranchesTotalPages] = useState(0);
    const routerReducer = useSelector((state: AppState) => state.router)
    const { sort, page } = queryString.parse(routerReducer.location.search);
    const [view, selectView] = useState(10);

    useEffect(() => {
        const pageNum: number = page ? parseInt(page.toString()) - 1 : 0;
        const [columnName, columnSorted] = sort ? sort.toString().split(',') : [];
        const sorting: string = sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc').replace('date', 'createdAt') : '';
        updateHeaderCloumns(updateHeaderSorting(columnName, columnSorted));
        changePage(page ? parseInt(page.toString()) : 1);
        const handleLoadBranches = async () => {
            const results = await fetchDataTableServiceAccountBranches(params.serviceAccountId, pageNum, view, sorting);
            updateBranches(results.branches);
            updateBranchesTotalPages(results.totalCountPages);
        }
        handleLoadBranches();
    }, [routerReducer.location.search, view]);
    const updateHeaderSorting = (columnName?: string, columnSorted?: string): IHeaderColumn[] => {
        return headerColumns.map((headerColumn, i) => {
            if (columnName && headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                switch (columnSorted) {
                    case 'ascending':
                        return {
                            ...headerColumn,
                            sorted: 'ascending'
                        }
                    case 'descending':
                        return {
                            ...headerColumn,
                            sorted: 'descending'
                        }
                    default:
                        return {
                            ...headerColumn,
                            sorted: undefined
                        }
                }
            } else {
                return {
                    ...headerColumn,
                    sorted: undefined
                }
            }
        });
    }
    const handleSort = (columnName: string, columnSorted?: 'ascending' | 'descending') => {
        let sorting = ``;
        headerColumns.map((headerColumn, i) => {
            let colName = headerColumn.name.toLocaleLowerCase()
            if (colName != "") {
                if (headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                    sorting = `${columnName.toLowerCase()},`;
                    switch (columnSorted) {
                        case undefined:
                            sorting += 'ascending';
                            break;
                        case 'ascending':
                            sorting += 'descending';
                            break;
                        default:
                            sorting = '';
                    }

                }
            }
        });
        const newQueryString = addQueryString(routerReducer.location.search, 'sort', sorting);
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }
    const headers: any = [
        { name: "id", width: 2 },
        { name: "name", width: 3 },
    ];
    const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);
    const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
    const handlePaginationChange = (e: any, { activePage }: any) => {
        dispatch(push({
            search: addQueryString(routerReducer.location.search, 'page', activePage)
        }));
    }

    const buildRows = (branches: any[]): IRow[] => {
        return branches.map((branch: any, i) => {
            return {
                value: branch,
                props: [
                    'id',
                    'name',
                ]
            }
        });
    };
    const rows = useMemo(() => buildRows(branches), [branches]);
    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                <MDBRow start className="mt-2 mb-4">
                    <MDBCol md="6" sm="6" className="p-0 text-justify">
                        <Select name="view"
                            id="view-select"
                            className="mr-2 d-inline"
                            label="View By"
                            options={constants.viewOptions.map((option, i) => {
                                return { text: option.value, value: option.id }
                            })}
                            selectedValue={view}
                            onChange={(e: any) => {
                                selectView(e.target.value)
                                dispatch(push({
                                    search: ''
                                }));
                            }}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow start className="justify-content-center mt-2">
                    <MDBCol className="text-center p-0">
                        {
                            branches.length > 0 ?
                                <DataTable
                                    headerColumns={headerColumns}
                                    rows={rows}
                                    data={branches}
                                    headerOnClick={handleSort}
                                    totalPages={branchesTotalPages / view}
                                    activePage={activePage}
                                    onPageChange={handlePaginationChange}
                                />
                                : <div className="mt-5" >There's no branches</div>
                        }
                    </MDBCol>
                </MDBRow>
            </MDBCol>
        </MDBRow>
    );
}
