import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Select from '../../components/select';
import { push } from 'connected-react-router'
import {
    loadStoragesPropsSaga, loadStoragePropsSaga,
    loadStorageTransactionsPropsSaga,
    loadModelsPropsSaga
} from "../../sagas/storage/types";
import { loadServiceAccountPropsSaga } from '../../sagas/service_account/types';
import { AppState } from "../../store";
import Loader from "../../components/loader";
import { MDBCol, MDBRow, MDBIcon, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import "../../assets/styles/app.scss"
import IStorage from "../../dtos/IStorage"
import { addQueryString } from '../../helpers';
import queryString from 'query-string';
import MainBlueButton from "../../components/blueButton"
import IconContainer from "../../components/iconContainer"
import constants from "../../constants"
import TableMenu from "../../components/tableMenu"
import StorageDetails from "./storageDetails"
import StorageForm, { storageFormik } from "./storageForm"



interface IStoragesListProps {

}

export default function StoragesList(props: IStoragesListProps) {
    const dispatch = useDispatch();
    const storageReducer = useSelector((state: AppState) => state.storageReducer)
    const serviceAccountsReducer=useSelector((state:AppState)=>state.serviceAccountReducer)
    const routerReducer = useSelector((state: AppState) => state.router)
    const { sort, page, searchkeyword, status, storageModel, serviceAccount } = queryString.parse(routerReducer.location.search);
    const [searchText, updateSearchText] = useState(searchkeyword ? searchkeyword.toString() : '');
    const [selectedStatus, selectStatus] = useState(status ? status.toString() : '');
    const [selectedStorageModel, selectStorageModel] = useState(storageModel ? storageModel.toString() : '');
    const [selectedServiceAccount, selectServiceAccount] = useState(serviceAccount ? serviceAccount.toString() : '');
    //new ui
    const [view, selectView] = useState(10)
    const [isOpen, toggleModal] = useState(false)
    const [storageModalOpen, toggleStorageModal] = useState(false)
    const [addStorageModalOpen, toggleAddStorageModal] = useState(false)

    useEffect(() => {
        const pageNum: number = page ? parseInt(page.toString()) - 1 : 0;
        const [columnName, columnSorted] = sort ? sort.toString().split(',') : [];
        const sorting: string = sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc') : '';
        updateHeaderCloumns(updateHeaderSorting(columnName, columnSorted));
        changePage(page ? parseInt(page.toString()) : 1);
        updateSearchText(searchkeyword ? searchkeyword.toString() : '');
        selectStatus(status ? status.toString() : '');
        selectStorageModel(storageModel ? storageModel.toString() : '');
        selectServiceAccount(serviceAccount ? serviceAccount.toString() : '');
        dispatch(loadStoragesPropsSaga(pageNum, view, sorting, searchkeyword?.toString(), status?.toString(), storageModel?.toString(), serviceAccount?.toString()));
        dispatch(loadModelsPropsSaga());
        dispatch(loadServiceAccountPropsSaga({page:0,size:1000,name:"",sort:""}));
    }, [routerReducer.location.search, view]);
    const updateHeaderSorting = (columnName?: string, columnSorted?: string): IHeaderColumn[] => {
        return headerColumns.map((headerColumn, i) => {
            if (columnName && headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                switch (columnSorted) {
                    case 'ascending':
                        return {
                            ...headerColumn,
                            sorted: 'ascending'
                        }
                    case 'descending':
                        return {
                            ...headerColumn,
                            sorted: 'descending'
                        }
                    default:
                        return {
                            ...headerColumn,
                            sorted: undefined
                        }
                }
            } else {
                return {
                    ...headerColumn,
                    sorted: undefined
                }
            }
        });
    }
    const handleSort = (columnName: string, columnSorted?: 'ascending' | 'descending') => {
        let sorting = ``;
        headerColumns.map((headerColumn, i) => {
            let colName = headerColumn.name.toLocaleLowerCase()
            if (colName != "" && colName != "model name" && colName != "service account") {
                if (headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                    sorting = `${columnName.toLowerCase()},`;
                    switch (columnSorted) {
                        case undefined:
                            sorting += 'ascending';
                            break;
                        case 'ascending':
                            sorting += 'descending';
                            break;
                        default:
                            sorting = '';
                    }

                }
            }
        });
        const newQueryString = addQueryString(routerReducer.location.search, 'sort', sorting);
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }
    const headers: any = [
        { name: "Storage Code", width: 2 },
        { name: "Name", width: 2 },
        { name: "Model Name", width: 2 },
        { name: "Type", width: 2 },
        { name: "Branch", width: 2 },
        { name: "Service Account", width: 2 },
        { name: "", width: 1 },
    ].map((header) => {
        return { ...header };
    });
    const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);
    const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
    const handlePaginationChange = (e: any, { activePage }: any) => {
        dispatch(push({
            search: addQueryString(routerReducer.location.search, 'page', activePage)
        }));
    }

    //filters
    const handleFilter = (filters: { filterName: string, value: any }[]) => {
        let newQueryString = routerReducer.location.search;
        filters.forEach((filter) => {
            newQueryString = addQueryString(newQueryString, filter.filterName, filter.value);
            newQueryString += '&';
        })
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }

    //reset filters & search
    const handleReset = () => {
        dispatch(push({
            search: ''
        }));
        toggleModal(false)
    }
    const buildRows = (storages: IStorage[]): IRow[] => {
        return storages.map((s: IStorage, i: any) => {
            return {
                value: s,
                props: [{
                    edit: (rowValue: IStorage) => {
                        return (<div className="cursor offblue-text" onClick={async () => {
                            await dispatch(loadStoragePropsSaga(rowValue.storage_id))
                            await dispatch(loadStorageTransactionsPropsSaga(rowValue.storage_id))
                            toggleStorageModal(!storageModalOpen)
                        }}>{rowValue.storage_id}</div>)
                    }
                }, {
                    edit: (rowValue: IStorage) => {
                        return (<div className="cursor offblue-text" onClick={async () => {
                            await dispatch(loadStoragePropsSaga(rowValue.storage_id))
                            await dispatch(loadStorageTransactionsPropsSaga(rowValue.storage_id))
                            toggleStorageModal(!storageModalOpen)
                        }}>{rowValue.name}</div>)
                    }
                }, 'model.name', 'type', 'branch.name', 'service_account.account_name',
                {
                    edit: (rowValue: IStorage) => {
                        return (<TableMenu menuItems={menuItems} rowValue={rowValue} />);
                    }
                }]
            }
        });
    };
    const menuItems = [
        {
            title: "View Storage",
            icon: "eye",
            handleChange: async (rowValue: any) => {
                await dispatch(loadStoragePropsSaga(rowValue.storage_id))
                toggleStorageModal(!storageModalOpen)
            }
        }
    ]
    const rows = useMemo(() => buildRows(storageReducer.storages), [storageReducer.storages]);
    const handleShowResult = () => {
        const filters: { filterName: string, value: any }[] = [];
        if (selectedStatus.trim() != '') {
            filters.push({ filterName: 'status', value: selectedStatus });
        }
        if (selectedStorageModel.trim() != '') {
            filters.push({ filterName: 'storageModel', value: selectedStorageModel });
        }
        if (selectedServiceAccount.trim() != '') {
            filters.push({ filterName: 'serviceAccount', value: selectedServiceAccount });
        }
        if (searchText != '') {
            filters.push({ filterName: 'searchkeyword', value: searchText });
        }
        handleFilter(filters);
        toggleModal(false);
    }
    const renderModal = () => {
        return <MDBContainer className="filters-modal">
            <MDBModal
                isOpen={isOpen} toggle={() => toggleModal(!isOpen)}
                inline={false} noClickableBodyWithoutBackdrop={false} overflowScroll={false}                >
                <MDBModalHeader>Filter</MDBModalHeader>
                <MDBModalBody className="p-3" >
                    <div className="w-100 d-flex mt-3 justify-content-between">
                        <input className="search-input form-control input-rounded w-50" type="text"
                            value={searchText}
                            onChange={(e) => updateSearchText(e.target.value)}
                            placeholder="Search" aria-label="Search" />
                        <Select name="status"
                            label="Select Status"
                            options={constants.storageStatus.map((option, i) => {
                                return { text: option.value, value: option.id }
                            })}
                            selectedValue={selectedStatus}
                            onChange={(e: any) => selectStatus(e.target.value)}
                        />
                    </div>
                    <div className="w-100 d-flex mt-3 justify-content-between">
                        <Select name="storageModel"
                            label="Select Model"
                            options={storageReducer.models.map((option, i) => {
                                return { text: option.name, value: option.model_id }
                            })}
                            selectedValue={selectedStorageModel}
                            onChange={(e: any) => selectStorageModel(e.target.value)}
                        />
                        <Select name="serviceAccount"
                            label="Select Account"
                            options={serviceAccountsReducer.serviceAccounts.map((option, i) => {
                                return { text: option.account_name, value: option.account_name }
                            })}
                            selectedValue={selectedServiceAccount}
                            onChange={(e: any) => selectServiceAccount(e.target.value)}
                        />
                    </div>
                    <MDBRow className="filter-buttons-container w-100 d-flex mt-3 justify-content-center">
                        <MainBlueButton
                            title="Show results"
                            className="mr-2"
                            onClick={() => handleShowResult()}
                        />
                        <MainBlueButton
                            btnClassName="reset-btn"
                            title="Reset"
                            onClick={() => handleReset()}
                        />
                    </MDBRow>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    }
    const resetStorage = () => {
        toggleStorageModal(false);
        let emptyStorage = {
            storage_id: '',
            name: '',
            type: '',
            model: {
                model_id: 0,
                name: '',
                size: 0
            },
            branch_id: 0,
            last_accessed_by_id: null
        }
        storageReducer.storage = emptyStorage;
    }
    const renderStorageModal = () => {
        return <MDBModal isOpen={storageModalOpen} toggle={() => { toggleStorageModal(!storageModalOpen) }}
            fullHeight={true} position="right"
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll={true}
            className="full-height-modal full-height-modal-right">
            <div className="custom-modal-header">
                <MDBRow className="m-0 pt-5 pb-5">
                    <MDBCol size="12" md="7" className="text-left offblue-text">
                        <h2 className="font-weight-bold">{storageReducer.storage.name != "" ? storageReducer.storage.name : ""}</h2>
                    </MDBCol>
                </MDBRow>
            </div>
            <MDBModalBody>
                {storageReducer.storage.storage_id != '' ? <StorageDetails storageId={storageReducer.storage.storage_id} toggleStorageModal={toggleStorageModal} /> : undefined}
            </MDBModalBody>
        </MDBModal>
    }
    const renderAddStorageModal = () => {
        return <MDBModal isOpen={addStorageModalOpen} toggle={() => { toggleAddStorageModal(!addStorageModalOpen) }}
            fullHeight={true} position="right"
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll={true}
            className="full-height-modal full-height-modal-right">
            <div className="custom-modal-header">
                <MDBRow className="m-0 pt-5 pb-5">
                    <MDBCol size="12" md="7" className="text-left offblue-text">
                        <h2 className="font-weight-bold">Add New Storage</h2>
                    </MDBCol>
                    <MDBCol size="12" md="5" className="mt-4 mt-md-0 actions-container">
                        <MDBRow className="m-0 p-0">
                            <MainBlueButton title="cancel" className="d-contents"
                                btnClassName="btn-modal-header-cancel"
                                onClick={() => toggleAddStorageModal(false)} />
                            <MainBlueButton form="invitation-form" type="submit" title="send" className="d-contents"
                                btnClassName="btn-modal-header-save"
                                onClick={() => storageFormik.submitForm()}
                            />
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div>
            <MDBModalBody>
                <StorageForm toggleAddStorageModal={toggleAddStorageModal} />
            </MDBModalBody>
        </MDBModal>
    }

    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                <Loader isLoading={storageReducer.loadingStorages.isLoadingStorages} errorMessage={storageReducer.loadingStorages.errorMessage}>
                    <MDBRow start className="m-2 mb-4">
                        <MDBCol md="2" size="12" className="p-0 mt-3 mt-md-0 d-flex justify-content-md-start justify-content-center">
                            <MainBlueButton
                                btnClassName="pr-3 pl-3 mr-2"
                                title="+ Add Storage" className="d-contents" onClick={() => { toggleAddStorageModal(true) }} />
                        </MDBCol>
                        <MDBCol md="6" size="12" className="p-0 d-flex justify-content-md-start justify-content-center">
                            <Select name="view"
                                id="view-select"
                                className="mr-2 d-inline"
                                label="View By"
                                options={constants.viewOptions.map((option, i) => {
                                    return { text: option.value, value: option.id }
                                })}
                                selectedValue={view}
                                onChange={(e: any) => {
                                    selectView(e.target.value)
                                    dispatch(push({
                                        search: ''
                                    }));
                                }}
                            />
                            <IconContainer className="mr-2 d-inline" onClick={() => toggleModal(true)} >
                                <MDBIcon icon="filter" />
                            </IconContainer>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow start className="justify-content-center mt-2">
                        <MDBCol className="text-center p-0">
                            {
                                storageReducer.storages.length > 0 ?
                                    <DataTable
                                        headerColumns={headerColumns}
                                        rows={rows}
                                        data={storageReducer.storages}
                                        headerOnClick={handleSort}
                                        totalPages={storageReducer.totalStoragesCount / view}
                                        activePage={activePage}
                                        onPageChange={handlePaginationChange}
                                    />
                                    : <div className="mt-5" >You don't have any storages yet.</div>
                            }
                        </MDBCol>
                    </MDBRow>
                </Loader>
                {renderModal()}
                {renderStorageModal()}
                {renderAddStorageModal()}
            </MDBCol>
        </MDBRow>

    );
}
