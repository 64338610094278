import { put, takeLatest, call } from 'redux-saga/effects'
import { displayErrorMessageProps } from "../../reducers/global/types";
import {
    LOAD_STORAGES_SAGA, ILoadStoragesActionSaga,
    LOAD_STORAGE_SAGA, ILoadStorageActionSaga,
    LOAD_STORAGE_TRANSACTIONS_SAGA, ILoadStorageTransactionsActionSaga,
    LOAD_MODELS_SAGA, ILoadModelsActionSaga,
    LOAD_SERVICE_ACCOUNTS_SAGA, ILoadServiceAccountsActionSaga,
    ADD_NEW_STORAGE_SAGA, IAddNewStorageActionSaga,
    LOAD_ACCOUNT_BRANCHES_SAGA, ILoadAccountBranchesActionSaga,
    UPDATE_STORAGE_SAGA, IUpdateStorageActionSaga,
} from "./types"
import { fetchStorage, fetchStorages, fetchStorageTransactions, fetchModels, fetchServiceAccounts, postStorage, fetchAccountBranches, putStorage } from "../../api/storages";
import { loadStoragesProps, loadStorageProps, loadStorageTransactionsProps, loadModelsProps, loadServiceAccountsProps, addNewStorageProps, loadAccountBranchesProps, updateStorageProps, updateIsLoadingStoragesProps } from "../../reducers/storages/types"
import IStorage from "../../dtos/IStorage"
import IModel from "../../dtos/IModel"
import IBranch from "../../dtos/IBranch"
import IServiceAccount from "../../dtos/IServiceAccount"

function* loadStorages(action: ILoadStoragesActionSaga) {
    try {
        yield put(updateIsLoadingStoragesProps({ isLoadingStorages: true, errorMessage: undefined }));
        const storagesData: {
            storages: IStorage[],
            totalStoragesCount: number
        } = yield call(fetchStorages, action.page, action.size, action.sort, action.name, action.status, action.storageModel, action.serviceAccount);
        yield put(loadStoragesProps({ storages: storagesData.storages, totalStoragesCount: storagesData.totalStoragesCount }))
        yield put(updateIsLoadingStoragesProps({ isLoadingStorages: false, errorMessage: undefined }));
    } catch (error) {
        yield put(updateIsLoadingStoragesProps({ isLoadingStorages: false, errorMessage: error.message }));
        yield put(displayErrorMessageProps({ message: error.message }))
    }
}
export function* watchLoadStorages() {
    yield takeLatest(LOAD_STORAGES_SAGA, loadStorages)
}

function* loadStorage(action: ILoadStorageActionSaga) {
    const storage: IStorage = yield call(fetchStorage, action.storageId);
    yield put(loadStorageProps({ storage }))
}
export function* watchLoadStorage() {
    yield takeLatest(LOAD_STORAGE_SAGA, loadStorage)
}

function* loadStorageTransactions(action: ILoadStorageTransactionsActionSaga) {
    const storageTransactions: any[] = yield call(fetchStorageTransactions, action.storageId);
    yield put(loadStorageTransactionsProps({ storageTransactions }))
}
export function* watchLoadStorageTransactions() {
    yield takeLatest(LOAD_STORAGE_TRANSACTIONS_SAGA, loadStorageTransactions)
}

function* loadModels(action: ILoadModelsActionSaga) {
    const models: IModel[] = yield call(fetchModels);
    yield put(loadModelsProps({ models }))
}
export function* watchLoadModels() {
    yield takeLatest(LOAD_MODELS_SAGA, loadModels)
}

// function* loadServiceAccounts(action: ILoadServiceAccountsActionSaga) {
//     const serviceAccounts: IServiceAccount[] = yield call(fetchServiceAccounts);
//     yield put(loadServiceAccountsProps({ serviceAccounts }))
// }
// export function* watchLoadServiceAccounts() {
//     yield takeLatest(LOAD_SERVICE_ACCOUNTS_SAGA, loadServiceAccounts)
// }

function* loadAccountBranches(action: ILoadAccountBranchesActionSaga) {
    const accountBranches: IBranch[] = yield call(fetchAccountBranches, action.serviceAccount);
    yield put(loadAccountBranchesProps({ accountBranches }))
}
export function* watchLoadAccountBranches() {
    yield takeLatest(LOAD_ACCOUNT_BRANCHES_SAGA, loadAccountBranches)
}

function* addNewStorage(action: IAddNewStorageActionSaga) {
    const storageAdded: boolean = yield call(postStorage, action.storage);
    yield put(addNewStorageProps({ storageAdded }))
}
export function* watchAddNewStorage() {
    yield takeLatest(ADD_NEW_STORAGE_SAGA, addNewStorage)
}

function* updateStorage(action: IUpdateStorageActionSaga) {
    const storageUpdated: boolean = yield call(putStorage, action.storage);
    yield put(updateStorageProps({ storageUpdated }))
}
export function* watchUpdateStorage() {
    yield takeLatest(UPDATE_STORAGE_SAGA, updateStorage)
}


export default [
    watchLoadStorages(),
    watchLoadStorage(),
    watchLoadStorageTransactions(),
    watchLoadModels(),
    watchAddNewStorage(),
    // watchLoadServiceAccounts(),
    watchLoadAccountBranches(),
    watchUpdateStorage(),
]