import {
    LOAD_STORAGES, LOAD_STORAGE,
    LOAD_STORAGE_TRANSACTIONS,
    LOAD_MODELS, LOAD_SERVICE_ACCOUNTS, ADD_NEW_STORAGE,
    LOAD_ACCOUNT_BRANCHES, UPDATE_STORAGE,
    UPDATE_IS_LOADING_STORAGES,
} from "../../sagas/storage/types"
import IStorage from "../../dtos/IStorage"
import IModel from "../../dtos/IModel"
import IBranch from "../../dtos/IBranch"
import IServiceAccount from "../../dtos/IServiceAccount"

export interface ILoadStoragesAction {
    readonly type: typeof LOAD_STORAGES;
    payload: {
        storages: IStorage[],
        totalStoragesCount: number
    }
}
export const loadStoragesProps = (payload: ILoadStoragesAction['payload']): ILoadStoragesAction => {
    return {
        type: LOAD_STORAGES,
        payload: payload
    }
};
export interface ILoadStorageAction {
    readonly type: typeof LOAD_STORAGE;
    payload: {
        storage: IStorage
    }
}
export const loadStorageProps = (payload: ILoadStorageAction['payload']): ILoadStorageAction => {
    return {
        type: LOAD_STORAGE,
        payload: payload
    }
};

export interface ILoadStorageTransactionsAction {
    readonly type: typeof LOAD_STORAGE_TRANSACTIONS;
    payload: {
        storageTransactions: any
    }
}
export const loadStorageTransactionsProps = (payload: ILoadStorageTransactionsAction['payload']): ILoadStorageTransactionsAction => {
    return {
        type: LOAD_STORAGE_TRANSACTIONS,
        payload: payload
    }
};

export interface ILoadModelsAction {
    readonly type: typeof LOAD_MODELS;
    payload: {
        models: IModel[]
    }
}
export const loadModelsProps = (payload: ILoadModelsAction['payload']): ILoadModelsAction => {
    return {
        type: LOAD_MODELS,
        payload: payload
    }
};

export interface ILoadServiceAccountsAction {
    readonly type: typeof LOAD_SERVICE_ACCOUNTS;
    payload: {
        serviceAccounts: IServiceAccount[]
    }
}
export const loadServiceAccountsProps = (payload: ILoadServiceAccountsAction['payload']): ILoadServiceAccountsAction => {
    return {
        type: LOAD_SERVICE_ACCOUNTS,
        payload: payload
    }
};

export interface ILoadAccountBranchesAction {
    readonly type: typeof LOAD_ACCOUNT_BRANCHES;
    payload: {
        accountBranches: IBranch[]
    }
}
export const loadAccountBranchesProps = (payload: ILoadAccountBranchesAction['payload']): ILoadAccountBranchesAction => {
    return {
        type: LOAD_ACCOUNT_BRANCHES,
        payload: payload
    }
};

export interface IAddNewStorageAction {
    readonly type: typeof ADD_NEW_STORAGE;
    payload: {
        storageAdded: boolean
    }
}
export const addNewStorageProps = (payload: IAddNewStorageAction['payload']): IAddNewStorageAction => {
    return {
        type: ADD_NEW_STORAGE,
        payload: payload
    }
};

export interface IUpdateStorageAction {
    readonly type: typeof UPDATE_STORAGE;
    payload: {
        storageUpdated: boolean
    }
}
export const updateStorageProps = (payload: IUpdateStorageAction['payload']): IUpdateStorageAction => {
    return {
        type: UPDATE_STORAGE,
        payload: payload
    }
};

export interface IUpdateIsLoadingStoragesAction {
    readonly type: typeof UPDATE_IS_LOADING_STORAGES;
    payload: {
        isLoadingStorages: boolean
        errorMessage: string | undefined
    }
}
export const updateIsLoadingStoragesProps = (payload: IUpdateIsLoadingStoragesAction['payload']): IUpdateIsLoadingStoragesAction => {
    return {
        type: UPDATE_IS_LOADING_STORAGES,
        payload: payload
    }
};

export type StorageActions = ILoadStoragesAction | ILoadStorageAction | ILoadStorageTransactionsAction | ILoadModelsAction | ILoadServiceAccountsAction | ILoadAccountBranchesAction | IUpdateStorageAction | IAddNewStorageAction | IUpdateIsLoadingStoragesAction