import {
    IUpdateIsLoadingFulfillmentsCenterAction,
    IUpdateIsLoadingReadyOrdersAction,
    IUpdateIsLoadingInProgressOrdersAction,
    IUpdateIsLoadingPackedOrdersAction,
    FulfillmentsCenterActions
} from './types'
import {
    LOAD_FULFILLMENTS_CENTER, UPDATE_IS_LOADING_FULFILLMENTS_CENTER,
    LOAD_READY_ORDERS, UPDATE_IS_LOADING_READY_ORDERS,
    //in progress
    LOAD_IN_PROGRESS_ORDERS_COUNT,
    LOAD_IN_PROGRESS_ORDERS, LOAD_IN_PROGRESS_ITEMS,
    UPDATE_IS_LOADING_IN_PROGRESS_ORDERS,
    //packed
    LOAD_PACKED_ORDERS, UPDATE_IS_LOADING_PACKED_ORDERS,
    UPDATE_ORDER, FINISH_ORDERS,
} from "../../sagas/fulfillments_center/types";
import { IOrder } from '../../dtos/IOrder';
import { IFProduct } from '../../dtos/IFProduct';
interface IFulfillmentsCenterState {
    totalOrdersCount: number,
    totalItemsCount: number,
    readyOrdersCount: number,
    pendingOrdersCount: number,
    readyItemsCount: number,
    pendingItemsCount: number
    loadingFulfillmentsCenter: IUpdateIsLoadingFulfillmentsCenterAction['payload'],
    //ready orders
    readyOrders: IOrder[],
    loadingReadyOrders: IUpdateIsLoadingReadyOrdersAction['payload'],
    //in progress
    inProgressOrdersCount: number,
    inProgressOrders: IOrder[],
    inProgressItems: IFProduct[],
    inProgressItemsCount: number,
    loadingInProgressOrders: IUpdateIsLoadingInProgressOrdersAction['payload'],
    //packed
    packedOrders: IOrder[],
    packedOrdersCount: number,
    loadingPackedOrders: IUpdateIsLoadingPackedOrdersAction['payload'],
    //update
    orderUpdated: boolean,
    //finish
    ordersFinished: boolean


}

const FulfillmentsCenterState: IFulfillmentsCenterState = {
    totalOrdersCount: 0,
    totalItemsCount: 0,
    readyOrdersCount: 0,
    pendingOrdersCount: 0,
    readyItemsCount: 0,
    pendingItemsCount: 0,
    loadingFulfillmentsCenter: {
        isLoadingFulfillmentsCenter: false,
        errorMessage: undefined
    },
    //ready orders
    readyOrders: [],
    loadingReadyOrders: {
        isLoadingReadyOrders: false,
        errorMessage: undefined
    },
    //in progress
    inProgressOrdersCount: 0,
    inProgressOrders: [],
    inProgressItems: [],
    inProgressItemsCount: 0,
    loadingInProgressOrders: {
        isLoadingInProgressOrders: false,
        errorMessage: undefined
    },
    //packed
    packedOrders: [],
    packedOrdersCount: 0,
    loadingPackedOrders: {
        isLoadingPackedOrders: false,
        errorMessage: undefined
    },
    //update
    orderUpdated: false,
    //finish
    ordersFinished: false,
};

const fulfillmentsCenterReducer = (state = FulfillmentsCenterState, action: FulfillmentsCenterActions): IFulfillmentsCenterState => {
    switch (action.type) {
        case LOAD_FULFILLMENTS_CENTER:
            return {
                ...state,
                totalOrdersCount: action.payload.totalOrdersCount,
                totalItemsCount: action.payload.totalItemsCount,
                readyOrdersCount: action.payload.readyOrdersCount,
                pendingOrdersCount: action.payload.pendingOrdersCount,
                readyItemsCount: action.payload.readyItemsCount,
                pendingItemsCount: action.payload.pendingItemsCount,
            };
        case UPDATE_IS_LOADING_FULFILLMENTS_CENTER:
            return {
                ...state,
                loadingFulfillmentsCenter: action.payload,
            };
        case LOAD_READY_ORDERS:
            return {
                ...state,
                readyOrders: action.payload.readyOrders,
                readyOrdersCount: action.payload.readyOrdersCount,
            };
        case UPDATE_IS_LOADING_READY_ORDERS:
            return {
                ...state,
                loadingReadyOrders: action.payload,
            };
        case LOAD_IN_PROGRESS_ORDERS_COUNT:
            return {
                ...state,
                inProgressOrdersCount: action.payload.inProgressOrdersCount
            }

        case LOAD_IN_PROGRESS_ORDERS:
            return {
                ...state,
                inProgressOrders: action.payload.inProgressOrders,
            };
        case LOAD_IN_PROGRESS_ITEMS:
            return {
                ...state,
                inProgressItems: action.payload.inProgressItems,
                inProgressItemsCount: action.payload.inProgressItemsCount,
            };
        case UPDATE_IS_LOADING_IN_PROGRESS_ORDERS:
            return {
                ...state,
                loadingInProgressOrders: action.payload,
            };

        case LOAD_PACKED_ORDERS:
            return {
                ...state,
                packedOrders: action.payload.packedOrders,
                packedOrdersCount: action.payload.packedOrdersCount,
            };
        case UPDATE_IS_LOADING_PACKED_ORDERS:
            return {
                ...state,
                loadingPackedOrders: action.payload,
            };
        case UPDATE_ORDER:
            return {
                ...state,
                orderUpdated: action.payload.orderUpdated
            }
        case FINISH_ORDERS:
            return {
                ...state,
                ordersFinished: action.payload.ordersFinished
            }
        default:
            return state;
    }
};

export default fulfillmentsCenterReducer;
