import { axiosInstance } from "../store";
import Constants from '../constants';
import { handlStatusCodeMessage } from "../helpers";

export function fetchDataTableCardRequests(page = 0, size = 10, sort = ''): Promise<{ cardRequests: any[], totalCountPages: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/access_card?page=${page}&size=${size}&sort=${sort}`)
        .then((res) => {
            return { cardRequests: res.status === 200 ? res.data : [], totalCountPages: res.headers['x-total-count'] }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Card request' });
            return {
                cardRequests: [],
                totalCountPages: 0
            }
        })
}

export function fetchCardRequest(id: string | number): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/access_card/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Card request' });
            return {}
        })
}

export function addCardRequest(cardRequestData: any): Promise<any> {
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}/smart-storage/card_request`, {
        ...cardRequestData,
    }).then((res) => res.data)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Card request' });
            return false
        });
}

export function putCardRequest(id: string, cardRequestData: any): Promise<any> {
    return axiosInstance.put(`${process.env.REACT_APP_API_URL}/smart-storage/access_card`, {
        id: id,
        tag_number: cardRequestData.tag_number,
        status_id: cardRequestData.status.id,
    }).then((res) => {
        handlStatusCodeMessage({ code: res.status, entity: 'Card request' });
        return true;
    }).catch((err) => {
        handlStatusCodeMessage({ code: err.response.status, entity: 'Card request', text: err.response.data.message });
        return false
    });
}

export function removeCardRequest(id: string): Promise<boolean> {
    return axiosInstance.delete(`${process.env.REACT_APP_API_URL}/smart-storage/access_card/${id}`)
        .then((res) => res.status === 200)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Card request' });
            return false
        });
}

export function fetchCardRequestStatuses(cardState: string): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/access_card/list_card_status?current_state=${cardState}`)
        .then((res) => res.data)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Card request status' });
            return {}
        })
}