import React from "react";
import { MDBRow, MDBCol, MDBCardText } from 'mdbreact';
import vendorsIcon from "../../assets/img/pages-icons/vendor-icon.png";
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import FulfillmentDashboardPage from "./fulfillmentsDashboard"
import FulfillmentOrdersPage from "./fulfillmentsOrders"
import FulfillmentPickingPage from "./fulfillmentPickingPage"
import FulfillmentPackedPage from "./fulfillmentPackedPage"
interface IFulfillmentCenterPageProps {
}

export default function FulfillmentCenterPage(props: IFulfillmentCenterPageProps) {
    let { path } = useRouteMatch();
    return (
        <>
            <MDBRow className="m-0">
                <MDBRow className="ml-2 mt-0 mb-0 w-100">
                    <MDBCol size={`12`} md={`6`}>
                        <img className="page-image d-inline-block" src={vendorsIcon} />
                        <MDBCardText className="page-title d-inline-block">Fulfillments Center</MDBCardText>
                        <span className="page-line d-inline-block" />
                    </MDBCol>
                </MDBRow>
            </MDBRow>
            <Switch>
                <Route path={`${path}/orders`} component={FulfillmentOrdersPage} />
                <Route path={`${path}/picking`} component={FulfillmentPickingPage} />
                <Route path={`${path}/packed`} component={FulfillmentPackedPage} />
                <Route exact path={path} component={FulfillmentDashboardPage} />
            </Switch>
        </>
    )
}