import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import { MDBCol, MDBRow } from 'mdbreact';
import { AppState } from "../../store";
// import { loadBranchesPropsSaga } from "../../sagas/branch/types";
// import { loadUserGroupPropsSaga } from "../../sagas/user-group/types";
import { inviteNewUserProps } from "../../sagas/user/types"
// import FormModal from "../../components/form"
import Input from '../../components/input'

import * as Yup from "yup";
import { useFormik } from "formik";
import IInvitation from '../../dtos/IInvitation';
import Select from '../../components/select';
import { loadUserGroupPropsSaga } from '../../sagas/user-group/types';
import MultiSelect from 'react-select';
const SERVICE_ACC_ID = localStorage.getItem("service_account_id");

interface IInvitationFormProps {
    toggleInvitationModal: any
}
const invitation: IInvitation = {
    email: '',
    // service_account_id: null,
    user_group_ids_list: [57],
    branch_ids_list: [],
    tanent_id: null,
}
export let invitationFormik: any;

function InvitationForm(props: IInvitationFormProps) {
    const dispatch = useDispatch();
    const userReducer = useSelector((state: AppState) => state.userReducer)
    const userGroupReducer = useSelector((state:AppState)=>state.userGroupReducer)

    useEffect(() => {
        if (userReducer.invitationSent) {
            props.toggleInvitationModal(false);
            // dispatch(push({ pathname: '/users' }))
            // window.location.reload();
        }
        dispatch(loadUserGroupPropsSaga(0,100));
    }, [userReducer.invitationSent])
    const invitationValidationSchema: object = Yup.object({
        email: Yup.string().email("Invalid email").required("Required!"),
    })

    invitationFormik = useFormik({
        initialValues: invitation,
        validationSchema: invitationValidationSchema,
        onSubmit: async (values) => {
            dispatch(inviteNewUserProps(values))
        },
    });
    return (
        <>
        <MDBRow className="m-0 w-50">
            <MDBCol className="p-4">
                <Input
                    // fieldClassName="w-100 request-field"
                    label="Email"
                    required={false}
                    placeholder="User Email"
                    defaultValue={invitationFormik.values.email}
                    onChange={(e: any) => invitationFormik.setFieldValue('email', e.target.value)}
                />
                {invitationFormik.errors.email && invitationFormik.touched.email ?
                    <p className='red-text'>{invitationFormik.errors.email}</p>
                    : undefined}
            </MDBCol>
        </MDBRow>
        <MDBRow className="m-0 w-50">
            <MDBCol className="p-4">
            <MultiSelect
                placeholder="Select User Groups"
                isMulti={true}
                options={userGroupReducer.userGroups.map((ug, key) => { return { label: ug.name, value: ug.id }; })}
                onChange={(selectedOptions:any) => {
                    let ids:any = [];
                    selectedOptions.map((element:any,key:any)=>{
                        ids.push(element.value);
                    });
                    invitationFormik.setFieldValue('user_group_ids_list',ids);
                }}
                />
            </MDBCol>
        </MDBRow>
        </>
    )
}

export default InvitationForm;