import React from "react";
import './index.scss'
import { MDBRow, MDBCol, MDBCardText } from 'mdbreact';
import FulfillmentRequests from "../../containers/fulfillment_requests";
import fulfillmentRequestsIcon from "../../assets/img/pages-icons/fulfillment-requests-icon.png";

interface IFulfillmentRequestsPageProps {
}

export default function FulfillmentRequestsPage(props: IFulfillmentRequestsPageProps) {
    return (
        <MDBRow className="m-0">
            <MDBRow className="ml-2 mt-0 mb-0 w-100">
                <MDBCol size={`12`} md={`6`}>
                    <img className="page-image d-inline-block" src={fulfillmentRequestsIcon} />
                    <MDBCardText className="page-title d-inline-block">Fulfillment Requests</MDBCardText>
                    <span className="page-line d-inline-block" />
                </MDBCol>
            </MDBRow>
            <FulfillmentRequests />
        </MDBRow>
    )
}