import globalReducer from './global/globalReducer';
import dashboardReducer from './dashboard/dashboardReducer';
import sidebarReducer from './sidebar/sidebarReducer';
import headerReducer from './header/headerReducer';
import notificationsReducer from "./notifications/notificationsReducer";
import userReducer from "./user/userReducer";
import serviceAccountReducer from "./service_account/serviceAccountReducer";
import storageReducer from "./storages/storageReducer"
import cardRequestsReducer from "./card_requests/cardRequestsReducer";
import fulfillmentRequestsReducer from "./fulfillment_requests/fulfillmentRequestsReducer";
import userGroupReducer from './user-group/userGroupReducer';
import fulfillmentsCenterReducer from "./fulfillments_center/fulfillmentsCenterReducer";
const reducers = {
    globalReducer,
    dashboardReducer,
    sidebarReducer,
    headerReducer,
    notificationsReducer,
    userReducer,
    serviceAccountReducer,
    storageReducer,
    cardRequestsReducer,
    fulfillmentRequestsReducer,
    userGroupReducer,
    fulfillmentsCenterReducer,
}

export default reducers;