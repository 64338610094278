import { axiosInstance } from "../store";
import Constants from '../constants';
import { handlStatusCodeMessage } from "../helpers";
import ICountry from "../dtos/ICountry";
import ICity from "../dtos/ICity";

export function fetchDataTableServiceAccounts(page = 0, size = 10, sort = '', name = ''): Promise<{ serviceAccounts: any[], totalCountPages: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/serviceaccount?page=${page}&size=${size}&sort=${sort}&name=${name}`)
        .then((res) => {
            return { serviceAccounts: res.status === 200 ? res.data : [], totalCountPages: res.headers['x-total-count'] }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Service account' });
            return {
                serviceAccounts: [],
                totalCountPages: 0
            }
        })
}

export function fetchServiceAccount(id: string | number): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/serviceaccount/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Service account' });
            return {}
        })
}

export function addServiceAccount(serviceAccountData: any): Promise<any> {
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}/smart-storage/serviceaccount/setup`, {
        ...serviceAccountData,
    }).then((res) => {
        handlStatusCodeMessage({ code: res.status, entity: 'Service account' });
        setTimeout(() => { window.location.reload(); }, 1000)
        return res.data;
    })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Service account', text: err.response.data.message });
            return false
        });
}

export function putServiceAccount(id: string, serviceAccountData: any): Promise<any> {
    return axiosInstance.put(`${process.env.REACT_APP_API_URL}/smart-storage/serviceaccount/${id}`, {
        id: id,
        ...serviceAccountData,
    }).then((res) => {
        handlStatusCodeMessage({ code: res.status, entity: 'Service account' });
        return res.data;
    })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Service account' });
            return false
        });
}

export function removeServiceAccount(id: string): Promise<boolean> {
    return axiosInstance.delete(`${process.env.REACT_APP_API_URL}/smart-storage/serviceaccount/${id}`)
        .then((res) => res.status === 200)
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Service account' });
            return false
        });
}

export function fetchCountires(): Promise<ICountry[]> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/lookup/countries`)
        .then((res) => {
            return res.status == 200 ? res.data : []
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'service account' });
            return []
        })
}
export function fetchCities(uuid = ''): Promise<ICity[]> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/lookup/cities?country_uuid=${uuid}`)
        .then((res) => {
            return res.status == 200 ? res.data : []
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'service account' });
            return []
        })
}

export function fetchSettings(): Promise<any[]> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/serviceaccount/settings`)
        .then((res) => {
            return res.status == 200 ? res.data : []
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'service account' });
            return []
        })
}

export function fetchDataTableServiceAccountBranches(id = 0, page = 0, size = 10, sort = ''): Promise<{ branches: any[], totalCountPages: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/serviceaccount/${id}/branch?page=${page}&size=${size}&sort=${sort}`)
        .then((res) => {
            return { branches: res.status === 200 ? res.data : [], totalCountPages: res.headers['x-total-count'] }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Service account' });
            return {
                branches: [],
                totalCountPages: 0
            }
        })
}

export function fetchDataTableServiceAccountUserGroups(id = 0, page = 0, size = 10, sort = ''): Promise<{ userGroups: any[], totalCountPages: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/usergroup?service_account_id=${id}&page=${page}&size=${size}&sort=${sort}`)
        .then((res) => {
            return { userGroups: res.status === 200 ? res.data : [], totalCountPages: res.headers['x-total-count'] }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'Service account' });
            return {
                userGroups: [],
                totalCountPages: 0
            }
        })
}
export function fetchServiceAccountUserGroups(id: string) {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/account/user-group?component_id=2&target_tenant_id=${id}&target_component_id=1`)
        .then(res => res.data)
        .catch(err => handlStatusCodeMessage({ code: err.response.status, entity: 'Service account' }));
}