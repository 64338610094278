import React, { useEffect, useMemo } from 'react';
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import _ from 'lodash';

interface ICheck {
    children: React.ReactNode
    check: string
}

interface ICheckWithOperation extends ICheck {
    operation: 'AND' | 'OR'
}

type ICheckProps = ICheck | ICheckWithOperation;

function Can(props: ICheckProps) {
    const authPermissions: { id: string, name: string }[] = useSelector((state: AppState) => state.globalReducer.authUser.user_permissions);
    const checkPermissions = (): boolean => {
        console.log(props.check);
        if ("operation" in props && "check" in props) {
            if (typeof props.check === 'string' && authPermissions.find((permission, i) => {console.log(`${permission.name}=>${props.check}`);return permission.name == props.check})) {
                return true;
            } else if (Array.isArray(props.check)) {
                if(props.operation === 'AND'){
                    let allow = true;
                    for(let i = 0; props.check.length; i++){
                        if(_.findIndex(authPermissions, (o) => o.name === props.check[i]) < 0){
                            allow = false;
                            break;
                        }
                    }
                    return allow;
                }else{
                    let allow = false;
                    for(let i = 0; props.check.length; i++){
                        if(_.findIndex(authPermissions, (o) => o.name === props.check[i]) >= 0){
                            allow = true;
                            break;
                        }
                    }
                    return allow;
                }
            } else {
                return false;
            }
        } else if ("check" in props && typeof props.check === 'string' && authPermissions.find((permission, i) => permission.name == props.check)) {
            return true;
        }
        return false;
    }
    const checkAvailability = useMemo<boolean>(() => checkPermissions(), [authPermissions]);
    return (
        <>
            {
                checkAvailability && props.children
            }
        </>
    )
}

export default Can;