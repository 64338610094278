import { axiosInstance } from "../store";
import IStorage from "../dtos/IStorage"
import IModel from "../dtos/IModel"
import IServiceAccount from "../dtos/IServiceAccount";
import { handlStatusCodeMessage } from "../helpers";

export function fetchStorages(page = 0, size = 0, sort = '', name = '', status = '', storageModel = '', serviceAccount = ''): Promise<{ storages?: IStorage[], totalStoragesCount?: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/storage?type=automatic&page=${page}&size=${size}&sort=${sort}&storage_id=${name}&model=${storageModel}&status=${status}&service_account_name=${serviceAccount}`)
        .then((res) => {
            if (res.status == 200) {
                return {
                    storages: res.data
                    , totalStoragesCount: res.headers['x-total-count']
                }
            }
            else {
                return {
                    storages: []
                    , totalStoragesCount: 0
                }
            }

        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'storage' });
            return {
                storages: []
                , totalStoragesCount: 0
            }
        })
}

export function fetchStorage(storageId = ''): Promise<IStorage> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/storage/${storageId}`)
        .then((res) => {
            return res.status == 200 ? res.data : {}
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'storage' });
            return {}
        })
}
export function fetchStorageTransactions(storageId = ''): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/count-revision?storage=${storageId}&sort=timestamp,desc&size=5`)
        .then((res) => {
            return res.status == 200 ? res.data : []
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'storage' });
            return []
        })
}

export function fetchModels(): Promise<IModel[]> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/automatic-storage-model?page=0&size=1000`)
        .then((res) => {
            return res.status == 200 ? res.data : []
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'storage' });
            return []
        })
}
export function fetchServiceAccounts(): Promise<IServiceAccount[]> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/serviceaccount?page=0&size=1000`)
        .then((res) => {
            return res.status == 200 ? res.data : []
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'storage' });
            return []
        })
}

export function fetchAccountBranches(serviceAccount = 0): Promise<any[]> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/smart-storage/serviceaccount/${serviceAccount}/branch?page=0&size=1000`)
        .then((res) => {
            return res.status == 200 ? res.data : []
        }).catch((err) => {
            if (err.response.status == 409) {
                handlStatusCodeMessage({ code: err.response.status, entity: 'storage', text: err.response.data.message });
                return []
            }
            else {
                handlStatusCodeMessage({ code: err.response.status, entity: 'storage' });
                return []
            }
        })
}
export function putStorage(storage: IStorage): Promise<boolean> {
    return axiosInstance.put(`${process.env.REACT_APP_API_URL}/smart-storage/storage/${storage.storage_id}`, storage)
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: 'user' });
                return true
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'user' });
                return false
            }
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'storage' });
            return false
        })
}
export function postStorage(storage: IStorage): Promise<boolean> {
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}/smart-storage/storage`, storage)
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: 'user' });
                return true
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'user' });
                return false
            }
        }).catch((err) => {
            if (err.response.status == 409) {
                handlStatusCodeMessage({ code: err.response.status, entity: 'storage', text: err.response.data.message });
                return false
            }
            else {
                handlStatusCodeMessage({ code: err.response.status, entity: 'storage' });
                return false
            }
        })
}