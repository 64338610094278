import React from "react";
import './index.scss'
import Dashboard from '../../containers/dashboard';
import { MDBRow, MDBCardText } from 'mdbreact';
import dashboardIcon from "../../assets/img/pages-icons/dashboard-icon.png";

interface IDashboardPageProps {
}

export default function DashboardPage(props: IDashboardPageProps) {
    return (
        <MDBRow className="m-0">
            <MDBRow className="ml-2 mt-0 mb-0 w-100">
                <img className="page-image" src={dashboardIcon} />
                <MDBCardText className="page-title">Dashboard</MDBCardText>
                <span className="page-line" />
            </MDBRow>
            <Dashboard />
        </MDBRow>
    )
}