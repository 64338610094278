import React, { useEffect, useState, useMemo } from 'react';
import { MDBCol, MDBRow, MDBCard, MDBCardBody } from 'mdbreact';
import { IOrder } from '../../dtos/IOrder'
import './styles.scss'

import { AppState } from "../../store";

interface IOrderCardProps {
    order: IOrder,
    handleSelectOrder: any,
    selectedOrder: any
}
export default function OrderCard(props: IOrderCardProps) {
    const [selected, selectOrder] = useState(false);
    useEffect(() => {
        selectOrder(props.selectedOrder)
    }, [])
    return (
        <MDBCol md={`4`} sm={`5`} className="text-center mb-3 cursor">
            <MDBCard
                onClick={() => {
                    selectOrder(!selected)
                    props.handleSelectOrder(props.order.order_id)
                }}
                className={`justify-content-center order-card ${selected ? 'selected-order-card' : ''}`}>
                <MDBCardBody className="justify-content-center p-4">
                    <MDBRow className='pl-2 pr-2 d-flex justify-content-center'>
                        <h6 className={`${selected ? '' : 'grey-text'} m-0 pr-1 text-center`}>ID:</h6>
                        <h6 className={`${selected ? '' : 'text-font-black'}  m-0 text-center`}>{props.order.external_reference}</h6>
                    </MDBRow>
                    <MDBRow className='pl-2 pr-2 d-flex justify-content-center'>
                        <h6 className={`${selected ? '' : 'grey-text'} m-0 pr-1 text-center`}>Date:</h6>
                        <h6 className={`${selected ? '' : 'text-font-black'} m-0 text-center`}>{props.order.ordered_at.replace('T', ' ')}</h6>
                    </MDBRow>
                    <MDBRow className='pl-2 pr-2 d-flex justify-content-center'>
                        <h6 className={`${selected ? '' : 'grey-text'} m-0 pr-1 text-center`}>Name:</h6>
                        <h6 className={`${selected ? '' : 'text-font-black'} m-0 text-center`}>{`${props.order.first_name} ${props.order.last_name}`}</h6>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        </MDBCol>
    )
}
