import React from "react";
import StoragesList from '../../containers/storages/storagesList';
import { MDBRow, MDBCol, MDBCardText } from 'mdbreact';
import storagesIcon from "../../assets/img/pages-icons/storages-icon.png";

interface IStoragesListPageProps {
}

export default function StoragesListPage(props: IStoragesListPageProps) {
    return (
        <MDBRow className="m-0">
            <MDBRow className="ml-2 mt-0 mb-0 w-100">
                <MDBCol size={`12`} md={`6`}>
                    <img className="page-image d-inline-block" src={storagesIcon} />
                    <MDBCardText className="page-title d-inline-block">Storages</MDBCardText>
                    <span className="page-line d-inline-block" />
                </MDBCol>
            </MDBRow>
            <StoragesList />
        </MDBRow>
    )
}