import {IUpdateIsLoadingCardRequestsAction, CardRequestsActions} from './types'
import {
    LOAD_CARD_REQUESTS, UPDATE_IS_LOADING_CARD_REQUESTS,
    DELETE_CARD_REQUEST, UPDATE_CARD_REQUEST
} from "../../sagas/card_requests/types";
import _ from 'lodash';

interface ICardRequestsState {
    filters: {
        sortCol: string,
        sortOrder: 'ascending' | 'descending'
    },
    cardRequests: any[],
    totalCountPages: number,
    loadingCardRequests: IUpdateIsLoadingCardRequestsAction['payload'],
}

const CardRequestsState: ICardRequestsState = {
    filters: {
        sortCol: '',
        sortOrder: 'ascending'
    },
    cardRequests: [],
    totalCountPages: 0,
    loadingCardRequests: {
        isLoadingCardRequests: false,
        errorMessage: undefined
    }
};

const cardRequestsReducer = (state = CardRequestsState, action: CardRequestsActions): ICardRequestsState => {
    switch (action.type) {
        case LOAD_CARD_REQUESTS:
            return {
                ...state,
                cardRequests: action.payload.cardRequests,
                totalCountPages: action.payload.totalCountPages
            };
        case UPDATE_IS_LOADING_CARD_REQUESTS:
            return {
                ...state,
                loadingCardRequests: action.payload,
            };
        case DELETE_CARD_REQUEST:
            const updatedCardRequests = [...state.cardRequests];
            _.remove(updatedCardRequests, (cardRequest, i) => cardRequest.id === action.payload.cardRequestId)
            return {
                ...state,
                cardRequests: updatedCardRequests
            };
        case UPDATE_CARD_REQUEST:
            return {
                ...state,
                cardRequests: state.cardRequests.map((cardRequest, i) => cardRequest.id?.toString() == action.payload.cardRequestId? {...cardRequest, ...action.payload.data} : cardRequest)
            };
        default:
            return state;
    }
};

export default cardRequestsReducer;
