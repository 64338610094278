import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import { loadReadyOrdersPropsSaga, loadInProgressOrdersCountPropsSaga, updateOrderPropsSaga } from "../../sagas/fulfillments_center/types";
import { toast } from 'react-toastify';

import { AppState } from "../../store";
import Loader from "../../components/loader";
import { MDBCol, MDBRow, MDBModal, MDBModalBody } from 'mdbreact';
import Switch from "react-switch";
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import { addQueryString, partialText } from '../../helpers';
import queryString from 'query-string';
import MainBlueButton from "../../components/blueButton";
import { IOrder } from '../../dtos/IOrder';
import CustomCheckbox from "../../components/customCheckbox"
import { NavLink } from 'react-router-dom';



export default function FulfillmentOrders() {
    const dispatch = useDispatch();
    const fulfillmentsCenterReducer = useSelector((state: AppState) => state.fulfillmentsCenterReducer)
    const routerReducer = useSelector((state: AppState) => state.router)
    const { page, sort, status } = queryString.parse(routerReducer.location.search);
    const [activePage, changePage] = useState(page ? parseInt(page.toString()) : 1);
    const [view, selectView] = useState(10)
    const [checked, updatedChecked] = useState(false)

    useEffect(() => {

        const pageNum: number = page ? parseInt(page.toString()) - 1 : 0;
        const [columnName, columnSorted] = sort ? sort.toString().split(',') : [];
        const sorting: string = sort ? sort.toString().replace('ascending', 'asc').replace('descending', 'desc').replace('date', 'orderedAt') : 'orderedAt,desc';

        updateHeaderCloumns(updateHeaderSorting(columnName, columnSorted));
        changePage(page ? parseInt(page.toString()) : 1);
        dispatch(loadReadyOrdersPropsSaga(pageNum, view, sorting, checked ? 'in_progress' : 'ready,in_progress'));
        dispatch(loadInProgressOrdersCountPropsSaga());
        if (fulfillmentsCenterReducer.orderUpdated) {
            fulfillmentsCenterReducer.orderUpdated = false
        }
    }, [routerReducer.location.search, view, checked, fulfillmentsCenterReducer.orderUpdated]);

    const handleChangeChecked = (checked: any) => {
        const filters: { filterName: string, value: any }[] = [];
        if (checked) {
            filters.push({ filterName: 'status', value: 'in_progress' });
            handleFilter(filters);
        }
        else {
            dispatch(push({
                search: ''
            }));
        }
        updatedChecked(checked);
    }

    const updateHeaderSorting = (columnName?: string, columnSorted?: string): IHeaderColumn[] => {
        return headerColumns.map((headerColumn, i) => {
            if (columnName && headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                switch (columnSorted) {
                    case 'ascending':
                        return {
                            ...headerColumn,
                            sorted: 'ascending'
                        }
                    case 'descending':
                        return {
                            ...headerColumn,
                            sorted: 'descending'
                        }
                    default:
                        return {
                            ...headerColumn,
                            sorted: undefined
                        }
                }
            } else {
                return {
                    ...headerColumn,
                    sorted: undefined
                }
            }
        });
    }
    const handleSort = (columnName: string, columnSorted?: 'ascending' | 'descending') => {
        let sorting = ``;
        headerColumns.map((headerColumn, i) => {
            if (headerColumn.name.toLowerCase() == 'date') {
                if (headerColumn.name.toLowerCase() == columnName.toLowerCase()) {
                    sorting = `${columnName.toLowerCase()},`;
                    switch (columnSorted) {
                        case undefined:
                            sorting += 'descending';
                            break;
                        case 'ascending':
                            sorting += 'descending';
                            break;
                        default:
                            sorting += 'ascending';
                    }
                }
            }
        });
        const newQueryString = addQueryString(routerReducer.location.search, 'sort', sorting);
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }
    const headers: any = [
        { name: "#", width: 1 },
        { name: "Order ID", width: 2 },
        { name: "Date", width: 2 },
        { name: "Customer Name", width: 2 },
        { name: "Selected", width: 1 },
    ].map((header) => {
        return { ...header };
    });
    const [headerColumns, updateHeaderCloumns] = useState<IHeaderColumn[]>(headers);
    const handlePaginationChange = (e: any, { activePage }: any) => {
        dispatch(push({
            search: addQueryString(routerReducer.location.search, 'page', activePage)
        }));
    }
    const handleOnSelect = (order: IOrder) => {
        if (order.packing_status == "in_progress") {
            order.packing_status = "ready"
        }
        else if (order.packing_status == "ready") {
            order.packing_status = "in_progress"
        }
        dispatch(updateOrderPropsSaga(order))
    }


    const buildRows = (readyOrders: IOrder[]): IRow[] => {
        return readyOrders.map((order: IOrder, i) => {
            return {
                value: order,
                onClick: () => {
                    handleOnSelect(order)
                },
                props: [
                    {
                        edit: () => {
                            return <h6>{i + 1}</h6>
                        }
                    }, 'external_reference', {
                        edit: (rowValue: IOrder) => {
                            return (<h6>{rowValue.ordered_at.replace('T', ' ')}</h6 >);
                        }
                    },
                    {
                        edit: (rowValue: IOrder) => {
                            return (<h6>
                                {rowValue.first_name ? `${rowValue.first_name} ${rowValue.last_name}` : ''}
                            </h6 >);
                        }
                    },
                    {
                        edit: (rowValue: IOrder) => {
                            return (<CustomCheckbox id="row"
                                key={rowValue.external_reference}
                                checked={rowValue.packing_status == "in_progress" ? true : false}
                                handleChange={() => handleOnSelect(rowValue)} />);
                        }
                    }

                ]
            }
        });
    };
    const rows = useMemo(() => buildRows(fulfillmentsCenterReducer.readyOrders), [fulfillmentsCenterReducer.readyOrders]);
    //filters
    const handleFilter = (filters: { filterName: string, value: any }[]) => {
        let newQueryString = routerReducer.location.search;
        filters.forEach((filter) => {
            newQueryString = addQueryString(newQueryString, filter.filterName, filter.value);
            newQueryString += '&';
        })
        dispatch(push({
            search: addQueryString(newQueryString, 'page', 1)
        }));
    }
    const handleShowResult = () => {
        const filters: { filterName: string, value: any }[] = [];
        if (checked) {
            filters.push({ filterName: 'status', value: 'in_progress' });
        }
        handleFilter(filters);
    }
    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                <Loader isLoading={fulfillmentsCenterReducer.loadingReadyOrders.isLoadingReadyOrders} errorMessage={fulfillmentsCenterReducer.loadingReadyOrders.errorMessage}>
                    <MDBRow start className="justify-content-center mt-2 bg-white fulfillment-container">
                        <MDBCol className="text-center p-0 ">
                            <MDBRow className="">
                                <MDBCol sm={`9`} className='pl-5 pt-3 text-left'>
                                    <MDBRow className="m-0 p-0">
                                        <h6 className={`text-font-black font-weight-bold pr-5`}>Orders</h6>
                                        <h6 className={`grey-text`}>{`${fulfillmentsCenterReducer.inProgressOrdersCount} orders has been selected`}</h6>
                                    </MDBRow>
                                </MDBCol>
                                <MDBCol className='pt-3 text-left'>
                                    <label className="d-flex justify-content-center align-items-center">
                                        <Switch onChange={handleChangeChecked} checked={checked}
                                            onColor={'#2D2ACE'} />
                                        <span className={`grey-text text-center pl-1`}>Show selected only</span>
                                    </label>
                                </MDBCol>
                            </MDBRow>
                            {
                                fulfillmentsCenterReducer.readyOrdersCount > 0 ?
                                    <DataTable
                                        headerColumns={headerColumns}
                                        rows={rows}
                                        headerOnClick={handleSort}
                                        totalPages={fulfillmentsCenterReducer.readyOrdersCount / view}
                                        activePage={activePage}
                                        onPageChange={handlePaginationChange}
                                        bottomCelled
                                        highlighted
                                    />
                                    : <div>You don't have any ready orders.</div>
                            }
                        </MDBCol>
                    </MDBRow>
                    <MDBCol className="p-4 d-flex justify-content-center w-100">
                        {/* <NavLink to={fulfillmentsCenterReducer.inProgressOrdersCount > 0 ? "/fulfillments-center/picking" : "/fulfillments-center/orders"}> */}
                        <MainBlueButton
                            title="Start Picking"
                            className="h1 d-contents"
                            icon="angle-double-right"
                            btnClassName="pt-4 pb-4 pl-5 pr-5"
                            largeButton={true}
                            onClick={() => {
                                if (fulfillmentsCenterReducer.inProgressOrdersCount == 0) {
                                    toast.info('Please select orders first');
                                }
                                else {
                                    dispatch(push("/fulfillments-center/picking"));
                                }
                            }}
                        />
                        {/* </NavLink> */}
                    </MDBCol>
                </Loader>
            </MDBCol>
        </MDBRow>
    )
}
