import React from "react";
import { MDBRow, MDBCol, MDBCardText } from 'mdbreact';
import POsFrom from "../../containers/posForm";
import serviceAccountIcon from "../../assets/img/pages-icons/service-account-icon.png";

interface IPOsPageProps {
}

export default function POsPage(props: IPOsPageProps) {
    return (
        <MDBRow className="m-0">
            <MDBRow className="ml-2 mt-0 mb-0 w-100">
                <MDBCol size={`12`} md={`6`}>
                    <img className="page-image d-inline-block" src={serviceAccountIcon} />
                    <MDBCardText className="page-title d-inline-block">PO</MDBCardText>
                    <span className="page-line d-inline-block" />
                </MDBCol>
            </MDBRow>
            <POsFrom />
        </MDBRow>
    )
}