import {put, takeLatest, call} from 'redux-saga/effects'
import {
    LOAD_SERVICE_ACCOUNTS_SAGA, ILoadServiceAccountsActionSaga,
    DELETE_SERVICE_ACCOUNT_SAGA, IDeleteServiceAccountActionSaga,
    UPDATE_SERVICE_ACCOUNT_SAGA, IUpdateServiceAccountActionSaga
} from "./types";
import {
    loadServiceAccountsProps, updateIsLoadingServiceAccountsProps,
    deleteServiceAccountProps, updateServiceAccountProps, 
} from "../../reducers/service_account/types";
import {fetchDataTableServiceAccounts, removeServiceAccount, putServiceAccount} from '../../api/serviceAccount'
import {displayErrorMessageProps} from "../../reducers/global/types";

function* loadServiceAccounts(action: ILoadServiceAccountsActionSaga) {
    try {
        yield put(updateIsLoadingServiceAccountsProps({isLoadingServiceAccounts: true, errorMessage: undefined}));
        const serviceAccountsReasults: {serviceAccounts: any[], totalCountPages: number} = yield call(fetchDataTableServiceAccounts, action.filters.page,action.filters.size,action.filters.sort, action.filters.name);
        yield put(loadServiceAccountsProps({
            serviceAccounts: serviceAccountsReasults.serviceAccounts,
            totalCountPages: serviceAccountsReasults.totalCountPages,
        }));
        yield put(updateIsLoadingServiceAccountsProps({isLoadingServiceAccounts: false, errorMessage: undefined}));
    } catch (error) {
        yield put(updateIsLoadingServiceAccountsProps({isLoadingServiceAccounts: false, errorMessage: error.message}));
        yield put(displayErrorMessageProps({message: error.message}))
    }
}

export function* watchLoadServiceAccounts() {
    yield takeLatest(LOAD_SERVICE_ACCOUNTS_SAGA, loadServiceAccounts)
}

function* deleteServiceAccount(action: IDeleteServiceAccountActionSaga) {
    const deletedServiceAccountResults: boolean = yield call(removeServiceAccount, action.serviceAccountId);
    yield put(deleteServiceAccountProps({serviceAccountId: action.serviceAccountId}));
}

export function* watchDeleteServiceAccount() {
    yield takeLatest(DELETE_SERVICE_ACCOUNT_SAGA, deleteServiceAccount);
}

function* updateServiceAccount(action: IUpdateServiceAccountActionSaga) {
    const updatedServiceAccountResults: any = yield call(putServiceAccount, action.serviceAccountId, action.data);
    yield put(updateServiceAccountProps({serviceAccountId: action.serviceAccountId, data: action.data}))
}

export function* watchUpdateServiceAccount() {
    yield takeLatest(UPDATE_SERVICE_ACCOUNT_SAGA, updateServiceAccount);
}

export default [
    watchLoadServiceAccounts(),
    watchDeleteServiceAccount(),
    watchUpdateServiceAccount(),
]