import React, { useEffect, useState, useRef, useMemo } from 'react';
import './styles/index.scss'
import { useDispatch, useSelector } from "react-redux";
import { loadDashboardPropsSaga } from "../../sagas/dashboard/types";
import { AppState } from "../../store";
import { NavLink } from "react-router-dom"
import Loader from "../../components/loader";
import { Link } from 'react-router-dom';
import Card from "../../components/card";
import { MDBCol, MDBRow } from 'mdbreact';
import {
    MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn,
    MDBAlert, MDBContainer
} from 'mdbreact';
import lineCard from '../../assets/img/dashboard/Line.svg';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Line } from 'react-chartjs-2';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import {
    fetchDataTableLastTransactions, fetchInboundOutbound,
    fetchMostActiveUsers
} from '../../api/dashboard';
import { forEach } from 'lodash';
import { Months } from '../../dtos/global';

interface IDashboardProps {
}

function Dashboard(props: IDashboardProps) {
    const dispatch = useDispatch();
    const dashboardReducer = useSelector((state: AppState) => state.dashboardReducer)
    const [dateRange, setDateRange] = useState<Date[]>([new Date(moment().subtract(7, 'd').format('YYYY-MM-DD')), new Date(moment().format('YYYY-MM-DD'))]);
    const [startDate, endDate] = dateRange;
    // charts logic
    const [transactionsChartData, setTransactionsChartData] = useState<any>({
        labels: [],
        datasets: [
            {
                label: 'Inbound',
                data: [],
                fill: false,
                backgroundColor: '#44A5FF',
                borderColor: '#44A5FF',
            },
            {
                label: 'Outbound',
                data: [],
                fill: false,
                backgroundColor: '#F83B29',
                borderColor: '#F83B29',
            },
        ],
    });
    const [activeUsersChartData, setActiveUsersChartData] = useState<any>({
        labels: [],
        datasets: [
            {
                label: 'User',
                data: [],
                fill: false,
                backgroundColor: '#44A5FF',
                borderColor: '#44A5FF',
            },
        ],
    });
    const [chartsOptions, setChartsOptions] = useState<any>({
        responsive: true,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    });

    // useEffect(() => {
    //     // resizing charts
    //     dispatch(loadDashboardPropsSaga())
    //     // calling last 10 data
    //     const getLastData = async () => {
    //         // transactions
    //         const lastTransactionsResults: { lastTransactions: any[], totalCountPages: number } = await fetchDataTableLastTransactions();
    //         updateLastTransactions(lastTransactionsResults.lastTransactions);
    //         // most active users
    //         const mostActiveUsersResults: { users: any[] } = await fetchMostActiveUsers();
    //         setActiveUsersChartData({
    //             labels: mostActiveUsersResults.users.map((userObj, i) => userObj.user),
    //             datasets: [
    //                 {
    //                     ...activeUsersChartData.datasets[0],
    //                     data: mostActiveUsersResults.users.map((userObj, i) => userObj.transactions),
    //                 },
    //             ]
    //         });
    //     }
    //     getLastData();
    //     changeDateData(moment(startDate), moment(endDate));
    // }, []);
    // change dates
    const handleDateChange = (date: any) => {
        setDateRange(date);
        if (date[1]) {
            changeDateData(moment(date[0]), moment(date[1]));
        }
    }
    const changeDateData = async (currentFromDate: moment.Moment, currentToDate: moment.Moment) => {
        const type = currentFromDate.month() === currentToDate.month() ? 'day' : 'month';
        let formattedDateTo = currentToDate.format("MMM DD yyyy");
        let formattedDateFrom = currentFromDate.format("MMM DD yyyy");
        const results = await fetchInboundOutbound(formattedDateFrom, formattedDateTo, type);
        const groupedData: any = {};
        forEach(results.transactions, (val, i) => {
            const currentDate = val.created_at.split('T')[0];
            const xAxisType = type === 'day' ? currentDate : Months[`_${moment(currentDate).month()}` as keyof typeof Months]
            groupedData[xAxisType] = {
                type: xAxisType,
                [(val.type as string).toLowerCase()]: val.count,
                ...(groupedData[xAxisType] ? groupedData[xAxisType] : {})
            }
        });
        const groupedDataArr = Object.values(groupedData);
        setTransactionsChartData({
            labels: groupedDataArr.map((groupObj: any, i) => groupObj.type),
            datasets: [
                {
                    ...transactionsChartData.datasets[0],
                    data: groupedDataArr.map((groupObj: any, i) => groupObj.inbound)
                },
                {
                    ...transactionsChartData.datasets[1],
                    data: groupedDataArr.map((groupObj: any, i) => groupObj.outbound)
                },
            ]
        });
    }
    //last 10 transactions
    const [lastTransactions, updateLastTransactions] = useState<any[]>([]);
    let lastTransactionsHeaders: IHeaderColumn[] = [
        { name: "Storage", width: 2, handleClick: () => { } },
        { name: "Quantity", width: 2, handleClick: () => { } },
        { name: "User", width: 2, handleClick: () => { } },
        { name: "Operation", width: 2, handleClick: () => { } },
        { name: "Created at", width: 2, backendName: 'created_at', handleClick: () => { } },
    ];
    const buildLastTransactionsRows = (lastTransactions: any[]): IRow[] => {
        return lastTransactions.map((lastTransaction: any, i) => {
            return {
                value: lastTransaction,
                props: [
                    'storage.name', 'quantity',
                    {
                        edit: (rowValue: any) => {
                            return (<div>{`${rowValue.user ? rowValue.user.first_name : ''} ${rowValue.user ? rowValue.user.last_name : ''}`}</div>);
                        },
                    },
                    'operation',
                    {
                        edit: (rowValue: any) => {
                            return (<div>{`${rowValue.created_at.split('T')[0]}`}</div>);
                        },
                    },
                ]
            }
        });
    };
    const lastTransactionsRows = useMemo(() => buildLastTransactionsRows(lastTransactions), [lastTransactions]);
    return (
        <MDBRow className="m-0 w-100">
            <MDBCol className="text-center">
                {/* <Loader isLoading={dashboardReducer.loadingDashboard.isLoadingDashboard} errorMessage={dashboardReducer.loadingDashboard.errorMessage}>
                    <MDBRow start className="mt-4">
                        <MDBCol md={`3`} sm={`6`} className="text-center mb-3">
                            <Link to="/inventory">
                                <MDBCard className='card-shadow'>
                                    <MDBCardBody className='p-2'>
                                        <MDBRow>
                                            <MDBCol className='text-left'>
                                                <h4 className={`text-font-blue text-weight-normal`}>{dashboardReducer.itemsCount.toString()}</h4>
                                                <h6 className={`grey-text`}>Items</h6>
                                            </MDBCol>
                                            <MDBCol>
                                                <img src={lineCard} />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCardBody>
                                </MDBCard>
                            </Link>
                        </MDBCol>
                        <MDBCol md={`3`} sm={`6`} className="text-center mb-3">
                            <Link to="/inventory">
                                <MDBCard className='card-shadow'>
                                    <MDBCardBody className='p-2'>
                                        <MDBRow>
                                            <MDBCol className='text-left'>
                                                <h4 className={`text-font-blue text-weight-normal`}>{dashboardReducer.productsCount.toString()}</h4>
                                                <h6 className={`grey-text`}>Products</h6>
                                            </MDBCol>
                                            <MDBCol>
                                                <img src={lineCard} />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCardBody>
                                </MDBCard>
                            </Link>
                        </MDBCol>
                        <MDBCol md={`3`} sm={`6`} className="text-center mb-3">
                            <Link to="/inventory?status=warning">
                                <MDBCard className='card-shadow'>
                                    <MDBCardBody className='p-2'>
                                        <MDBRow>
                                            <MDBCol className='text-left'>
                                                <h4 className={`text-font-blue text-weight-normal`}>{dashboardReducer.warningsCount.toString()}</h4>
                                                <h6 className={`grey-text`}>Warning</h6>
                                            </MDBCol>
                                            <MDBCol>
                                                <img src={lineCard} />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCardBody>
                                </MDBCard>
                            </Link>
                        </MDBCol>
                        <MDBCol md={`3`} sm={`6`} className="text-center mb-3">
                            <Link to="/inventory?status=critical">
                                <MDBCard className='card-shadow'>
                                    <MDBCardBody className='p-2'>
                                        <MDBRow>
                                            <MDBCol className='text-left'>
                                                <h4 className={`text-font-blue text-weight-normal`}>{dashboardReducer.criticalsCount.toString()}</h4>
                                                <h6 className={`grey-text`}>Critical</h6>
                                            </MDBCol>
                                            <MDBCol>
                                                <img src={lineCard} />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCardBody>
                                </MDBCard>
                            </Link>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow start className="mt-4">
                        <MDBCol className="p-0 m-0 request-container" size="12">
                            <DatePicker wrapperClassName="calendar-input ml-2"
                                placeholderText={`${moment(startDate).format('YYYY-MM-DD')} - ${endDate ? moment(endDate).format('YYYY-MM-DD') : ''}`}
                                dateFormat="yyyy-MM-dd"
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange={true}
                                shouldCloseOnSelect={false}
                                onChange={handleDateChange}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow start className="mt-4 justify-content-around">
                        <MDBCol className="p-2 m-0 text-center white-background" md="5" sm="11">
                            <h4 className="text">Inbound/Outbound requests</h4>
                            <Line type="line" data={transactionsChartData} options={chartsOptions} />
                        </MDBCol>
                        <MDBCol className="p-2 m-md-0 mt-3 text-center white-background" md="5" sm="11">
                            <h4 className="text">Most active users</h4>
                            <Line type="line" data={activeUsersChartData} options={chartsOptions} />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow start className="mt-4">
                        <MDBCol size="12" className="text-center transaction-table">
                            <DataTable
                                headerColumns={lastTransactionsHeaders}
                                rows={lastTransactionsRows}
                                actionsBar={<MDBRow><MDBCol className="text-left">
                                    <h4 className="text">Last 10 transactions</h4>
                                </MDBCol></MDBRow>}
                            />
                        </MDBCol>
                    </MDBRow>
                </Loader> */}
            </MDBCol>
        </MDBRow >
    )
}

export default Dashboard;