import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import { MDBRow, MDBCol } from 'mdbreact';
import MainBlueButton from "../../components/blueButton";
import './styles/index.scss';
import { AppState } from "../../store";
import { postPO } from "../../api/po"
import Input from '../../components/input'
import * as Yup from "yup";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
const SERVICE_ACC_ID = localStorage.getItem("service_account_id");

interface IPOsFromProps {
}

function POsFrom(props: IPOsFromProps) {
    const dispatch = useDispatch();
    const [orders, setOrders] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [selectedPO, changeSelectedPO] = useState('orders')
    const generatePO = () => {
        postPO(startDate ? moment(startDate).format("DD/MM/yyy") : null, endDate ? moment(endDate).format("DD/MM/yyy") : null, orders ? orders : null);
    }
    const handleChangeSelected = (value: string) => {
        changeSelectedPO(value);
        setOrders('');
        setStartDate('');
        setEndDate('');
    }
    return (
        <MDBRow className="m-5">
            <MDBRow className="po-form p-4 bg-white">
                <MDBRow className="w-100 p-4">
                    <div className="pl-4 pt-4 pb-4">
                        <input
                            type="radio"
                            value="orders"
                            checked={selectedPO == "orders"}
                            onChange={() => handleChangeSelected("orders")}
                        />
                    </div>
                    <MDBCol className="w-50">
                        <Input
                            label="Orders"
                            required={false}
                            placeholder="Orders Numbers"
                            disabled={selectedPO != 'orders'}
                            value={orders}
                            onChange={(e: any) => setOrders(e.target.value)}
                        />
                        <p className='red-text pl-3'>Please seperate order Ids with comma.</p>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="w-100 p-4">
                    <div className="pl-4 pt-4 pb-4">
                        <input
                            type="radio"
                            value="dates"
                            checked={selectedPO == "dates"}
                            onChange={() => handleChangeSelected("dates")}
                        />
                    </div>
                    <MDBCol className="w-50">
                        <div className="picker-container">
                            <label className="font-weight-bold pt-3 pl-3">Start Date
                            </label>
                            <DatePicker
                                dateFormat="dd/MM/yyy"
                                selected={startDate ? new Date(startDate) : undefined}
                                disabled={selectedPO != 'dates'}
                                placeholderText="Start Date"
                                onChange={(date: any) => {
                                    setStartDate(date)
                                    setEndDate('')
                                }} />
                        </div>
                    </MDBCol>
                    <MDBCol className="w-50">
                        <div className="picker-container">
                            <label className="font-weight-bold pt-3 pl-3">End Date
                            </label>
                            <DatePicker
                                dateFormat="dd/MM/yyy" minDate={new Date(startDate)}
                                selected={endDate ? new Date(endDate) : undefined}
                                disabled={selectedPO != 'dates'}
                                placeholderText="End Date"
                                onChange={(date: any) => setEndDate(date)} />
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBCol className="p-4 d-flex justify-content-end w-100">
                    <MainBlueButton
                        btnClassName="pl-3 pr-3 mr-2"
                        title="Generate"
                        onClick={() => {
                            generatePO();
                        }}
                    />
                </MDBCol>
            </MDBRow>
        </MDBRow >
    )
}

export default POsFrom;